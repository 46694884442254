import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { useLingui } from '@lingui/react'

import { MenuItemModel, MenuItemTypeModel } from './index'

import Submenu from 'components/Menu/submenu'

import useModals from 'state/modals/hooks'
import { ApplicationModal } from 'state/modals/actions'

import { getScreenWidth } from 'functions/helper'

import ArrowIcon from '../../../public/icons/arrowLeft.svg'

interface ItemProps {
	item: MenuItemModel;
	handleActiveSubmenu: (active: boolean) => void;
	handleCloseMenu: () => void;
}

const Item: React.FC<ItemProps> = ({ item, handleActiveSubmenu, handleCloseMenu }) => {
	const router = useRouter()
	const { i18n } = useLingui()

	const wrapperRef = useRef(null)

	const [showDropdown, setShowDropdown] = useState(false)
	const { openModal } = useModals()

	const toggleMenu = useCallback(() => {
		setShowDropdown(!showDropdown)
		handleActiveSubmenu(!showDropdown)
	}, [showDropdown, handleActiveSubmenu])

	const handleItemClick = useCallback((item: MenuItemModel, isSubitem: boolean): void => {
		setShowDropdown(false)
		if (isSubitem) {
			handleCloseMenu()
			handleActiveSubmenu(false)
		}

		if (item.type && item.link !== undefined) {
			if (item.type === MenuItemTypeModel.EXTERNAL_LINK) {
				window.open(item.link.toString(), '_blank', 'noopener,noreferrer')
			}

			if (item.type === MenuItemTypeModel.MODAL) {
				openModal(item.link as ApplicationModal)
			}

			if (item.type === MenuItemTypeModel.LINK) {
				router.push(`${item.link}`)
			}
		}
	}, [openModal, router, handleActiveSubmenu, handleCloseMenu])

	useEffect(() => {
		const handleClickOutside = (event) => {
			const width = getScreenWidth()
			if (showDropdown && width > 575 && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setShowDropdown(false)
			}
		}

		if (showDropdown) {
			document.addEventListener('mousedown', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [showDropdown])

	return (
		<>
			<div className={'relative'} onClick={() => !!item?.subItems?.length && toggleMenu()} ref={wrapperRef} id={`menu-item-element-wrapper-${item.label}-id`}>
				<div className={'flex xs:justify-between items-center'}>
					<p className={`text-gray-400 font-medium text-sm cursor-pointer xs:text-base`}
						 id={`menu-item-element-${item.label}-id`}
						 onClick={() => handleItemClick(item, false)}>
						{i18n._(`${item.label}`)}
					</p>
					<div className={'hidden xs:block w-32px h-32px rounded-full border-2 bg-white border-opacity-80 p-9px'}>
						<ArrowIcon className={'rotate-180 w-full h-full'} viewBox={'0 0 15 23'}/>
					</div>
				</div>
				{!!item?.subItems?.length &&
				<div className={'absolute top-0 left-50 inset-x-2/4 z-10 xs:hidden'}>
					<div
						className={`w-187px top-33px -left-84px backdrop-filter-blur-50 bg-gray-360 px-9px absolute border-white flex flex-col justify-end items-center bg-opacity-100 transition-all overflow-hidden duration-500 ease-in-out rounded-22 ${showDropdown ? `pt-34px pb-24px max-h-screen border-4` : 'border-0 max-h-0 py-0'}`}>
						<Submenu subItems={item.subItems} handleItemClick={handleItemClick} />
					</div>
				</div>
				}
			</div>
			<div
				className={`hidden fixed translate-x-full top-0 left-0 w-full h-full py-28px px-35px overflow-y-scroll ${showDropdown && 'xs:block'}`}>
				<p className={`text-gray-400 font-medium text-sm cursor-pointer xs:text-base`}
					 onClick={toggleMenu}>{i18n._(`${item.label}`)}
					 id={`menu-item-element-wrapper-${item.label}-mobile-id`}
				</p>
				<div className={'pl-3px pt-15px'}>
					<Submenu subItems={item.subItems} handleItemClick={handleItemClick} />
				</div>
			</div>
		</>
	)
}

export default Item
