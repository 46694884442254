import React, { useCallback, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { Modal, ModalMenuItem, MenuItemTypeModel, BackButton } from 'components'

import { ApplicationModal } from 'state/modals/actions'
import useModals from 'state/modals/hooks'

import { LinkItemModal } from 'types/LinkItemModal'
import { Sizes } from 'types/Sizes'

import styles from './swapmeet.module.scss'

interface SwapMeetModalProps {
	swapPageLink: string;
	pairsPageLink: string;
	poolsPageLink: string;
	tokensPageLink: string;
}

const SwapMeetModal: React.FC<SwapMeetModalProps> = ({ swapPageLink, pairsPageLink, poolsPageLink, tokensPageLink }) => {
	const router = useRouter()
	const { i18n } = useLingui()
	const { closeModals, openModal } = useModals()
	const [showDetail, setShowDetail] = useState(false)

	const list: LinkItemModal[] = useMemo(() => [
		{ label: `${i18n._(t`Swap tokens`)}`, type: MenuItemTypeModel.LINK, link: swapPageLink },
		{ label: `${i18n._(t`View pairs`)}`, type: MenuItemTypeModel.LINK, link: pairsPageLink },
		{ label: `${i18n._(t`View tokens`)}`, type: MenuItemTypeModel.LINK, link: tokensPageLink },
		{ label: `${i18n._(t`View pools`)}`, type: MenuItemTypeModel.LINK, link: poolsPageLink },
		{ label: `${i18n._(t`Property details`)}`, type: MenuItemTypeModel.LOCAL_EVENT, link: 'PROPERTY_DETAILS' }
	], [pairsPageLink, poolsPageLink, tokensPageLink])

	const toggleDetails = useCallback(() => {
		setShowDetail(!showDetail)
	}, [showDetail])

	const selectSwapModal = useCallback((item: LinkItemModal): void => {
		if (item.link) {
			if ([MenuItemTypeModel.MODAL, MenuItemTypeModel.LINK].includes(item.type)) {
				closeModals()
			}
			if (item.type === MenuItemTypeModel.MODAL) {
				openModal(item.link as ApplicationModal)
			}
			if (item.type === MenuItemTypeModel.LINK) {
				router.push(`${item.link}`)
			}
			if (item.type === MenuItemTypeModel.LOCAL_EVENT && item.link === 'PROPERTY_DETAILS') {
				toggleDetails()
			}
		}
	}, [closeModals, openModal, router, toggleDetails])

	return (
		<Modal isOpen={true}
					 title={{ firstLine: `${i18n._(t`Swap`)}`, secondLine: `${i18n._(t`Meet`)}` }}
					 description={`${i18n._(t`The property for decentralized digital asset exchange in the HODLVERSE network.`)}`}
					 size={Sizes.SMALL}
					 mainIconName={'SwapMeet'}
					 addTopSpace={showDetail}
					 addMobileTopSpace
					 customBackAction={showDetail ? toggleDetails : undefined}>
			<div className={`h-full relative xs:h-500px`}>
				<div className={`${styles.body} ${showDetail ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
					<div className={`h-full flex flex-col`}>
						{list.map((item, index) => (
							<div className={'mb-20px xs:mb-15px'} key={index}>
								<ModalMenuItem label={item.label} onSelect={() => selectSwapModal(item)}/>
							</div>
						))}
					</div>
				</div>
				<div className={`${styles.body} body-details ${showDetail ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
					<div className={'details-form'}>
						<p className='modal-description__title'>{i18n._(t`Name`)}</p>
						<p className='modal-description__value'>{i18n._(t`Swap`)} {i18n._(t`Meet`)}</p>
						<hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px xs:h-2px xs:mt-24px xs:mb-26px'}/>
						<p className='modal-description__title'>{i18n._(t`Description`)}</p>
						<p className={`modal-description__value min-h-118px xs:min-h-90px xs:text-sm xs:normal-case`}>
							{i18n._(t`The property for decentralized digital asset exchange in the HODLVERSE network.`)}
						</p>
						<hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px xs:h-2px xs:mt-24px xs:mb-26px'}/>
						<p className='modal-description__title'>{i18n._(t`Available for purchase`)}</p>
						<p className='modal-description__value'>{i18n._(t`No`)}</p>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default SwapMeetModal
