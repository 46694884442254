import React from 'react'
import { useRouter } from 'next/router'

import { Modal, Button } from 'components'

import useModals from 'state/modals/hooks'

import { Sizes } from 'types/Sizes'
import { useWallet } from 'state/wallet/hooks'

interface ConnectWalletRequestModalProps {
	isOpen: boolean;
	connectWalletRoute: string;
}

const ConnectWalletRequestModal: React.FC<ConnectWalletRequestModalProps> = ({
																																							 isOpen, connectWalletRoute
																																						 }) => {

	const router = useRouter()
	const { closeModals } = useModals()
	const { notifyNotConnectedWallet } = useWallet()

	const goToConnectWallet = () => {
		notifyNotConnectedWallet(false)
		closeModals()
		router.push(connectWalletRoute)
	}

	return (
		<Modal isOpen={isOpen}
					 title={{ firstLine: 'SO CLOSE!' }}
					 firstLineClassname={'mt-45px'}
					 headBlock={<p
						 className={'font-medium text-25px xs:text-xs text-center text-gray-400 opacity-60 tracking-normal ' +
						 'mt-9px xs:-mt-3px mb-25px xs:mb-0'}>
						 The HODLVERSE requires connecting your wallet before you can proceed.
					 </p>}
					 isConfirmation={true}
					 size={Sizes.EXTRA_SMALL}>
			<div className={`h-full relative px-16px pt-17px pb-28px xs:pt-40px xs:pb-20px`}>
				<div className={'flex justify-center items-center pt-37px xs:hidden'} onClick={goToConnectWallet}>
					<p className={'font-bold text-xl text-blue-400 cursor-pointer uppercase tracking-normal'}>Connect now</p>
				</div>
				<div className={'hidden xs:flex justify-center'}>
					<Button onClick={goToConnectWallet} id={'popup-connect-now-btn-id'}>
						<p className={'uppercase text-xl text-white'}>Connect now</p>
					</Button>
				</div>
			</div>
		</Modal>
	)
}

export default ConnectWalletRequestModal
