import React, { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { CurrencyAmount, Pair, Token, CHAIN_INIT_CODE_HASH } from '@hodlvalley/sdk'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { Modal, PoolItem } from 'components'

import useModals from 'state/modals/hooks'
import usePool from 'state/pool/hooks'

import useDebounce from 'hooks/useDebounce'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'

import { Sizes } from 'types/Sizes'

import SearchPoolOrFarmTemplate from './template'

interface SearchPoolModalProps {
	isMyPool?: boolean;
	isFarmView?: boolean;
	pairs
}

const SearchPoolModal: React.FC<SearchPoolModalProps> = ({ isMyPool = false, pairs }) => {
	const router = useRouter()
	const { closeModals } = useModals()
	const { setPool } = usePool()
	const { chainId, account } = useActiveWeb3React()
	const { i18n } = useLingui()

	const [searchVal, setSearchVal] = useState('')
	const [pairList, setPairList] = useState<any[]>([])

	const debouncedSearchVal = useDebounce(searchVal, 250)

	const selectItem = useCallback((item): void => {
		closeModals()

		const urlEnd = `${item.token0.id}-${item.token0.id}`
		setPool(item, isMyPool)

		router.push(`/pool/${isMyPool ? 'myview' : 'allview'}/${urlEnd}`)
	}, [closeModals, isMyPool, router])

	useEffect(() => {
		if (debouncedSearchVal.length) {
			const search = debouncedSearchVal.toLowerCase()
			setPairList(pairs.filter(x => x?.token0?.name?.toLowerCase()?.includes(search) || x?.token0?.symbol?.toLowerCase()?.includes(search) ||
				x?.token1?.name?.toLowerCase()?.includes(search) || x?.token1?.symbol?.toLowerCase()?.includes(search)))
		} else {
			setPairList(pairs)
		}
	}, [pairs, debouncedSearchVal])


	const RenderRow = React.useCallback(
		({ index, style, data }) => {

			const item = data[index]

			const tokenA = new Token(chainId, item.token0.id, 18, item.token0.symbol, item.token0.name)
			const tokenB = new Token(chainId, item.token1.id, 18, item.token1.symbol, item.token1.name)

			const currencyAmountA = CurrencyAmount.fromRawAmount(tokenA, '0')
			const currencyAmountB = CurrencyAmount.fromRawAmount(tokenB, '0')

			const pair = new Pair(currencyAmountA, currencyAmountB)

			return (
				<div key={data[index].id} style={style} className={'px-10px'}>
					<PoolItem item={pair} onSelect={() => selectItem(data[index])}/>
				</div>
			)
		},
		[selectItem, chainId]
	)

	return (
		<Modal isOpen={true}
					 title={{ firstLine: isMyPool ? `${i18n._(t`View my`)}` : `${i18n._(t`View all`)}`, secondLine: isMyPool ? `${i18n._(t`Pool`)}` : `${i18n._(t`Pools`)}` }}
					 description={`${i18n._(t`The property for all pools and adding liquidity in the HODLVERSE network`)}.`}
					 size={Sizes.MEDIUM}
					 addMobileTopSpace
					 mainIconName={'PoolOne'}>
			<SearchPoolOrFarmTemplate
				searchVal={searchVal}
				setSearchVal={setSearchVal}
				elements={account ? pairList : []}
				renderRow={RenderRow}
			/>
		</Modal>
	)
}

export default SearchPoolModal
