import {useActiveWeb3React, useFarmContractByAddress, useMoneyContract} from '../../hooks'

import { BigNumber } from '@ethersproject/bignumber'
import { Chef } from './enum'
import { Zero } from '@ethersproject/constants'
import {useCallback, useMemo} from 'react'
import { useChefContract } from './hooks'


export function useFarmByAddress(address: string) {
  const { account } = useActiveWeb3React()
  const contract = useFarmContractByAddress(address, true)
  const deposit = useCallback((amount: BigNumber) => {
        try {
          return  contract?.deposit(amount)
        } catch (e) {
          console.error(e)
          return e
        }
      },
      [account, contract]
  )

  // Withdraw
  const withdraw = useCallback((amount: BigNumber) => {
        try {
          return contract?.withdraw(amount)
        } catch (e) {
          console.error(e)
          return e
        }
      },
      [account, contract]
  )

    return  useMemo(()=>{
        return { deposit, withdraw }
    }, [deposit, withdraw]);
}

export default function useMasterChef(chef: Chef) {
  const { account } = useActiveWeb3React()

  const money = useMoneyContract()


  const contract = useChefContract(chef)

  // Deposit
  const deposit = useCallback(
    async (pid: number, amount: BigNumber) => {
      try {
        let tx
        if (chef === Chef.MASTERCHEF) {
          tx = await contract?.deposit(pid, amount)
        } else {
          tx = await contract?.deposit(pid, amount, account)
        }

        return tx
      } catch (e) {
        console.error(e)
        return e
      }
    },
    [account, chef, contract]
  )

  // Withdraw
  const withdraw = useCallback(
    async (pid: number, amount: BigNumber) => {
      try {
        let tx

        if (chef === Chef.MASTERCHEF) {
          tx = await contract?.withdraw(pid, amount)
        } else {
          tx = await contract?.withdraw(pid, amount, account)
        }

        return tx
      } catch (e) {
        console.error(e)
        return e
      }
    },
    [account, chef, contract]
  )

  const harvest = useCallback(
    async (pid: number) => {
      try {
        let tx

        if (chef === Chef.MASTERCHEF) {
          tx = await contract?.deposit(pid, Zero)
        } else if (chef === Chef.MASTERCHEF_V2) {
          const pendingSushi = await contract?.pendingSushi(pid, account)

          const balanceOf = await money?.balanceOf(contract?.address)

          // If MasterChefV2 doesn't have enough sushi to harvest, batch in a harvest.
          if (pendingSushi.gt(balanceOf)) {
            tx = await contract?.batch(
              [
                contract?.interface?.encodeFunctionData('harvestFromMasterChef'),
                contract?.interface?.encodeFunctionData('harvest', [pid, account]),
              ],
              true
            )
          } else {
            tx = await contract?.harvest(pid, account)
          }
        } else if (chef === Chef.MINICHEF) {
          tx = await contract?.harvest(pid, account)
        }

        return tx
      } catch (e) {
        console.error(e)
        return e
      }
    },
    [account, chef, contract, money]
  )

  return { deposit, withdraw, harvest }
}
