import {Currency, CurrencyAmount,} from "@hodlvalley/sdk";
import {useSelector} from "react-redux";
import {AppState} from "../state";
import {CrossChainState} from "../state/crosschainswap/reducer";
import { Field} from "../state/swap/actions";
import {useMemo} from "react";



export function useAgentMarket(
    currencyAmountIn?: CurrencyAmount<Currency>,
    currencyOut?: Currency,
    isCrossChain?: boolean
): {
    // [field in Field]?: number;
    [key: string]: any
} {

    const crossChainState: CrossChainState = useSelector<AppState, CrossChainState>((state) => state.crosschainswap)


    return  useMemo(()=>{
        if (isCrossChain && currencyAmountIn) {
            const market = crossChainState.markets.find((m)=>m.from.toUpperCase() ===currencyAmountIn?.currency?.symbol.toUpperCase() && m.to.toUpperCase() === currencyOut?.symbol.toUpperCase());
            if (market) {
                return {
                    [Field.OUTPUT]: market.rate * parseFloat(currencyAmountIn.toSignificant(4)),
                    rate: `1 ${market.from} = ${market.rate} ${market.to}`
                }
            }

        }
        return {
            [Field.OUTPUT]: 0,
            rate: ''
        }
    }, [isCrossChain, currencyAmountIn, crossChainState.markets, currencyAmountIn]);


}
