import {useWeb3React} from "@web3-react/core";
import {useApollo} from "analytics/core/apollo";
import {ChainId} from "@hodlvalley/sdk";
import React, {useMemo} from "react";
import {ApolloProvider} from "@apollo/client";

interface Props {
    children: any;
}
export const ApolloCustomProvider = (props: Props) => {
    const { chainId, library } = useWeb3React()
    const client = useApollo(chainId || ChainId.SEPOLIA,undefined)
    useMemo(()=>{
        client.resetStore();
    }, [chainId]);

    return (
        <ApolloProvider client={client}>
            {props.children}
        </ApolloProvider>
    )
}