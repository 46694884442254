import React, { useCallback, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { BackButton, Modal, ModalMenuItem, MenuItemTypeModel } from 'components'

import { Sizes } from 'types/Sizes'
import { LinkItemModal } from 'types/LinkItemModal'

import useModals from 'state/modals/hooks'
import { ApplicationModal } from 'state/modals/actions'

import { useFarms, useGetMyHodlFarms } from 'services/graph'

import styles from './communityFarm.module.scss'

const CommunityFarmModal: React.FC = () => {
	const router = useRouter()
	const { closeModals, openModal } = useModals()
	const { i18n } = useLingui()

	const [showDetail, setShowDetail] = useState(false)

	const toggleDetails = useCallback(() => {
		setShowDetail(!showDetail)
	}, [showDetail])

	const myFarms = useGetMyHodlFarms()
	const allFarms = useFarms()


	const selectModal = useCallback((item: LinkItemModal): void => {
		if (item.link) {
			if ([MenuItemTypeModel.MODAL, MenuItemTypeModel.LINK].includes(item.type)) {
				closeModals()
			}
			if (item.type === MenuItemTypeModel.MODAL) {
				openModal(item.link as ApplicationModal)
			}
			if (item.type === MenuItemTypeModel.LINK) {
				router.push(`${item.link}`)
			}
			if (item.type === MenuItemTypeModel.LOCAL_EVENT && item.link === 'PROPERTY_DETAILS') {
				toggleDetails()
			}
		}
	}, [closeModals, openModal, router, toggleDetails])

	const list = useMemo(() => {
		return [
			{
				label: `${i18n._(t`View my farms`)}`,
				type: MenuItemTypeModel.LINK,
				link: '/farm/myfarm',
				count: myFarms.length
			},
			{
				label: `${i18n._(t`View all farms`)}`,
				type: MenuItemTypeModel.LINK,
				link: '/farm/allfarm',
				count: allFarms.length
			},
			{ label: `${i18n._(t`Property details`)}`, type: MenuItemTypeModel.LOCAL_EVENT, link: 'PROPERTY_DETAILS' }
		]
	}, [i18n, myFarms.length, allFarms.length])

	return (
		<Modal isOpen={true}
					 title={{ firstLine: `${i18n._(t`Community`)}`, secondLine: `${i18n._(t`Farm`)}` }}
					 description={`${i18n._(t`The property for the farming of the HVLP token in the HODLVERSE network`)}.`}
					 mainIconName={'FarmOne'}
					 size={Sizes.SMALL}
					 addMobileTopSpace
					 addTopSpace={showDetail}
					 customBackAction={showDetail ? toggleDetails : undefined}>
			<div className={`h-full relative xs:h-500px`}>
				<div className={`${styles.body} ${showDetail ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
					<div className={`h-full flex flex-col justify-center xs:justify-start`}>
						{list.map((item, index) => (
							<div className={'mb-20px xs:mb-15px'} key={index}>
								<ModalMenuItem label={item.label} onSelect={() => selectModal(item)}
															 suffix={item.hasOwnProperty('count')
																 ? <p
																	 className={'ml-11px text-blue-400 tracking-normal font-medium text-xl'}>{item.count}</p>
																 : undefined}/>
							</div>
						))}
					</div>
				</div>
				<div className={`${styles.body} body-details ${showDetail ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
					<div className={'details-form'}>
						<p className="modal-description__title">{i18n._(t`Name`)}</p>
						<p className="modal-description__value">{i18n._(t`Community farm`)}</p>
						<hr
							className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px xs:h-2px xs:mt-24px xs:mb-26px'}/>
						<p className="modal-description__title">{i18n._(t`Description`)}</p>
						<p className={`modal-description__value min-h-118px xs:min-h-90px xs:text-sm xs:normal-case`}>
							{i18n._(t`The property for the farming of the HVLP token in the HODLVERSE network`)}.
						</p>
						<hr
							className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px xs:h-2px xs:mt-24px xs:mb-26px'}/>
						<p className="modal-description__title">{i18n._(t`Available for purchase`)}</p>
						<p className="modal-description__value">{i18n._(t`No`)}</p>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default CommunityFarmModal
