import { createAction } from '@reduxjs/toolkit'
import {Pair} from "@hodlvalley/sdk";


interface CustomPool {
    chainId: number,
    lpAddress: string
}

export const setPoolData = createAction<{pool: Pair | null, isUserPool}>('pool/setPoolData')

export const addBookmarkPool = createAction<{
    customPool: CustomPool
}>('pool/addBookmarkPool')
