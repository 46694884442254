import {ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useRouter} from 'next/router'
import {ChainId} from "@hodlvalley/sdk";
import Image from 'next/image'
import {useAddCustomNetwork}  from '../../hooks/useManageNetworks'
import {getScreenWidth} from 'functions/helper'
import {NETWORK_ICON, NETWORK_LABEL} from "constants/networks";
import FlagUs4x3 from '../../../public/icons/flags/4x3/us.svg'
import FlagUs1x1 from '../../../public/icons/flags/1x1/us.svg'
// import FlagFr4x3 from '../../../public/icons/flags/4x3/fr.svg'
// import FlagFr1x1 from '../../../public/icons/flags/1x1/fr.svg'
// import FlagEs4x3 from '../../../public/icons/flags/4x3/es.svg'
// import FlagEs1x1 from '../../../public/icons/flags/1x1/es.svg'
// import FlagNl4x3 from '../../../public/icons/flags/4x3/nl.svg'
// import FlagNl1x1 from '../../../public/icons/flags/1x1/nl.svg'
import FlagRu4x3 from '../../../public/icons/flags/4x3/ru.svg'
import FlagRu1x1 from '../../../public/icons/flags/1x1/ru.svg'
import FlagVn4x3 from '../../../public/icons/flags/4x3/vn.svg'
import FlagVn1x1 from '../../../public/icons/flags/1x1/vn.svg'
import FlagIn4x3 from '../../../public/icons/flags/4x3/in.svg'
import FlagIn1x1 from '../../../public/icons/flags/1x1/in.svg'
import FlagCn4x3 from '../../../public/icons/flags/4x3/cn.svg'
import FlagCn1x1 from '../../../public/icons/flags/1x1/cn.svg'
// import FlagId4x3 from '../../../public/icons/flags/4x3/id.svg'
// import FlagId1x1 from '../../../public/icons/flags/1x1/id.svg'
// import FlagAr4x3 from '../../../public/icons/flags/4x3/ar.svg'
// import FlagAr1x1 from '../../../public/icons/flags/1x1/ar.svg'
import FlagDe4x3 from '../../../public/icons/flags/4x3/de.svg'
import FlagDe1x1 from '../../../public/icons/flags/1x1/de.svg'
// import FlagMy4x3 from '../../../public/icons/flags/4x3/my.svg'
// import FlagMy1x1 from '../../../public/icons/flags/1x1/my.svg'
// import FlagTr4x3 from '../../../public/icons/flags/4x3/tr.svg'
// import FlagTr1x1 from '../../../public/icons/flags/1x1/tr.svg'
// import FlagPt4x3 from '../../../public/icons/flags/4x3/pt.svg'
// import FlagPt1x1 from '../../../public/icons/flags/1x1/pt.svg'
import BtcIcon from '../../../public/icons/assets/btc.svg'
import EthIcon from '../../../public/icons/assets/eth.svg'

import styles from './languageNetworkSwitcher.module.scss'
import {useActiveWeb3React} from "hooks/useActiveWeb3React";

export interface LanguageItem {
	label: string;
	locale: string;
	flagIcon: ReactNode;
	squareFlag: ReactNode;
}

export interface NetworkItem {
	label: string;
	icon: ReactNode;
	chainId: number
}

const languageList: LanguageItem[] = [
	{ label: 'English', locale: 'en', flagIcon: <FlagUs4x3/>, squareFlag: <FlagUs1x1/> },
	// { label: 'French', locale: 'fr', flagIcon: <FlagFr4x3/>, squareFlag: <FlagFr1x1/> },
	// { label: 'Spanish', locale: 'es', flagIcon: <FlagEs4x3/>, squareFlag: <FlagEs1x1/> },
	// { label: 'Dutch', locale: 'nl', flagIcon: <FlagNl4x3/>, squareFlag: <FlagNl1x1/> },
	{ label: '中国人', locale: 'cn', flagIcon: <FlagCn4x3/>, squareFlag: <FlagCn1x1/> },
	{ label: 'Русский', locale: 'ru', flagIcon: <FlagRu4x3/>, squareFlag: <FlagRu1x1/> },
	{ label: 'Tiếng Việt Nam', locale: 'vn', flagIcon: <FlagVn4x3/>, squareFlag: <FlagVn1x1/> },
	// { label: 'Indonesian', locale: 'id', flagIcon: <FlagId4x3/>, squareFlag: <FlagId1x1/> },
	{ label: 'हिन्दी', locale: 'hi', flagIcon: <FlagIn4x3/>, squareFlag: <FlagIn1x1/> },
	// { label: 'Arabic', locale: 'ar', flagIcon: <FlagAr4x3/>, squareFlag: <FlagAr1x1/> },
	{ label: 'Deutsch', locale: 'de', flagIcon: <FlagDe4x3/>, squareFlag: <FlagDe1x1/> },
	// { label: 'Malaysian', locale: 'my', flagIcon: <FlagMy4x3/>, squareFlag: <FlagMy1x1/> },
	// { label: 'Turkish', locale: 'tr', flagIcon: <FlagTr4x3/>, squareFlag: <FlagTr1x1/> },
	// { label: 'Portuguese', locale: 'pt', flagIcon: <FlagPt4x3/>, squareFlag: <FlagPt1x1/> }
]

const networkList: NetworkItem[] = [
	{ label: NETWORK_LABEL[ChainId.SEPOLIA], chainId: ChainId.SEPOLIA, icon: <Image width={32} height={32} src={NETWORK_ICON[ChainId.ROPSTEN]} alt={'SEPOLIA'} /> },
	// { label:NETWORK_LABEL[ChainId.AVALANCHE_TESTNET], chainId: ChainId.AVALANCHE_TESTNET, icon: <Image width={32} height={32} src={NETWORK_ICON[ChainId.AVALANCHE_TESTNET]} alt={'AVALANCHE TESTNET'} /> },
	// { label: NETWORK_LABEL[ChainId.BSC_TESTNET], chainId: ChainId.BSC_TESTNET, icon: <Image width={32} height={32} src={NETWORK_ICON[ChainId.BSC_TESTNET]} alt={'BSC TESTNET'} /> },
	{ label: NETWORK_LABEL[ChainId.MATIC_TESTNET], chainId: ChainId.MATIC_TESTNET, icon: <Image width={32} height={32} src={NETWORK_ICON[ChainId.MATIC_TESTNET]} alt={'MATIC TESTNET'} /> },
	// { label: NETWORK_LABEL[ChainId.FANTOM_TESTNET], chainId: ChainId.FANTOM_TESTNET, icon: <Image width={32} height={32} src={NETWORK_ICON[ChainId.FANTOM_TESTNET]} alt={'FANTOM TESTNET'} /> },
]

interface ItemProps {
	language?: boolean;
	network?: boolean;
}

const Item: React.FC<ItemProps> = ({ network, language }) => {
	const { chainId } = useActiveWeb3React()

	const { locale, asPath, push } = useRouter();

	const { addCustomChain } = useAddCustomNetwork()
	const [_, setCurrentNetwork] = useState<number>(chainId);

	const [showDropdown, setShowDropdown] = useState(false)

	const currentLocale = useMemo(() => {
		return languageList.find(x => x.locale === locale)
	}, [locale])

	const wrapperRef = useRef(null)

	const toggleMenu = useCallback(() => {
		setShowDropdown(!showDropdown)
	}, [showDropdown])

	const handleNetworkItemClick = useCallback((item: NetworkItem)=>{
		setShowDropdown(false)
		addCustomChain(item?.chainId).then((chainId)=>{
			setCurrentNetwork(chainId);
		});
	}, [addCustomChain]);

	const handleItemClick = useCallback((item: NetworkItem | LanguageItem): void => {
		setShowDropdown(false)

		if (language) {
			push(asPath, undefined, { locale: (item as LanguageItem).locale })
		}

		if (network) {
			handleNetworkItemClick(item as NetworkItem)
		}
	}, [asPath, push, language, network, handleNetworkItemClick])

	useEffect(() => {
		const handleClickOutside = (event) => {
			const width = getScreenWidth()
			if (showDropdown && width > 575 && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setShowDropdown(false)
			}
		}

		if (showDropdown) {
			document.addEventListener('mousedown', handleClickOutside)
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [showDropdown])
	const submenu = useMemo(() => (
		<>
			{(language ? languageList : networkList).map((subitem: LanguageItem | NetworkItem, index) => (
				<div
					className={`rounded-18 mb-8px py-8px px-13px w-full bg-transparent transition last:mb-0 flex items-center 
					cursor-pointer hover:bg-white hover:bg-opacity-80 
					${styles.svgIconWrapper}`}
					key={index}
					id={`${language ? 'language' : 'network'}-item-${language ? (subitem as LanguageItem).locale : subitem.label}-id`}
					onClick={() => handleItemClick(subitem)}>
					<div className={`w-23px ${language ? 'h-15px' : 'h-22px'} flex justify-center items-center mr-14px`}>
						{language ? (subitem as LanguageItem).flagIcon : (subitem as NetworkItem).icon}
					</div>
					<p className={`text-gray-400 font-medium text-sm text-opacity-100 whitespace-nowrap text-ellipsis overflow-hidden`}>
						{subitem.label}
					</p>
				</div>
			))}
		</>
	), [handleItemClick, language])

	return (
		<>
			<div className={'relative'} onClick={toggleMenu} ref={wrapperRef} id={`${language ? 'language' : 'network'}-selector-id`}>
				{language &&
					<div className={`w-44px h-44px rounded-full border-4 overflow-hidden cursor-pointer`}>
						{currentLocale?.squareFlag}
					</div>
				}
				{network &&
					<div className={`w-44px h-44px flex items-center justify-center rounded-full border-4 overflow-hidden cursor-pointer ${styles.svgIconWrapper}`}>
						<Image width={38} height={38} src={NETWORK_ICON[chainId]} alt={"Network"} objectFit="cover" />
					</div>
				}
				<div className={'absolute top-0 left-50 inset-x-2/4 z-10'}>
					<div
						className={`w-187px top-46px -left-96px backdrop-filter-blur-50 bg-gray-360 px-9px absolute border-white bg-opacity-100 transition-all duration-500 ease-in-out rounded-22
						xs:left-auto xs:-right-[25px] 
						${showDropdown ? `pt-39px pb-24px border-4 max-h-[300px] overflow-y-scroll` : 'border-0 max-h-0 py-0 overflow-hidden'}`}>
						{submenu}
					</div>
				</div>
			</div>
		</>
	)
}

export default Item
