import React from 'react'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

const NoResults: React.FC = () => {
	const { i18n } = useLingui()

	return (
		<p className={'uppercase font-bold text-center text-gray-400 text-sm xs:text-base pt-2px xs:pt-11px'}>
			{i18n._(t`No search results`)}.
		</p>
	)
}

export default NoResults
