import { ReactNode, useEffect, useRef } from 'react'
import { updateElSize } from 'functions/format'

interface InputProps {
	title: string;
	value: string;
	onChange?: (val: string) => void;
	suffix?: string;
	manageBlock?: ReactNode;
	placeholder?: string;
	readonly?: boolean;
	id?: string;
}

const Input: React.FC<InputProps> = ({
																			 title,
																			 value,
																			 onChange,
																			 suffix,
																			 manageBlock,
																			 placeholder = '',
																			 readonly = false,
	id
																		 }) => {
	const inputRef = useRef()

	useEffect(() => {
		updateElSize(inputRef.current, value)
	}, [value])

	return (
		<div className={`bg-white border-3 border-gray-220 h-103px xs:h-64px rounded-full overflow-hidden bg-opacity-60 
    border-opacity-60 flex justify-between items-center pl-60px xs:pl-29px pt-8px xs:pt-5px pr-51px xs:pr-30px`}>
			<div className={'flex flex-col'}>
				<p className={'capitalize font-medium text-xl xs:text-xs text-gray-400 text-opacity-60 tracking-normal'}>
					{title}
				</p>
				<div className={'flex items-center -mt-2px pl-2px xs:pl-0'}>
					<input type="text"
								 id={id}
								 readOnly={readonly}
								 ref={inputRef}
								 className={`font-medium text-28px xs:text-base tracking-normal text-gray-400 bg-transparent min-w-20px xs:min-w-15px xs:leading-4`}
								 value={value}
								 placeholder={placeholder}
								 onChange={e => onChange && onChange(e.target.value)}/>
					<span
						className={'font-medium text-28px xs:text-base text-gray-400 text-opacity-60 tracking-normal'}>{suffix}</span>
				</div>
			</div>
			{manageBlock &&
			<div className={'pb-8px'}>
				{manageBlock}
			</div>
			}
		</div>
	)
}

export default Input
