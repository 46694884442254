import React, { useCallback, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'

import { Modal, ModalMenuItem, MenuItemTypeModel } from 'components'

import { ApplicationModal } from 'state/modals/actions'
import useModals from 'state/modals/hooks'

import { useWindowSize } from 'hooks/useWindowSize'

import { LinkItemModal } from 'types/LinkItemModal'
import { Sizes } from 'types/Sizes'

import { responsiveSizes } from 'constants/sizes'
import useMultiplayer from 'state/multiplayer/hooks'

interface AirportModalProps {
	createRoomPageLink: string;
	viewRoomsPageLink: string;
}

const AirportModal: React.FC<AirportModalProps> = ({ createRoomPageLink, viewRoomsPageLink }) => {
	const router = useRouter()
	const { width: screenWidth } = useWindowSize()
	const { closeModals, openModal } = useModals()
	const { isActive, isJoinedRoom, setJoinedRoom } = useMultiplayer();

	const list: LinkItemModal[] = useMemo(() => [
		{ label: 'create room', type: MenuItemTypeModel.LINK, link: createRoomPageLink, disabled: isJoinedRoom },
		{ label: 'view rooms', type: MenuItemTypeModel.LINK, link: viewRoomsPageLink }
	], [createRoomPageLink, viewRoomsPageLink, isJoinedRoom])

	const selectSwapModal = useCallback((item: LinkItemModal): void => {
		if (item.link) {
			if ([MenuItemTypeModel.MODAL, MenuItemTypeModel.LINK].includes(item.type)) {
				closeModals()
			}
			if (item.type === MenuItemTypeModel.MODAL) {
				openModal(item.link as ApplicationModal)
			}
			if (item.type === MenuItemTypeModel.LINK) {
				router.push(`${item.link}`)
			}
		}
	}, [closeModals, openModal, router])

	useEffect(() => {
		const isPlayerInTheRoom = (e) => {
			setJoinedRoom((e as any).detail.roomName || "");
		}

		window.addEventListener('HODL_VALLEY__UNITY_PLAYER_IN_ROOM_RESPONSE', isPlayerInTheRoom, false)

		window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_IS_PLAYER_IN_ROOM', {}) as Event)

		return () => {
			window.removeEventListener('HODL_VALLEY__UNITY_PLAYER_IN_ROOM_RESPONSE', isPlayerInTheRoom, false)
		}
	}, [])

	return (
		<Modal isOpen={true}
					 title={{ firstLine: screenWidth < responsiveSizes.xs ? 'HODLVERSE airport' : 'HODLVERSE', secondLine: screenWidth < responsiveSizes.xs ? '' : 'airport' }}
					 description={'The property used to travel to other cities on the network and create or join multiplayer rooms in the HODLVERSE network.'}
					 size={Sizes.SMALLER}
					 mainIconName={'Airport'}>
			<div className={`h-full relative xs:h-500px`}>
				<div className={`transition-opacity h-full w-full absolute top-0 left-0 py-85px px-70px xs:py-40px xs:px-31px opacity-100`}>
					<div className={`h-full flex flex-col justify-center`}>
						{list.map((item, index) => (
							<div className={'mb-20px'} key={index}>
								<ModalMenuItem label={item.label} onSelect={() => selectSwapModal(item)} disabled={!isActive || item.disabled}/>
							</div>
						))}
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default AirportModal
