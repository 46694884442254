import { useEffect, useState } from 'react'

import CameraPersonIcon from '../../../public/icons/camera-1st.svg'
import CameraZoomIcon from '../../../public/icons/camera-zoom.svg'
import CameraISOIcon from '../../../public/icons/camera-iso.svg'

enum CameraView {
	ISO = 'ISO',
	ZOOM = 'PERSON',
	PERSON = '1ST'
}

const CameraViewSwitcher: React.FC = () => {
	const [hide, setHide] = useState(false);
	const [currentView, setCurrentView] = useState(CameraView.ISO)

	const changeView = () => {
		let newView = currentView;
		switch (currentView) {
			case CameraView.ISO:
				newView = CameraView.ZOOM;
				break;

			case CameraView.ZOOM:
				newView = CameraView.PERSON;
				break;

			case CameraView.PERSON:
				newView = CameraView.ISO;
				break;
		}

		setCurrentView(newView)
		window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_CHANGE_CAMERA_VIEW', { detail: newView }) as Event)
	}

	useEffect(() => {
		const enterLeaveBuilding = (e) => {
			const isEnter = (e as any).detail.isEnter
			setHide(isEnter);
		}

		window.addEventListener('HODL_VALLEY__UNITY_ENTER_LEFT_BUILDING', enterLeaveBuilding, false)

		return () => {
			window.removeEventListener('HODL_VALLEY__UNITY_ENTER_LEFT_BUILDING', enterLeaveBuilding)
		}
	}, [])

	return (
		<div className={`backdrop-filter-blur-50 w-68px h-68px rounded-34 bg-white bg-opacity-80 border-4 border-white flex items-center justify-center
									xs:w-44px xs:h-44px xs:overflow-hidden xs:border-gray-200 xs:p-6px
									xs:backdrop-filter-none cursor-pointer ${hide && "hidden"}`}
				 onClick={changeView}>
				{currentView === CameraView.ISO
					? <CameraISOIcon viewBox={"0 0 31 21"}/>
					: currentView === CameraView.ZOOM
						? <CameraZoomIcon viewBox={"0 0 31 21"}/>
						:  <CameraPersonIcon viewBox={"0 0 31 21"}/>}
		</div>
	)
}

export default CameraViewSwitcher
