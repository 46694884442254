/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useCallback } from 'react'
import Image from 'next/image'
import { FixedSizeList } from 'react-window'
import { Token } from '@hodlvalley/sdk'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { Button, ButtonType, Tooltip } from '../index'
import Input from './Input'
import CurrencyLogo from '../CurrencyLogo'
import TokenInRow from 'components/TokenDropdown/TokenInRaw'
import NoResults from 'components/TokenDropdown/NoResults'

import { useActiveWeb3React } from 'hooks'
import { useToken } from 'hooks/Tokens'
import { useWindowSize } from 'hooks/useWindowSize'

import { useRemoveUserAddedToken, useUserAddedTokens } from 'state/user/hooks'

import { shortenStringAsAddress } from 'functions'
import { isAddress } from 'functions/validate'
import { getExplorerLink } from 'functions/explorer'

import { Sizes } from 'types/Sizes'

import { responsiveSizes } from 'constants/sizes'

import styles from './tokenDropdown.module.scss'
import ManageButton from 'components/TokenDropdown/manageButton'

interface ManageTokensProps {
	onManageList: () => void;
	onAddCustomToken?: (token: Token) => void;
	isSecond?: boolean;
}

const ManageTokens: React.FC<ManageTokensProps> = ({ onManageList, onAddCustomToken, isSecond }) => {
	const { chainId } = useActiveWeb3React()
	const { width: screenWidth } = useWindowSize()
	const { i18n } = useLingui()

	const [searchQuery, setSearchQuery] = useState<string>('')
	const [shortAddress, setShortAddress] = useState(searchQuery)
	const [focusedAddressInput, setFocusedAddressInput] = useState(false)

	const handleInput = useCallback((input: string) => {
		const checksummedInput = isAddress(input)
		setSearchQuery(checksummedInput || input)
		setShortAddress(shortenStringAsAddress(input))
	}, [])

	// if they input an address, use it
	const searchToken = useToken(searchQuery)

	// all tokens for local lisr
	const userAddedTokens: Token[] = useUserAddedTokens()
	const removeToken = useRemoveUserAddedToken()

	const handleRemoveAll = useCallback(() => {
		if (chainId && userAddedTokens) {
			userAddedTokens.map((token) => {
				return removeToken(chainId, token.address)
			})
		}
	}, [removeToken, userAddedTokens, chainId])

	const Row = useCallback(
		({ data, index, style }) => {
			const token = data[index]

			return (
				<div
					className={'flex items-center justify-between pb-18px xs:pb-8px border-b-3 border-gray-260 mb-18px last:border-0 last:mb-0'}
					key={index} style={style}>
					<TokenInRow token={token} wrapClassname={'w-8/12'} textWrapClassname={'xs:text-xs'} />
					<div className={'flex items-center justify-end pr-10px xs:pr-0 -mt-9px xs:mt-4px w-4/12 h-full xs:items-end'}>
						<div className={'mr-24px xs:mr-19px xs:mb-10px flex items-center justify-center cursor-pointer'}
								 onClick={() => removeToken(chainId, token.address)}>
							<Image src={'/icons/iconCrossTiny.svg'} width={screenWidth < responsiveSizes.xs ? 12 : 21} height={21}/>
						</div>
						<a className={'mt-5px cursor-pointer'} href={getExplorerLink(chainId, token.address, 'address')}
							 target={'_blank'} rel="noreferrer">
							<Image src={'/icons/iconShare.svg'} width={screenWidth < responsiveSizes.xs ? 20 : 29} height={29}/>
						</a>
					</div>
				</div>
			)
		},
		[chainId, removeToken, screenWidth]
	)

	return (
		<div className={`${styles.formWrapper}`} id={`manage-tokens-dropdown-${isSecond ? 'second' : 'first'}-id`}>
			<div className={styles.formSearchWrapper}>
				<Input value={focusedAddressInput ? searchQuery : shortAddress}
							 onChange={(val) => {
								 handleInput(val)
							 }}
							 placeholder={focusedAddressInput ? '' : `${i18n._(t`Enter token contract address`)}...`}
							 id={`manage-tokens-dropdown-input-${isSecond ? 'second' : 'first'}-id`}
							 onFocus={() => setFocusedAddressInput(true)}
							 onBlur={() => setFocusedAddressInput(false)}/>
			</div>
			<div className={'px-35px xs:pl-17px xs:pr-11px mt-26px xs:mt-24px'}>
				{!userAddedTokens.length && !searchToken && <NoResults/>}
				{!!userAddedTokens.length &&
					<div className={'flex justify-between xs:-mt-2px'}>
						<p className={'text-sm xs:text-xs uppercase text-gray-400 font-bold'}>{userAddedTokens.length} tokens added</p>
						<p className={'text-sm xs:text-xs uppercase text-gray-400 font-bold text-right cursor-pointer'}
							 id={`manage-tokens-dropdown-remove-all-${isSecond ? 'second' : 'first'}-id`}
							 onClick={handleRemoveAll}>remove all</p>
					</div>
				}
			</div>
			<div className={`h-130px px-35px xs:pl-17px xs:pr-14px mt-8px xs:mt-0 flex flex-col overflow-y-scroll`}>
				{searchToken &&
					<div className={'flex items-center justify-between pb-18px border-b-3 xs:border-b-2 border-gray-260 mb-18px last:border-0 last:mb-0'}>
						<TokenInRow token={(searchToken as Token)} wrapClassname={'xs:w-8/12'} />
						<div className={'flex items-center pr-10px -mt-9px xs:mt-0 xs:-mb-13px'}>
							<Button type={ButtonType.GHOST}
											size={Sizes.EXTRA_SMALL}
											onClick={() => {
												onAddCustomToken && onAddCustomToken(searchToken)
												handleInput('')
											}}>
								id={`manage-tokens-dropdown-add-token-btn-${isSecond ? 'second' : 'first'}-id`}
								<p className={'tracking-wider'}>{i18n._(t`Add`)}</p>
							</Button>
						</div>
					</div>
				}
				<FixedSizeList
					width={'100%'}
					height={screenWidth < responsiveSizes.xs ? 140 : 160}
					itemData={userAddedTokens}
					itemCount={userAddedTokens.length}
					itemSize={70}
				>
					{Row}
				</FixedSizeList>
			</div>
			<ManageButton text={`${i18n._(t`Manage lists`)}`} handleClick={onManageList} wrapClassname={'xs:mt-5px'} />
		</div>
	)
}

export default ManageTokens
