import {ReactNode, useCallback, useState} from "react";

import styles from './modalMenuItem.module.scss'

interface ModalMenuItemProps {
  label: string;
  onSelect?: () => void;
  disabled?: boolean;
  suffix?: ReactNode;
  selected?: boolean;
}

const ModalMenuItem: React.FC<ModalMenuItemProps> = ({label, onSelect, disabled = false, suffix, selected}) => {
  const [hover, setHover] = useState(false);

  const handleSelect = useCallback(() => {
    !disabled && onSelect && onSelect();
  }, [onSelect, disabled]);

  return (
    <div className={`border-white w-full rounded-full flex items-center cursor-pointer transition-opacity 
                     text-opacity-60 text-gray-400 hover:text-opacity-100 hover:bg-white 
                     pt-17px pb-15px px-44px xs:text-opacity-100 xs:pt-13px xs:pb-9px xs:px-22px
                     ${disabled ? 'bg-gray-250 border-0' : 'bg-gray-50 border-3 xs:bg-white'} ${selected && !disabled ? 'text-opacity-100 bg-white' : ''} ${styles.btn}`}
         onClick={handleSelect}
         id={`list-item-${label}-id`}
         onMouseEnter={ ()=> setHover(true) }
         onMouseLeave={ ()=> setHover(false) }>
      <p className={'uppercase text-xl font-medium overflow-ellipsis overflow-hidden w-full whitespace-nowrap xs:text-base xs:tracking-normal'}>
        {label}
      </p>
      {suffix &&
        <div className={`${hover || selected ? 'opacity-100' : 'opacity-60'}`}>
          {suffix}
        </div>
      }
    </div>
  )
}

export default ModalMenuItem
