import gql from 'graphql-tag'
import { FARMING_ADDRESS } from "@hodlvalley/sdk";

export const findPoolByIdQuery = gql`
  query farmings($where: Pool_filter!) {
    pools(where: $where) {
      id
      pair
      allocPoint
      lastRewardBlock
      balance
      userCount
      owner {
        id
        money
        totalAllocPoint
      }
    }
  }
`
export const poolsQuery = gql`
  query farmings(
    $first: Int! = 1000
    $skip: Int! = 0
    $orderBy: Pool_orderBy! = id
    $orderDirection: OrderDirection! = desc
    $where: Pool_filter! = { allocPoint_gt: 0 }
  ) {
    pools(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection, where: $where) {
      id
      pair
      allocPoint
      lastRewardBlock
      balance
      userCount
      owner {
        id
        money
        totalAllocPoint
      }
    }
  }
`

export const masterChefV1PairAddressesQuery = gql`
  query farmings(
    $first: Int! = 1000
    $skip: Int! = 0
    $orderBy: Pool_orderBy! = id
    $orderDirection: OrderDirection! = desc
    $where: Pool_filter! = { allocPoint_gt: 0, }
  ) {
    pools(first: $first, skip: $skip, orderBy: $orderBy, orderDirection: $orderDirection, where: $where) {
      id
      allocPoint
      pair
    }
  }
`

export const masterChefV1TotalAllocPointQuery = gql`
  query masterChefV1TotalAllocPoint($id: String! = "${FARMING_ADDRESS[3]}") {
    farmings(id: $id) {
      id
      totalAllocPoint
    }
  }
`

export const masterChefV1SushiPerBlockQuery = gql`
  query masterChefV1SushiPerBlock($id: String! = "${FARMING_ADDRESS[3]}") {
    farmings(id: $id) {
      id
      sushiPerBlock
    }
  }
`
