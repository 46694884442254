import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { classNames, limitNumber, updateElSize } from 'functions'

const defaultClassName = 'w-0 p-0 text-2xl bg-transparent'

export const Input = React.memo(
  ({
    value,
    onUserInput,
    placeholder,
    className = defaultClassName,
    autosize = false,
    ...rest
  }: {
    value: string | number
    onUserInput: (input: string) => void
    error?: boolean
    fontSize?: string
    align?: 'right' | 'left'
    autosize?: boolean
  } & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) => {
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
      autosize && updateElSize(inputRef.current, value.toString());
    }, [autosize, value])

    const enforcer = useCallback((nextUserInput: string) => {
      onUserInput && onUserInput(limitNumber(nextUserInput, 9, 8))
    }, [onUserInput]);

    const correctSize = useMemo(() => {
      const count = value ? value.toString()?.length : 1;

      if (count <= 6) {
        return 'text-34px'
      }

      if (count <= 7) {
        return 'text-28px'
      }

      if (count <= 8) {
        return 'text-25px'
      }

      if (count <= 9) {
        return 'text-2xl'
      }

      if (count <= 10) {
        return 'text-22px'
      }

      if (count <= 11) {
        return 'text-xl'
      }

      return 'text-xl'
    }, [value]);

    return (
      <input
        {...rest}
        value={value}
        onChange={(event) => {
          // replace commas with periods, because uniswap exclusively uses period as the decimal separator
          enforcer(event.target.value.replace(/,/g, '.'))
        }}
        ref={inputRef}
        // universal input options
        inputMode="decimal"
        title="Token Amount"
        autoComplete="off"
        autoCorrect="off"
        // text-specific options
        type="text"
        pattern="^[0-9]*[.,]?[0-9]*$"
        placeholder={placeholder || '0.0'}
        min={0}
        minLength={1}
        maxLength={79}
        spellCheck="false"
        className={classNames(
          'relative',
          className,
          correctSize
        )}
      />
    )
  }
)

Input.displayName = 'NumericalInput'

export default Input

// const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
