import { createReducer } from '@reduxjs/toolkit'
import {
  setPoolData,
    addBookmarkPool
} from './actions'
import {ChainId, Pair} from "@hodlvalley/sdk";

type BookMarkedPoolMap = { [chainId: number]: Array<string> }

export interface PoolState {
  readonly poolData: Pair | null
  readonly isUserPool: boolean
  readonly bookmarkedPools: BookMarkedPoolMap
}

const initialState: PoolState = {
  poolData: null,
  isUserPool: false,
  bookmarkedPools: {
      [ChainId.MAINNET]: [],
      [ChainId.ROPSTEN]: []
  }
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setPoolData, (state, action) => {
      state.poolData = action.payload.pool;
      state.isUserPool = action.payload.isUserPool;
    })
      .addCase(addBookmarkPool, (state, {payload: {customPool}})=>{
          const oldBookmarkedPools:BookMarkedPoolMap  = state.bookmarkedPools || {};


          let currentPools:Array<string> = oldBookmarkedPools[customPool.chainId]

          if (Array.isArray(currentPools)) {
              if (!currentPools.includes(customPool.lpAddress)) {
                  currentPools.push(customPool.lpAddress)
              }
          } else {
              currentPools = [customPool.lpAddress]
          }
          if (state.bookmarkedPools) {
              state.bookmarkedPools[customPool.chainId] = currentPools
          } else {
              state.bookmarkedPools = {
                  [customPool.chainId]: currentPools
              }
          }

      })
)
