import React, { useCallback } from 'react'
import Image from 'next/image'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { Sizes } from 'types/Sizes'

import { Button, ButtonType } from '../index'

import { useWindowSize } from 'hooks/useWindowSize'

import { responsiveSizes } from 'constants/sizes'

export interface BackButtonProps {
	onClick?: (e) => void;
	size?: Sizes;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick, size = Sizes.SMALL }) => {
	const { width: screenWidth } = useWindowSize()
	const { i18n } = useLingui()

	const handleClick = useCallback((e) => {
		onClick && onClick(e)
	}, [onClick])

	return (
		<Button type={ButtonType.GHOST}
						size={size}
						id={"back-button-id"}
						leftIcon={<Image src={'/icons/arrowLeft.svg'} alt={'Back'} width={screenWidth < responsiveSizes.xs ? 10 : 16}
														 height={24}/>}
						onClick={handleClick}>
			<p className={`text-gray-400 uppercase ${size === Sizes.EXTRA_SMALL ? 'text-xl pl-27px xs:pl-12px' : 'pl-16px xs:pl-21px'} xs:text-base`}>
				{i18n._(t`Back`)}
			</p>
		</Button>
	)
}

export default BackButton