import { MONEY, CurrencyAmount, JSBI, FARMING_ADDRESS } from '@hodlvalley/sdk'
import { Chef } from './enum'
import {ADDRESS_ZERO, MASTERCHEF_V2_ADDRESS, MINICHEF_ADDRESS} from '../../constants'
import { useSingleCallResult, useSingleContractMultipleData,  } from '../../state/multicall/hooks'
import { useCallback, useMemo } from 'react'
import { useMasterChefContract, useMasterChefV2Contract, useMiniChefContract } from '../../hooks'

import { Contract } from '@ethersproject/contracts'
import { Zero } from '@ethersproject/constants'
import concat from 'lodash/concat'
import { useActiveWeb3React } from '../../hooks/useActiveWeb3React'
import zip from 'lodash/zip'
import {BigNumber} from "ethers";

export function useChefContract(chef: Chef) {
  const masterChefContract = useMasterChefContract()
  const contracts = useMemo(
    () => ({
      [Chef.MASTERCHEF]: masterChefContract,
    }),
    [masterChefContract,]
  )
  return useMemo(() => {
    return contracts[chef]
  }, [contracts, chef])
}



export function useChefContracts(chefs: Chef[]) {
  const masterChefContract = useMasterChefContract()
  const masterChefV2Contract = useMasterChefV2Contract()
  const miniChefContract = useMiniChefContract()
  const contracts = useMemo(
    () => ({
      [Chef.MASTERCHEF]: masterChefContract,
      [Chef.MASTERCHEF_V2]: masterChefV2Contract,
      [Chef.MINICHEF]: miniChefContract,
    }),
    [masterChefContract, masterChefV2Contract, miniChefContract]
  )
  return chefs.map((chef) => contracts[chef])
}

export function useUserInfo(farm, token) {
  const { account } = useActiveWeb3React()

  const contract = useChefContract(farm.chef)

  const args = useMemo(() => {
    if (!account) {
      return
    }
    return [String(farm.id), String(account)]
  }, [farm, account])

  const result = useSingleCallResult(args ? contract : null, 'userInfo', args)?.result

  const value = result?.[0]

  const amount = value ? JSBI.BigInt(value.toString()) : undefined

  return amount ? CurrencyAmount.fromRawAmount(token, amount) : undefined
}

export function usePendingMoney(farm) {
  const { account, chainId } = useActiveWeb3React()

  const contract = useChefContract(farm.chef)

  const args = useMemo(() => {
    if (!account) {
      return
    }
    return [String(farm.id), String(account)]
  }, [farm, account])


  const result = useSingleCallResult(args ? contract : null, 'pendingMoney', args)?.result

  const value = result?.[0]

  const amount = value ? JSBI.BigInt(value.toString()) : undefined

  return amount ? CurrencyAmount.fromRawAmount(MONEY[chainId], amount) : undefined
}

export function usePendingToken(farm, contract) {
  const { account } = useActiveWeb3React()

  const args = useMemo(() => {
    if (!account || !farm) {
      return
    }
    return [String(farm.pid), String(account)]
  }, [farm, account])

  const pendingTokens = useSingleContractMultipleData(
    args ? contract : null,
    'pendingTokens',
    args.map((arg) => [...arg, '0'])
  )

  return useMemo(() => pendingTokens, [pendingTokens])
}

export function useChefPositions(contract?: Contract | null, rewarder?: Contract | null) {
  const { account, chainId } = useActiveWeb3React()

  const rawNumberOfPools = useSingleCallResult(contract ? contract : null, 'poolLength', undefined, )?.result?.[0]


  const numberOfPools = rawNumberOfPools ?  rawNumberOfPools.add(1) : BigNumber.from(1)

  const pendingArgs = useMemo(() => {
    if (!account || !numberOfPools) {
      return
    }
    return [...Array(numberOfPools.toNumber()).keys()].map((pid) => [String(pid+1), String(account)])
  }, [numberOfPools, account])

  const farmsArgs = useMemo(() => {
    if (!account || !numberOfPools) {
      return
    }
    return [...Array(numberOfPools.toNumber()).keys()].map((pid) => [String(pid+1)])
  }, [numberOfPools, account])


  const pendingMoney = useSingleContractMultipleData(pendingArgs ? contract : null, 'pendingMoney', pendingArgs)


  const farms = useSingleContractMultipleData(farmsArgs ? contract : null, 'farms',  farmsArgs)



  const userInfoArgs = useMemo(()=>{
    return farms.map((data)=>{
      return [String(data?.result?.[0] ? data?.result?.[0] : ADDRESS_ZERO), String(account)]
    });
  }, [farms, account]);

  const userInfo = useSingleContractMultipleData(userInfoArgs ? contract : null, 'userInfo', userInfoArgs)


  // const pendingTokens = useSingleContractMultipleData(
  //     rewarder,
  //     'pendingTokens',
  //     args.map((arg) => [...arg, '0'])
  // )


  const getChef = useCallback(() => {
    if (FARMING_ADDRESS[chainId] === contract.address) {
      return Chef.MASTERCHEF
    } else if (MASTERCHEF_V2_ADDRESS[chainId] === contract.address) {
      return Chef.MASTERCHEF_V2
    } else if (MINICHEF_ADDRESS[chainId] === contract.address) {
      return Chef.MINICHEF
    }
  }, [chainId, contract])



  return  useMemo(() => {
    if (!pendingMoney || !farms || !userInfo) {
      return []
    }
    return zip(pendingMoney, farms, userInfo)
      .map((data, i) => {
        return {
          id: data?.[1]?.result?.[0] || ADDRESS_ZERO,
          pendingMoney: data?.[0]?.result?.[0] || Zero,
          amount: data[2].result?.[0] || Zero,
          chef: getChef(),
          // pendingTokens: data?.[2]?.result,
        }
      })
        .filter(({ pendingMoney, amount }) => {
          return (pendingMoney && !pendingMoney.isZero()) || (amount && !amount.isZero())
        })
  }, [getChef, pendingMoney, farms, userInfo])
}

export function usePositions() {
  const [masterChefV1Positions,] = [
    useChefPositions(useMasterChefContract()),
  ]
  return concat(masterChefV1Positions)
}
