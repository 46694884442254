import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Image from 'next/image'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import ListLogo from 'components/ListLogo'
import { Tooltip } from 'components/index'

import { AppDispatch, AppState } from 'state'
import { useIsListActive } from 'state/lists/hooks'
import { disableList, enableList, removeList } from 'state/lists/actions'

import { useWindowSize } from 'hooks/useWindowSize'

import { responsiveSizes } from 'constants/sizes'

import styles from "./tokenDropdown.module.scss"

const ListRow: React.FC<{ listUrl: string; style: any }> = ({ listUrl, style }) => {
	const windowSize = useWindowSize()
	const { i18n } = useLingui()

	const listsByUrl = useSelector<AppState, AppState['lists']['byUrl']>((state) => state.lists.byUrl)
	const dispatch = useDispatch<AppDispatch>()

	const { current: list, pendingUpdate: pending } = listsByUrl[listUrl]

	const isActive = useIsListActive(listUrl)

	const handleRemoveList = useCallback(() => {
		dispatch(removeList(listUrl))
	}, [dispatch, listUrl])

	const handleEnableList = useCallback(() => {
		dispatch(enableList(listUrl))
	}, [dispatch, listUrl])

	const handleDisableList = useCallback(() => {
		dispatch(disableList(listUrl))
	}, [dispatch, listUrl])

	if (!list) return null

	return (
		<div className={'flex items-center justify-between pb-0px border-b-3 border-gray-260 mb-18px last:border-0 last:mb-0'} style={style}>
			<div className={'flex items-center w-1/2 xs:w-7/12'}>
				<ListLogo size={windowSize.width < responsiveSizes.xs ? "20px" : "32px"} logoURI={list.logoURI} alt={`${list.name} list logo`} />
				<div className={`flex flex-col pl-8px pt-2px w-full ${styles.tokenNameWrapper}`}>
					<div className={'w-full truncate max-w-full'}>
						<Tooltip message={list.name}>
							<span className={'uppercase text-sm xs:text-xs text-gray-400 font-bold xs:font-medium truncate max-w-full'}>{list.name}</span>
						</Tooltip>
					</div>
					<p className={'uppercase text-15px xs:text-xs text-gray-400 font-bold xs:font-medium pt-1px'}>{list.tokens.length} {i18n._(t`Tokens`)}</p>
				</div>
			</div>
			<div className={'flex items-center xs:items-end justify-end -mt-9px xs:mt-24px xs:pr-3px w-1/2 xs:w-5/12'}>
				<div className={'mr-24px xs:mr-13px flex items-center justify-center'} onClick={handleRemoveList}>
					<Image src={'/icons/iconCrossTiny.svg'} alt={'delete'} width={windowSize.width < responsiveSizes.xs ? 12 : 21} height={21} />
				</div>
				<a className={'mr-18px xs:mr-13px mt-3px xs:mt-0 xs:mb-2px flex items-center justify-center'} href={`https://tokenlists.org/token-list?url=${listUrl}`} target={"_blank"} rel="noreferrer">
					<Image src={'/icons/iconShare.svg'} alt={'share'}
								 width={windowSize.width < responsiveSizes.xs ? 20 : 29}
								 height={windowSize.width < responsiveSizes.xs ? 20 : 29} />
				</a>
				<button
				  onClick={() => isActive ? handleDisableList() : handleEnableList()}
				  className={`uppercase font-medium text-sm xs:text-xs flex justify-center items-center -mt-3px w-47px h-28px rounded-14 
				  					${isActive ? 'text-white bg-gray-400' : 'text-gray-310 border-3 border-gray-270'}`}>
				  {isActive ? `${i18n._(t`On`)}` : `${i18n._(t`Off`)}`}
				</button>
			</div>
		</div>
	)
}

export default ListRow;
