import React, { useCallback, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { FixedSizeList } from 'react-window'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Modal, Button, ButtonType } from 'components'

import { Sizes } from 'types/Sizes'

import SuccessIcon from '../../../public/icons/iconSuccess.svg'

import styles from './createViewRoom.module.scss'
import { useWindowSize } from 'hooks/useWindowSize'
import { responsiveSizes } from 'constants/sizes'
import { safelyParseJSON } from '../../crosschainswap/ConfirmCrossChainSwapModal'
import useMultiplayer from 'state/multiplayer/hooks'

export interface RoomPersonModel {
	id?: string;
	PlayerName: string;
	joined?: boolean;
}

interface RoomInfoProps {
	airportPageLink: string;
	name: string;
}
//HODL_VALLEY__UNITY_DISCONNECT_FROM_PHOTON (Please call this event when player cancel to join multiplayer)
const RoomInfo: React.FC<RoomInfoProps> = ({ name, airportPageLink = '' }) => {
	const router = useRouter()
	const { width: screenWidth } = useWindowSize()
	const { isJoinedRoom, joinedRoomName, setJoinedRoom } = useMultiplayer();

	const [personList, setPersonList] = useState<RoomPersonModel[]>([
		// { id: '1', firstname: 'Damon', lastname: 'Nam', joined: false }
	])

	useEffect(() => {
		const onPlayerLeftRoom = () => {
			console.log('PLAYER LEFT');
		}

		const onUserLeftRoom = () => {
			console.log('USER LEFT');
		}

		const onUserJoinedRoom = (e) => {
			console.log('ROOM, USER JOINED: ', (e as any).detail);
			const newPlayer: {name: string} = (e as any).detail;
			setPersonList(pl => !pl.some(x => x.PlayerName == newPlayer.name) ? [...pl, {PlayerName: newPlayer.name}] : pl)
		}

		const onPlayerJoinedRoom = (e) => {
			console.log('PLAYER JOINED', (e as any).detail);
		}

		const onGetPlayerList = (e) => {
			console.log('GOT LIST OF PLAYERS');
			const players = safelyParseJSON((e as any).detail.list) || [];
			if (Array.isArray(players) && players.length) {
				setPersonList(players);
			}
		}

		window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_GET_ROOM_INFO', {detail: {roomName: name}}) as Event);

		window.addEventListener('HODL_VALLEY__UNITY_PLAYER_LEFT_ROOM', onPlayerLeftRoom, false)
		window.addEventListener('HODL_VALLEY__UNITY_USER_LEFT_ROOM', onUserLeftRoom, false)
		window.addEventListener('HODL_VALLEY__UNITY_USER_JOINED_ROOM', onUserJoinedRoom, false)
		window.addEventListener('HODL_VALLEY__UNITY_PLAYER_JOINED_ROOM', onPlayerJoinedRoom, false)
		window.addEventListener('HODL_VALLEY__UNITY_LIST_OF_PLAYERS', onGetPlayerList, false)

		return () => {
			window.removeEventListener('HODL_VALLEY__UNITY_PLAYER_LEFT_ROOM', onPlayerLeftRoom)
			window.removeEventListener('HODL_VALLEY__UNITY_USER_LEFT_ROOM', onUserLeftRoom)
			window.removeEventListener('HODL_VALLEY__UNITY_USER_JOINED_ROOM', onUserJoinedRoom)
			window.removeEventListener('HODL_VALLEY__UNITY_PLAYER_JOINED_ROOM', onPlayerJoinedRoom)
			window.removeEventListener('HODL_VALLEY__UNITY_LIST_OF_PLAYERS', onGetPlayerList)
		}
	}, [name]);

	const toggleJoin = useCallback((id: string) => {
		const index = personList.findIndex(x => x.id === id)
		if (index > -1) {
			personList[index].joined = !personList[index].joined
			setPersonList([...personList])
		}
	}, [personList])

	const RenderRow = React.useCallback(
		({ index, style, data }) => {
			const roomPerson = data[index] as RoomPersonModel
			if (roomPerson) {
				return (
					<div key={data[index].id} style={style} className={'px-10px'} onClick={() => toggleJoin(roomPerson.id)} id={`room-info-person-${index}-id`}>
						<div className={`w-full rounded-full flex items-center cursor-pointer transition-opacity text-gray-400 border-white border-3 bg-gray-50 pl-44px pr-9px py-6px xs:px-15px ${styles.listItem}`}>
							<p className={'capitalize text-xl xs:text-base font-medium overflow-ellipsis overflow-hidden w-full whitespace-nowrap'}>{roomPerson?.PlayerName || `Player ${index + 1}`} </p>
							<div className={'w-44px h-44px'}>
								<SuccessIcon className={`${roomPerson?.joined ? styles.successIconActive : styles.successIcon} w-full h-full`} viewBox="0 0 49 49"/>
							</div>
						</div>
					</div>
				)
			}

			return (
				<div key={data.length} style={style} className={'px-10px'}>
					<div className={'flex items-center justify-center border-dashed border-gray-700 border-2 rounded-52 py-17px xs:py-12px cursor-pointer'}>
						<p className={'text-gray-700 uppercase text-xl xs:text-base font-medium'}>Invite Friends</p>
					</div>
				</div>
			)
		},
		[toggleJoin]
	)

	return (
		<Modal isOpen={true}
					 title={{ firstLine: name, secondLine: 'room' }}
					 description={'The property used to travel to other cities on the network and create or join multiplayer rooms in the HODLVERSE network.'}
					 size={Sizes.SMALLER}
					 mainIconName={'Airport'}>
			<div className={`h-full relative xs:h-500px`}>
				<div className={`transition-opacity h-full w-full absolute top-0 left-0 p-40px opacity-100 xs:py-40px xs:px-20px`}>
					<div className={`h-full flex flex-col`}>
						<div className={`${styles.listWrapper}`}>
							<FixedSizeList
								outerElementType={(props) => <PerfectScrollbar {...props} options={{ suppressScrollX: true }}/>}
								height={308}
								itemData={personList.concat(undefined)}
								itemCount={personList.length + 1}
								itemSize={86}
								width={'100%'}
							>
								{RenderRow}
							</FixedSizeList>
						</div>
						<div className={'mt-30px px-11px flex items-center justify-between'}>
							<Button type={ButtonType.GHOST} size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.BIG}
											id={'view-room-leave-btn-id'}
											disabled={!joinedRoomName || joinedRoomName !== name}
											onClick={() => {
												window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_LEAVE_ROOM', {detail: {roomName: name}}) as Event);
												router.push(airportPageLink)
												setJoinedRoom("");
											}}>Leave</Button>

							<Button size={Sizes.BIG}
											disabled={isJoinedRoom}
											id={'view-room-start-btn-id'}
								onClick={() => {
									setJoinedRoom(name);
									window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_START_GAME') as Event);
									router.push("/")
								}}>Start</Button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default RoomInfo
