/* eslint-disable jsx-a11y/alt-text */
import Image from 'next/image'

import {ToastTypes} from "hooks/useToast";
import { useWindowSize } from 'hooks/useWindowSize'

import { responsiveSizes } from 'constants/sizes'

import styles from "./toast.module.scss"

interface ToastProps {
  text: string;
  type?: ToastTypes;
  autosize?: boolean;
}

const Toast: React.FC<ToastProps> = ({text, type = ToastTypes.error, autosize = false}) => {
  const { width: screenWidth } = useWindowSize()

  return (
  <div className={`flex xs:flex-col items-center justify-between rounded-full xs:rounded-32 border-5 pt-11px pb-8px pl-30px pr-80px w-full 
                  ${type === ToastTypes.success ? `${styles.wrapperSuccess} bg-green-400 border-green-500` : `${styles.wrapperError} bg-red-400 border-red-300`}
                  ${!autosize ? 'w-900px' : ''} xs:w-full xs:px-24px xs:py-14px`}>
    <div className={'flex items-center'}>
      <div className={'cursor-pointer pt-4px'}>
        {type === ToastTypes.success ?
          <Image src={"/icons/iconSuccess.svg"} alt={'Success'}
                 width={screenWidth < responsiveSizes.xs ? 45 : 48}
                 height={screenWidth < responsiveSizes.xs ? 39 : 42} />
          :
          <Image src={"/icons/iconWarning.svg"} alt={'Warning'}
                 width={screenWidth < responsiveSizes.xs ? 37 : 48}
                 height={screenWidth < responsiveSizes.xs ? 31 : 42} />
        }
      </div>
      <hr className={`${styles.delimiter} ml-24px`} />
      <p className={`text-base text-white font-medium xs:font-bold tracking-wider px-32px xs:text-center xs:leading-4 xs:mt-4px ${styles.text}`}>{text}</p>
    </div>
    <hr className={styles.delimiter} />
  </div>
)};

export default Toast;
