import { ReactNode, useCallback, useEffect, useState } from 'react'

import { Sizes } from 'types/Sizes'
import { useInterval } from 'analytics/core/hooks'

export enum ButtonType {
	ACTIVE = 'ACTIVE',
	GHOST = 'GHOST',
	ALERT = 'ALERT'
}

export interface ButtonProps {
	children: ReactNode;
	onClick?: (e) => void;
	leftIcon?: ReactNode;
	type?: ButtonType;
	size?: Sizes;
	disabled?: boolean;
	notClickable?: boolean;
	error?: boolean;
	confirmed?: boolean;
	wrapClassname?: string;
	timerSec?: number;
	onTimerEnd?: () => void;
	id?: string;
}

const Button: React.FC<ButtonProps> = ({
																				 children,
																				 onClick,
																				 leftIcon,
																				 type = ButtonType.ACTIVE,
																				 size = Sizes.MEDIUM,
																				 disabled = false,
																				 error = false,
																				 confirmed = false,
																				 notClickable = false,
																				 wrapClassname = '',
																				 timerSec,
																				 onTimerEnd,
	id
																			 }) => {
	const [countdown, setCountdown] = useState<number>()

	const handleClick = useCallback((e) => {
		onClick && onClick(e)
	}, [onClick])

	useInterval(() => {
		if (timerSec) {
			const val = countdown - 1 < 0 ? timerSec : countdown - 1
			setCountdown(val)

			if (countdown - 1 < 0 && onTimerEnd) {
				onTimerEnd()
			}
		}
	}, timerSec ? 1000 : null)

	useEffect(() => {
		timerSec && setCountdown(timerSec)
	}, [timerSec])

	const formatCountdown = useCallback((secs: number) => {
		const sec_num = secs
		const hours = Math.floor(sec_num / 3600)
		const minutes = Math.floor(sec_num / 60) % 60
		const seconds = sec_num % 60

		return [hours, minutes, seconds]
			.map(v => v < 10 ? '0' + v : v)
			.filter((v, i) => v !== '00' || i > 0)
			.join(':')
	}, [])


	return (
		<button onClick={disabled ? null : handleClick}
						id={id}
						className={`bg-blue-400 relative flex justify-center items-center font-medium text-xl rounded-70 uppercase cursor-pointer tracking-normal
          ${notClickable ? 'pointer-events-none' : ''}
          ${type === ButtonType.ACTIVE && !disabled
							? 'shadow-custom-5 text-white'
							: type === ButtonType.GHOST
								? 'bg-gray-210 text-gray-400 border-3 border-white shadow-custom-2'
								: type === ButtonType.ALERT
									? 'bg-red-450 text-white'
									: 'text-white'} 
          ${size === Sizes.SMALL
							? 'w-159px h-66px xs:w-119px xs:h-44px'
							: size === Sizes.NORMAL
								? 'w-274px h-66px xs:w-135px xs:h-40px xs:text-10px'
								: size === Sizes.EXTRA_SMALL ?
									`h-47px xs:w-83px xs:h-40px xs:text-10px ${leftIcon ? 'w-[123px] xs:w-[90px]' : 'w-99px'}`
									: size === Sizes.BIG
										? 'w-180px h-72px'
										: 'w-269px h-100px xs:w-208px xs:h-58px xs:text-xs'} 
          ${disabled ? 'bg-gray-250 cursor-not-allowed pointer-events-auto shadow-none filter-none text-gray-750' : ''}
          ${wrapClassname}`}>
			{leftIcon &&
			<div className={`absolute ${size === Sizes.EXTRA_SMALL ? 'left-[16px] top-[8px] xs:top-[4px] xs:left-[9px]' : 'top-18px left-20px xs:top-7px xs:left-20px'}`}>
				{leftIcon}
			</div>
			}
			{children} {timerSec && formatCountdown(countdown)}
		</button>
	)
}

export default Button
