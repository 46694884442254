import React, { useMemo } from 'react'
import {  AppState } from '../index'
import { addTransactionToast, updateTransactionToast, removeTransactionToast } from './actions'
import { NotificationModel } from 'components/Notification'
import { toast } from 'react-toastify'
import { Notification } from 'components/index'
import { NotificationToast } from 'state/transactionToast/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'

export type toastOptions  = {
  timeout?: number;
}

// const defaultTimeout = 20000
const defaultTimeout = 100000

const useTransactionToast = () => {
  const dispatch = useAppDispatch()
  const selector = useAppSelector((state: AppState) => state.transactionToast);

  const methods = useMemo(
    () => ({
      showTransactionToast: (id: string, transactionInfo: NotificationModel, options?: toastOptions) => {
        const toastList: NotificationToast[] = [...selector.currentNotificationToastList];

        if (!toastList.some(x => x.id === id)) {
          const toastId = toast.info(<Notification item={transactionInfo} showTime={options?.timeout || defaultTimeout} />, {
            position: "bottom-center",
            autoClose: options?.timeout || defaultTimeout,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            className: 'transaction-toast',
          });

					const notificationToast: NotificationToast = {
						id,
						toastId,
						transactionInfo
					}

          dispatch(addTransactionToast(notificationToast))

          // setTimeout(() => {
          //   const toastList = selector.currentNotificationToastList;
          //   const index = toastList.findIndex(x => x.id === id)
          //   if (index > -1) {
          //     dispatch(removeTransactionToast({id}))
          //   }
          // }, options?.timeout || defaultTimeout)
        }
      },
      updateTransactionToast: (id: string, newTransactionInfo: NotificationModel, options?: toastOptions) => {
        const toastList: NotificationToast[] = selector.currentNotificationToastList;
        const index = toastList.findIndex(x => x.id === id);
        if (index > -1) {
          const currentNotificationToastInfo = toastList[index];

          toast.update(currentNotificationToastInfo.toastId, {
            render: <Notification item={newTransactionInfo} showTime={options?.timeout || defaultTimeout} />,
            type: toast.TYPE.INFO,
            position: "bottom-center",
            autoClose: options?.timeout || defaultTimeout,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            className: 'transaction-toast',
          });

					dispatch(updateTransactionToast({id, toastId: currentNotificationToastInfo.toastId, transactionInfo: newTransactionInfo}))
        } else {
          methods.showTransactionToast(id, newTransactionInfo, options);
        }
      }
    }),
    [dispatch, selector]
  );

  return useMemo(
    () => ({
      ...methods
    }),
    [methods]
  );
};

export default useTransactionToast;