/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useMemo } from 'react'
import { Token } from '@hodlvalley/sdk'
import Image from 'next/image'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { Button, ButtonType } from '../index'
import CurrencyLogo from 'components/CurrencyLogo'

import { shortenAddress } from 'functions'

import { useAddUserToken } from 'state/user/hooks'

import { useWindowSize } from 'hooks/useWindowSize'

import { Sizes } from 'types/Sizes'

import { responsiveSizes } from 'constants/sizes'

import styles from './tokenDropdown.module.scss'

interface AddTokenProps {
	token?: Token;
	onConfirmAdd: (token: Token) => void;
	isSecond?: boolean;
}

const AddToken: React.FC<AddTokenProps> = ({ token, onConfirmAdd, isSecond }) => {
  const windowSize = useWindowSize()
	const addToken = useAddUserToken()
	const { i18n } = useLingui()

	const handleAdd = useCallback((e) => {
		e.preventDefault()
		e.stopPropagation()
		onConfirmAdd(token)
		addToken(token)
	}, [onConfirmAdd, token, addToken]);

  const ActionBtn = useMemo(() => (
		<div className={`relative mt-29px xs:mt-17px`}>
			<div className={'flex justify-center'}>
				<Button type={ButtonType.GHOST} size={Sizes.NORMAL} onClick={handleAdd}>
					<p className={'tracking-wider'}>{i18n._(t`Add`)}</p>
				</Button>
			</div>
		</div>
	), [handleAdd, i18n])

	return token ? (
		<div className={'flex flex-col items-center xs:items-start'} id={`add-token-dropdown-${isSecond ? 'second' : 'first'}-id`}>
			<div className={'flex items-center xs:items-start -ml-10px xs:ml-17px xs:mt-23px'}>
				<CurrencyLogo currency={token} size={windowSize.width < responsiveSizes.xs ? 26 : 49}/>
				<div className={'flex flex-col pl-12px'}>
					<p className={'uppercase text-3xl xs:text-base xs:mt-2px text-gray-400 font-medium xs:order-2 xs:leading-4'} id={`add-token-dropdown-symbol-${isSecond ? 'second' : 'first'}-id`}>{token.symbol}</p>
					<p className={'uppercase text-15px xs:text-base text-gray-400 font-medium -mt-2px xs:order-1 xs:leading-4'} id={`add-token-dropdown-name-${isSecond ? 'second' : 'first'}-id`}>{token.name}</p>
					<p
						className={'text-base xs:text-sm text-underline font-medium text-gray-400 xs:text-gray-700 tracking-normal underline mt-2px xs:mt-4px order-3'}
						id={`add-token-dropdown-address-${isSecond ? 'second' : 'first'}-id`}>
						{shortenAddress(token.address, 6)}
					</p>
				</div>
			</div>
			<div className={'mt-20px xs:mt-16px px-41px xs:px-0'}>
				<div className={`bg-red-400 border-4 xs:border-5 border-red-500 xs:border-red-300 pt-19px xs:pt-24px pl-21px pr-30px pb-20px xs:px-15px rounded-20 xs:rounded-none`}>
					<div className={'flex items-center justify-center pr-25px xs:pr-0'}>
						<div className={'xs:hidden'}>
							<Image src={'/icons/iconWarning.svg'} alt={'Warning'} width={25} height={22}/>
						</div>
						<p className={'ml-12px xs:ml-0 uppercase text-xl xs:text-base text-white font-medium tracking-normal xs:tracking-wider'}>{i18n._(t`Warning`)}</p>
					</div>
					<p className={'text-xs text-white font-medium tracking-normal mt-7px xs:mt-0 xs:text-center xs:text-opacity-70'}>
						{i18n._(t`This token is not on any active token lists. Please ensure this is the token you would like to add.`)}
					</p>
					<div className={'hidden xs:block'} id={`add-token-dropdown-action-btn-wrapper-mobile-${isSecond ? 'second' : 'first'}-id`}>
						{ActionBtn}
					</div>
				</div>
			</div>
			<div className={'xs:hidden'} id={`add-token-dropdown-action-btn-wrapper-${isSecond ? 'second' : 'first'}-id`}>
				{ActionBtn}
			</div>
		</div>
	) : null
}

export default AddToken
