/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

import { Button, ButtonType } from '../index'

import { Sizes } from 'types/Sizes'

interface ManageButtonProps {
	text: string;
	handleClick: () => void;
	wrapClassname?: string;
}

const ManageButton: React.FC<ManageButtonProps> = ({ text, handleClick, wrapClassname = '' }) => (
	<div className={`relative mt-20px ${wrapClassname}`}>
		<div className={'flex justify-center'}>
			<Button type={ButtonType.GHOST} size={Sizes.NORMAL} onClick={handleClick} id={'manage-btn-id'}>
				<p className={'tracking-wider xs:text-10px text-gray-400'}>{text}</p>
			</Button>
		</div>
	</div>
)

export default ManageButton
