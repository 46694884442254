import {useCallback} from "react";
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import styles from "./swaptokens.module.scss"

interface ToggleProps {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
  id?: string;
}

const Toggle: React.FC<ToggleProps> = ({
  id,
                                         enabled,
                                         onChange}) => {
  const { i18n } = useLingui()

  const handleChange = useCallback(() => {
    onChange(!enabled);
  }, [onChange, enabled]);

  return (
    <div className={`rounded-full bg-transparent bg-opacity-40 border-3 xs:border-2 border-gray-310 relative flex justify-between items-center px-11px h-34px w-108px xs:h-30px xs:w-94px`}
    id={id}>
      <span className={'uppercase font-medium text-sm xs:text-xs text-gray-310'}>{i18n._(t`On`)}</span>
      <span className={'uppercase font-medium text-sm xs:text-xs text-gray-310'}>{i18n._(t`Off`)}</span>
      <div className={`absolute top-0 left-0 h-full rounded-full bg-gray-400 ${styles.toggleClicker} 
                      ${enabled ? 'w-47px xs:w-40px translate-x-0' : 'w-51px xs:w-43px translate-x-51px xs:translate-x-50px'}`}
           onClick={handleChange}>
        <div className={`flex justify-center items-center absolute top-0 left-0 w-full h-full transition-opacity ${enabled ? 'opacity-100' : 'opacity-0'}`}>
          <span className={'uppercase font-medium text-sm xs:text-xs text-white'}>{i18n._(t`On`)}</span>
        </div>
        <div className={`flex justify-center items-center absolute top-0 left-0 w-full h-full transition-opacity ${enabled ? 'opacity-0' : 'opacity-100'}`}>
          <span className={'uppercase font-medium text-sm xs:text-xs text-white'}>{i18n._(t`Off`)}</span>
        </div>
      </div>
    </div>
  )
}

export default Toggle;
