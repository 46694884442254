import {ApolloLink, from, HttpLink, split} from "@apollo/client";
import {RetryLink} from "@apollo/client/link/retry";
import {ChainId} from "@hodlvalley/sdk";

// export const uniswap = from([
//     new RetryLink(),
//     new HttpLink({
//         uri: "https://api.thegraph.com/subgraphs/name/osinakayah/v2uniswap",
//         // shouldBatch: true,
//     }),
// ]);

type ApolloLinkForAnalytics = {
    bar: ApolloLink;
    masterchef: ApolloLink;
    exchange: ApolloLink;
    blocklytics: ApolloLink;
    lockup: ApolloLink;
}
const analyticsSubgraph: {[id in ChainId]?: ApolloLinkForAnalytics} = {
    [ChainId.SEPOLIA]: {
        bar: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/sepolia-bar",
                // shouldBatch: true,
            }),
        ]),
        masterchef: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/sepolia-farming",
                // shouldBatch: true,
            }),
        ]),
        exchange: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/sepolia-exchange",
                // shouldBatch: true,
            }),
        ]),
        blocklytics: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/sepolia-blocks",
                // shouldBatch: true,
            }),
        ]),
        lockup: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/sepolia-bar",
                // shouldBatch: true,
            }),
        ])
    },
    [ChainId.MATIC_TESTNET]: {
        bar: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/matic-test-bar",
                // shouldBatch: true,
            }),
        ]),
        masterchef: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/matic-test-farming",
                // shouldBatch: true,
            }),
        ]),
        exchange: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/matic-test-exchange",
                // shouldBatch: true,
            }),
        ]),
        blocklytics: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/matic-test-blocks",
                // shouldBatch: true,
            }),
        ]),
        lockup: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/matic-test-bar",
                // shouldBatch: true,
            }),
        ])
    },
    [ChainId.MAINNET]: {
        bar: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/matic-test-bar",
                // shouldBatch: true,
            }),
        ]),
        masterchef: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/matic-test-farming",
                // shouldBatch: true,
            }),
        ]),
        exchange: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/matic-test-exchange",
                // shouldBatch: true,
            }),
        ]),
        blocklytics: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/matic-test-blocks",
                // shouldBatch: true,
            }),
        ]),
        lockup: from([
            new RetryLink(),
            new HttpLink({
                uri: "https://api.thegraph.com/subgraphs/name/osinakayah/matic-test-bar",
                // shouldBatch: true,
            }),
        ])
    }
}
// export const bar = from([
//     new RetryLink(),
//     new HttpLink({
//         uri: "https://api.thegraph.com/subgraphs/name/osinakayah/sepolia-bar",
//         // shouldBatch: true,
//     }),
// ]);

// export const masterchef = from([
//     new RetryLink(),
//     new HttpLink({
//         uri: "https://api.thegraph.com/subgraphs/name/osinakayah/sepolia-farming",
//         // shouldBatch: true,
//     }),
// ]);
// export const exchange = from([
//     new RetryLink(),
//     new HttpLink({
//         uri: "https://api.thegraph.com/subgraphs/name/osinakayah/sepolia-exchange",
//         // shouldBatch: true,
//     }),
// ]);

// export const blocklytics = from([
//     new RetryLink(),
//     new HttpLink({
//         uri: "https://api.thegraph.com/subgraphs/name/osinakayah/sepolia-blocks"
//         // shouldBatch: true,
//     }),
// ]);
//
// export const lockup = from([
//     new RetryLink(),
//     new HttpLink({
//         uri: "https://api.thegraph.com/subgraphs/name/hodlarchitect/lockup",
//         // shouldBatch: true,
//     }),
// ]);

export const getLink = (chainId: ChainId = ChainId.SEPOLIA) => {
    const supportedNetworks = Object.keys(analyticsSubgraph)
    chainId = supportedNetworks.includes(chainId.toString()) ? chainId : ChainId.SEPOLIA
  return split(
      (operation) => {
          return operation.getContext().clientName === "blocklytics";
      },
      analyticsSubgraph[chainId].blocklytics,
      split(
          (operation) => {
              return operation.getContext().clientName === "masterchef";
          },
          analyticsSubgraph[chainId].masterchef,
          split(
              (operation) => {
                  return operation.getContext().clientName === "bar";
              },
              analyticsSubgraph[chainId].bar,
              split(
                  (operation) => {
                      return operation.getContext().clientName === "lockup";
                  },
                  analyticsSubgraph[chainId].lockup,
                  analyticsSubgraph[chainId].exchange
              )
          )
      )
  )
}

// export default split(
//     (operation) => {
//         return operation.getContext().clientName === "blocklytics";
//     },
//     blocklytics,
//     split(
//         (operation) => {
//             return operation.getContext().clientName === "masterchef";
//         },
//         masterchef,
//         split(
//             (operation) => {
//                 return operation.getContext().clientName === "bar";
//             },
//             bar,
//             split(
//                 (operation) => {
//                     return operation.getContext().clientName === "lockup";
//                 },
//                 lockup,
//                 exchange
//             )
//         )
//     )
// );
