import Item from './item'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'

const LanguageNetworkSwitcher: React.FC = () => {
	const { account, error } = useActiveWeb3React()

	return (
		<div className={`backdrop-filter-blur-50 h-68px rounded-34 bg-white bg-opacity-80 border-4 border-white flex items-center 
									xs:w-fit xs:h-fit xs:bg-transparent xs:backdrop-filter-none xs:border-0`}>
			{(!!account || error) &&
				<div
					className={'h-full px-19px border-r-4 border-white flex items-center justify-center pointer xs:border-r-0 xs:px-5px'}>
					<Item network/>
				</div>
			}
			<div className={`h-full flex items-center justify-center pointer xs:px-5px ${account ? 'px-20px' : 'px-8px'}`}>
				<Item language/>
			</div>
		</div>
	)
}

export default LanguageNetworkSwitcher
