/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Token } from '@hodlvalley/sdk'

import { Tooltip } from '../index'
import CurrencyLogo from '../CurrencyLogo'

import { useWindowSize } from 'hooks/useWindowSize'

import { responsiveSizes } from 'constants/sizes'

import styles from './tokenDropdown.module.scss'

interface TokenInRowProps {
	token: Token;
	wrapClassname?: string;
	textWrapClassname?: string;
}

const TokenInRow: React.FC<TokenInRowProps> = ({ token, wrapClassname = '', textWrapClassname = '' }) => {
	const windowSize = useWindowSize();

	return (
		<div className={`flex items-center ${wrapClassname}`} id={'token-wrapper-id'}>
			<CurrencyLogo currency={token} size={windowSize.width < responsiveSizes.xs ? 24 : 32}/>
			<div className={`flex flex-col pl-8px pt-2px w-full ${styles.tokenNameWrapper}`}>
				<span className={`uppercase text-sm xs:text-base text-gray-400 font-bold xs:font-medium truncate max-w-full xs:leading-4 ${textWrapClassname}`}
							id={`token-name-${token.name}-id`}>
							<Tooltip message={token.name}>
								{token.name}
							</Tooltip>
						</span>
				<p className={`uppercase text-15px xs:text-base text-gray-400 font-bold xs:font-medium pt-1px xs:pt-0 xs:-mt-2px xs:leading-4 ${textWrapClassname}`}
					 id={`token-symbol-${token.symbol}-id`}>
					{token.symbol}
				</p>
			</div>
		</div>
	)
}

export default TokenInRow
