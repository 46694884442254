import { Currency, CurrencyAmount, Token, STAKING_ADDRESS } from '@hodlvalley/sdk'

import { useCallback } from 'react'
import { useSushiBarContract } from '../hooks/useContract'
import { useTransactionAdder } from '../state/transactions/hooks'
import {showToast, ToastTypes} from "hooks/useToast";

const useSushiBar = () => {
  const addTransaction = useTransactionAdder()
  const barContract = useSushiBarContract()

  const enter = useCallback(
    async (amount: CurrencyAmount<Token> | undefined) => {
      if (amount?.quotient) {
        try {
          const tx = await barContract?.enter(amount?.quotient.toString())
          await addTransaction(tx, { summary: 'Staked MONEY' })
          return tx
        } catch (e) {
          showToast(e.message, {
            type: ToastTypes.error,
            timeout: 7 * 1000
          })
          return e
        }
      }
    },
    [addTransaction, barContract]
  )

  const leave = useCallback(
    async (amount: CurrencyAmount<Token> | undefined) => {
      if (amount?.quotient) {
        try {
          const tx = await barContract?.leave(amount?.quotient.toString())
          await addTransaction(tx, { summary: 'UnStaked MONEY' })
          return tx
        } catch (e) {
          showToast(e.message, {
            type: ToastTypes.error,
            timeout: 7 * 1000
          })
          return e
        }
      }
    },
    [addTransaction, barContract]
  )

  return { enter, leave }
}

export default useSushiBar
