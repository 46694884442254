import {useSession, signIn} from "next-auth/react";
import {ReactNode} from "react";
import {useRouter} from "next/router";

interface AuthWrapperProps {
    children: ReactNode;
}

const AuthWrapper: React.FC<AuthWrapperProps> = function ({children}) {
    const { data: session } = useSession()
    const router = useRouter()
    // return <>{children}</>
    if (session || process.env.NEXT_PUBLIC_IS_AUTH_ENABLE === 'false') {
        return <>{children}</>
    }
    // router.push('api/auth/signin')
    return <button onClick={() => signIn()}>Sign in</button>
}

export default AuthWrapper
