const BA_LIST = 'https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json'

// used to mark unsupported tokens, these are hosted lists of unsupported tokens
/**
 * @TODO add list from blockchain association
 */
export const UNSUPPORTED_LIST_URLS: string[] = [BA_LIST]

// const YEARN_LIST = 'https://yearn.science/static/tokenlist.json' // not available
const NFTX_LIST_V1 = 'https://nftx.ethereumdb.com/v1/tokenlist/'
const NFTX_LIST_V2 = 'https://nftx.ethereumdb.com/v2/tokenlist/'
const SYNTHETIX_LIST = 'synths.snx.eth'
// const OPYN_LIST = 'https://raw.githubusercontent.com/opynfinance/opyn-tokenlist/master/opyn-v1.tokenlist.json' // removed
const AAVE_LIST = 'tokenlist.aave.eth'
// const CMC_ALL_LIST = 'defi.cmc.eth'
const CMC_ALL_LIST = 'https://lksi0i6eel.execute-api.us-east-2.amazonaws.com/dev?uri=https://api.coinmarketcap.com/data-api/v3/uniswap/all.json' // updated
const CMC_STABLECOIN = 'stablecoin.cmc.eth' // removed
const COINGECKO_LIST = 'https://tokens.coingecko.com/uniswap/all.json'
const COMPOUND_LIST = 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'
const COIN_LIST = 'https://raw.githubusercontent.com/hodlverse/cryptoassets/master/coin.tokenlist.json'
const GEMINI_LIST = 'https://www.gemini.com/uniswap/manifest.json'
const KLEROS_LIST = 't2crtokens.eth'
export const ARBITRUM_LIST = 'https://bridge.arbitrum.io/token-list-42161.json' // new
export const OPTIMISM_LIST = 'https://static.optimism.io/optimism.tokenlist.json'
const UMA_LIST = 'https://umaproject.org/uma.tokenlist.json'
const ROLL_LIST = 'https://app.tryroll.com/tokens.json'
// const DHEDGE_LIST = 'https://list.dhedge.eth.link' // new // removed (not working any longer)
const SUSHI_LIST = 'https://token-list.sushi.com' // new
const CHAINLINK_LIST = 'https://token-list.sushi.com/chainlink' // new
const SET_LIST = 'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.tokenlist.json'
const WRAPPED_LIST = 'wrapped.tokensoft.eth'

// this is the default list of lists that are exposed to users
// lower index == higher priority for token import
const DEFAULT_LIST_OF_LISTS_TO_DISPLAY: string[] = [
  COIN_LIST,
  // SUSHI_LIST,
  // CHAINLINK_LIST,
  // COMPOUND_LIST,
  // AAVE_LIST,
  // CMC_ALL_LIST,
  // CMC_STABLECOIN, // removed in new version os sushi
  // COINGECKO_LIST,
  // UMA_LIST,
  // // YEARN_LIST, // not available
  // SYNTHETIX_LIST,
  // KLEROS_LIST,
  // GEMINI_LIST,
  // WRAPPED_LIST,
  // SET_LIST,
  // ROLL_LIST,
  // NFTX_LIST_V1,
  // NFTX_LIST_V2,
  // DHEDGE_LIST, // not available
]

export const DEFAULT_LIST_OF_LISTS: string[] = [
  ...DEFAULT_LIST_OF_LISTS_TO_DISPLAY,
  ...UNSUPPORTED_LIST_URLS, // need to load dynamic unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [
  COIN_LIST,
  // YEARN_LIST, // not available
  // GEMINI_LIST
]
