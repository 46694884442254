import React, { useCallback, useMemo, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { FixedSizeList } from 'react-window'
import { NATIVE } from '@hodlvalley/sdk'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

/* eslint-disable jsx-a11y/alt-text */
import { BackButton, Button, ButtonType, Modal, Tooltip } from 'components'
import NetworkStatus from './networkStatus'
import Transaction from 'components/AccountDetails/Transaction'

import { shortenAddress } from 'functions'
import { getExplorerLink } from 'functions/explorer'

import { useActiveWeb3React } from 'hooks'
import { showToast, ToastTypes } from 'hooks/useToast'
import useUSDCValue from 'hooks/useUSDCPrice'

import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import { useETHBalances } from 'state/wallet/hooks'

import { NETWORK_LABEL, NETWORK_NET, NETWORK_TYPE } from 'constants/networks'

import styles from './home.module.scss'

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
	return b.addedTime - a.addedTime
}

const HomeModal: React.FC = () => {
	const router = useRouter()
	const { i18n } = useLingui()

	const [showDetail, setShowDetail] = useState(false)
	const { chainId, account } = useActiveWeb3React()
	const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
	const userUSDBalance = useUSDCValue(NATIVE[chainId])

	const allTransactions = useAllTransactions()

	const sortedRecentTransactions = useMemo(() => {
		const txs = Object.values(allTransactions)
		return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
	}, [allTransactions])

	const pendingTransactions = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
	const confirmedTransactions = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

	const toggleDetails = useCallback(() => {
		setShowDetail(!showDetail)
	}, [showDetail])

	const copyAddress = useCallback(() => {
		showToast(`${i18n._(t`Address successfully copied`)}!`, {
			type: ToastTypes.success,
			autosize: true
		})
		navigator.clipboard.writeText(account)
	}, [account])

	const RenderRow = React.useCallback(
		({ index, style, data }) => (
			<div key={index} style={style}>
				<Transaction hash={data[index]}/>
			</div>
		),
		[]
	)

	return (
		<Modal isOpen={true}
					 title={{ firstLine: `${i18n._(t`my`)}`, secondLine: `${i18n._(t`home`)}` }}
					 description={`${i18n._(t`The property to view user wallet information when the wallet is connected to the HODLVERSE network.`)}`}
					 mainIconName={'UserHomeOne'}
					 customBackAction={showDetail ? toggleDetails : undefined}
					 addTopSpace>
			<div className={`h-full relative xs:h-500px`}>
				<div className={`${styles.body} absolute xs:relative ${showDetail ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
					<div className={`flex justify-between items-end w-87.5% xs:w-full xs:items-center xs:justify-start xs:px-24px`}>
						<p className='text-40px uppercase tracking-normal hidden xs:block xs:text-xl xs:text-gray-610 xs:mr-6px'>
							{NATIVE[chainId].symbol}
						</p>
						<p className='text-gray-400 text-opacity-60 font-medium text-40px uppercase tracking-normal xs:text-xl xs:text-gray-400'>
							{NETWORK_TYPE[chainId]}
						</p>
						{/* <p className='text-gray-400 text-opacity-60 font-medium text-xl mb-6px tracking-normal'>$0</p> */}
					</div>
					<div className={`flex justify-between -mt-14px w-87.5% xs:-mt-3px xs:px-24px`}>
						<p className='text-40px uppercase tracking-normal xs:hidden'>{NATIVE[chainId].symbol}</p>
						<Tooltip message={userUSDBalance ? `$${userUSDBalance.toSignificant(4, { groupSeparator: ',' })} USD` : ''}>
							<p className='text-40px tracking-normal xs:text-2xl xs:mt-5px'>{userEthBalance && (userEthBalance).toFixed(4)}</p>
						</Tooltip>
					</div>
					<hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 mt-19px mb-27px xs:h-4px xs:mt-21px xs:mb-28px'}/>
					<div className='flex justify-between items-center pl-2px pr-17px xs:flex-col xs:justify-start xs:items-start xs:px-24px'>
						<p className='text-base text-gray-300 tracking-normal xs:text-xs'>{i18n._(t`WALLET ADDRESS`)}</p>
						<div className='flex items-center xs:w-full xs:relative' onClick={copyAddress}>
							<a className='mr-27px text-gray-400 text-base tracking-normal mr-30px underline cursor-pointer'
								 id={'home-modal-wallet-address-id'}
								 href={getExplorerLink(chainId, account, 'address')} target={'_blank'} rel="noreferrer">
								{account && shortenAddress(account)}
							</a>
							<div className={'xs:absolute xs:-right-13px xs:-top-10px max-h-24px overflow-hidden'}>
								<Image src='/icons/copy.svg' width={24} height={24}/>
							</div>
						</div>
					</div>
					<hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 mt-27px mb-28px xs:h-4px xs:mt-24px xs:mb-24px'}/>
					<p className='text-gray-400 text-sm pl-2px xs:text-xs xs:px-24px xs:tracking-normal uppercase'>{i18n._(t`Recent transactions`)}</p>
					<div className='mt-20px xs:px-24px xs:mt-3px'>
						{(!pendingTransactions.length && !confirmedTransactions.length) ?
							<p className='text-gray-300 text-sm pl-2px xs:text-xs xs:tracking-normal uppercase'>
								{!account
									? 'PLEASE CONNECT YOUR WALLET TO VIEW YOUR TRANSACTIONS.'
									: `${i18n._(t`Swap a token to see your transaction here!`)} 😊`
								}
							</p>
							:
							<FixedSizeList
								outerElementType={(props) => <PerfectScrollbar {...props} />}
								height={100}
								itemData={pendingTransactions.concat(confirmedTransactions)}
								itemCount={pendingTransactions.length + confirmedTransactions.length}
								itemSize={30}
								width={'100%'}
							>
								{RenderRow}
							</FixedSizeList>
						}
					</div>
					<div className={`flex justify-between absolute w-full left-0 bottom-35px pr-40px pl-89px 
					xs:bottom-21px xs:flex-col xs:items-center xs:px-24px`}>
						<div className={'xs:order-2 xs:mt-18px flex items-center'}>
							<NetworkStatus networkName={NETWORK_LABEL[chainId]} network={NETWORK_NET[chainId]}/>
						</div>
						<div className={'xs:order-1 flex items-center'}>
							<Button type={account ? ButtonType.ACTIVE : ButtonType.ALERT}
											id={'home-modal-details-btn'}
											onClick={() => account ? toggleDetails() : router.push('/connect')}>
								{account ? `${i18n._(t`Details`)}` : `${i18n._(t`CONNECT WALLET`)}`}
							</Button>
						</div>
					</div>
				</div>

				<div className={`${styles.body} xs:absolute body-details ${showDetail ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
					<div className={'details-form'}>
						<p className='modal-description__title'>{i18n._(t`Name`)}</p>
						<p className='modal-description__value'>{i18n._(t`My Home`)}</p>
						<hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px xs:h-2px xs:mt-24px xs:mb-26px'}/>
						<p className='modal-description__title'>{i18n._(t`Description`)}</p>
						<p className={`modal-description__value min-h-118px xs:min-h-90px xs:text-sm xs:normal-case`}>
							{i18n._(t`The property to view user wallet information when the wallet is connected to the HODLVERSE network.`)}
						</p>
						<hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px xs:h-2px xs:mt-24px xs:mb-26px'}/>
						<p className='modal-description__title'>{i18n._(t`Available for purchase`)}</p>
						<p className='modal-description__value'>{i18n._(t`No`)}</p>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default HomeModal
