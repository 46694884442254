import styles from './gainerLoser.module.scss'
import {ReactNode, useEffect, useState} from "react";
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import {SearchInput, NetworkListDropdown} from "../../components";

interface LayoutProps {
  children: ReactNode;
  gainers?: boolean;
  onChangeKeyword?: (keyword: string) => void;
}

const GainerLoserLayout: React.FC<LayoutProps> = ({ children, gainers, onChangeKeyword }) => {
  const { i18n } = useLingui()

  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    onChangeKeyword && onChangeKeyword(search);
  }, [onChangeKeyword, search])

  return (
    <div className={`flex justify-center pt-80px xs:pt-0 xs:bg-white xs:bg-opacity-80`}>
      <div className={`w-1642px xs:w-full border-10 xs:border-0 border-white rounded-80 xs:rounded-none overflow-hidden`}>
        <div className={'flex items-start bg-white bg-opacity-80'}>
          <div className={'w-2/12 h-full h-10 xs:hidden'}>

          </div>
          <div className={'w-10/12 xs:w-full pl-20px pt-48px pb-55px xs:px-0 xs:py-0 xs:flex xs:flex-col'}>
            <div className={'flex justify-between items-top pr-77px xs:order-2 xs:px-18px'}>
              <div className={'flex justify-end items-center w-full'}>
                <div className={`w-294px`}>
                  {/*<SearchInput value={search} onChange={setSearch} />*/}
                </div>
                <div className={`ml-25px rounded-full w-294px ${styles.tokenDropdownWrapper}`}>
                  <SearchInput value={search} onChange={setSearch} />
                  {/*<NetworkListDropdown />*/}
                </div>
              </div>
            </div>
            <h2 className={'uppercase font-medium text-40px xs:text-xl text-gray-800 mt-59px xs:mt-25px mb-14px xs:mb-20px tracking-normal pl-7px xs:pl-36px leading-10 xs:order-1'}>
              {gainers ? `${i18n._(t`Gainers`)}` : `${i18n._(t`Losers`)}`}
            </h2>
            <div className={'pb-55px xs:pb-0 xs:pt-20px xs:order-3'}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GainerLoserLayout;
