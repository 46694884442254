import { AlertTriangle, ArrowUpCircle, CheckCircle } from 'react-feather'
import { ChainId, Currency, CurrencyAmount } from '@hodlvalley/sdk'
import React, { FC } from 'react'
import Lottie from 'lottie-react'

import {Button, CloseIcon, ExternalLink, Image, Modal, RowFixed, ModalHeader} from 'components'

import { getExplorerLink } from 'functions/explorer'

import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import useAddTokenToMetaMask from 'hooks/useAddTokenToMetaMask'

import loadingRollingCircle from '../../animation/loading-rolling-circle.json'

import HodlConfirmationModal from 'modals/Confirmation'

interface ConfirmationPendingContentProps {
  onDismiss: () => void
  pendingText: string
  pendingText2: string
}

export const ConfirmationPendingContent: FC<ConfirmationPendingContentProps> = ({
  onDismiss,
  pendingText,
  pendingText2,
}) => {
  return (
    <div style={{ color: "white" }}>
      <div className="flex justify-end">
        <CloseIcon onClick={onDismiss} />
      </div>
      <div className="w-24 pb-4 m-auto">
        <Lottie animationData={loadingRollingCircle} autoplay loop />
      </div>
      <div className="flex flex-col items-center justify-center gap-3">
        <div className="text-xl font-bold text-high-emphesis">Waiting for Confirmation</div>
        <div className="font-bold">{pendingText}</div>
        <div className="font-bold">{pendingText2}</div>
        <div className="text-sm font-bold text-secondary">Confirm this transaction in your wallet</div>
      </div>
    </div>
  )
}

interface TransactionSubmittedContentProps {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
  inline?: boolean // not in modal
}

export const TransactionSubmittedContent: FC<TransactionSubmittedContentProps> = ({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
}) => {
  const { library } = useActiveWeb3React()
  const { addToken, success } = useAddTokenToMetaMask(currencyToAdd)
  return (
    <div style={{ color: "white" }}>
      <div className="flex justify-end">
        <CloseIcon onClick={onDismiss} />
      </div>
      <div className="w-24 pb-4 m-auto">
        <ArrowUpCircle strokeWidth={0.5} size={90} className="text-blue" />
      </div>
      <div className="flex flex-col items-center justify-center gap-1">
        <div className="text-xl font-bold">Transaction Submitted</div>
        {chainId && hash && (
          <ExternalLink href={getExplorerLink(chainId, hash, 'transaction')}>
            <div className="font-bold text-blue">View on explorer</div>
          </ExternalLink>
        )}
        {currencyToAdd && library?.provider?.isMetaMask && (
          <Button onClick={addToken}>
            {!success ? (
              <RowFixed className="mx-auto space-x-2">
                <span>{`Add ${currencyToAdd.symbol} to MetaMask`}</span>
                <Image
                  src="/images/wallets/metamask.png"
                  alt={`Add ${currencyToAdd.symbol} to MetaMask`}
                  width={24}
                  height={24}
                  className="ml-1"
                />
              </RowFixed>
            ) : (
              <RowFixed>
                {`Added`} {currencyToAdd.symbol}
                {/* <CheckCircle className="ml-1.5 text-2xl text-green" size="16px" /> */}
              </RowFixed>
            )}
          </Button>
        )}
        {/* <Button onClick={onDismiss} style={{ margin: '20px 0 0 0' }}>
          Close
        </Button> */}
      </div>
    </div>
  )
}

interface ConfirmationModelContentProps {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}

export const ConfirmationModalContent: FC<ConfirmationModelContentProps> = ({
  title,
  bottomContent,
  onDismiss,
  topContent,
}) => {
  return (
    <div className="grid gap-4" style={{ color: "white" }}>
      <ModalHeader title={title} onClose={onDismiss} />
      {topContent()}
      {bottomContent()}
    </div>
  )
}

interface TransactionErrorContentProps {
  message: string
  onDismiss: () => void
}

export const TransactionErrorContent: FC<TransactionErrorContentProps> = ({ message, onDismiss }) => {
  return (
    <div className="grid gap-6">
      <div>
        <div className="flex justify-between">
          <div className="text-lg font-medium text-high-emphesis">Error</div>
          <CloseIcon onClick={onDismiss} />
        </div>
        <div className="flex flex-col items-center justify-center gap-3">
          <AlertTriangle className="text-red" style={{ strokeWidth: 1.5 }} size={64} />
          <div className="font-bold text-red">{message}</div>
        </div>
      </div>
      <div>
        <Button onClick={onDismiss}>
          Dismiss
        </Button>
      </div>
    </div>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content?: () => React.ReactNode
  attemptingTxn: boolean
  pendingText?: string
  pendingText2?: string
  currencyToAdd?: Currency | undefined

  token0Symbol?: string;
  token0Amount?: string | CurrencyAmount<Currency>;

  token1Symbol?: string;
  token1Amount?: string | CurrencyAmount<Currency>;
  type?: TransactionType
}

export enum TransactionType {
  SWAP = 'SWAP',
  POOL = 'POOL',
  ADD_STAKE = 'ADD_STAKE',
  ADD_POOL = 'ADD_POOL',
  REMOVE_STAKE = 'REMOVE_STAKE',
  REMOVE_POOL = 'REMOVE_POOL',
  FARM = 'FARM'
}

const TransactionConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  pendingText2,
  content,
  currencyToAdd,
  token0Symbol,
   token0Amount,
   token1Symbol,
   token1Amount,
    type
}) => {
  const { chainId } = useActiveWeb3React()

  if (!chainId) return null


  // confirmation screen
  return (
    <HodlConfirmationModal
      type={type}
      chainId={chainId}
      hash={hash}

      onDismiss={onDismiss}
      submitted={attemptingTxn ? false : !!hash}
      isOpen={isOpen}
      token0Symbol={token0Symbol}
      token1Symbol={token1Symbol}

      token0Amount={token0Amount}
      token1Amount={token1Amount}
    />
  )
  // confirmation screen
  // return (
  //     <>
  //       {attemptingTxn ? (
  //           <HodlConfirmationModal
  //               type={type}
  //               chainId={chainId}
  //               hash={hash}
  //
  //               onDismiss={onDismiss}
  //               submitted={false}
  //               isOpen={isOpen}
  //               token0Symbol={token0Symbol}
  //               token1Symbol={token1Symbol}
  //
  //               token0Amount={token0Amount}
  //               token1Amount={token1Amount}
  //           />
  //       ) : hash ? (
  //           <HodlConfirmationModal
  //             type={type}
  //             chainId={chainId}
  //             hash={hash}
  //
  //             onDismiss={onDismiss}
  //             submitted={true}
  //             isOpen={isOpen}
  //             token0Symbol={token0Symbol}
  //             token1Symbol={token1Symbol}
  //
  //             token0Amount={token0Amount}
  //             token1Amount={token1Amount} />
  //       ) : (
  //           content()
  //       )}
  //     </>
  // )
}

export default TransactionConfirmationModal
