import { useEffect } from 'react'

import { Confirmation, ConnectWalletRequest } from 'modals'

import useModals from 'state/modals/hooks'
import { ApplicationModal } from 'state/modals/actions'
import { useWallet } from 'state/wallet/hooks'

const Popups: React.FC = () => {
  const { isModalOpen, openModal } = useModals();
  const { alertNotConnectedWallet } = useWallet();

  useEffect(() => {
    if (alertNotConnectedWallet) {
      openModal(ApplicationModal.CONNECT_WALLET_REQUEST)
    }
  }, [alertNotConnectedWallet, openModal])

  return (
    <>
      <Confirmation isOpen={isModalOpen(ApplicationModal.CONFIRMATION)} />
      <ConnectWalletRequest isOpen={isModalOpen(ApplicationModal.CONNECT_WALLET_REQUEST)} connectWalletRoute={'/connect'} />
    </>
  )
}

export default Popups
