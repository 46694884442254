import { Currency, Percent, Trade as V2Trade, TradeType} from '@hodlvalley/sdk'
import React, {useCallback, useMemo} from 'react'
import {
  TransactionType,
} from 'modals/TransactionConfirmationModal'
import HodlConfirmationModal from 'modals/Confirmation'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'



export default function ConfirmSwapModal({
  trade,
  onDismiss,
  isOpen,
  attemptingTxn,
  txHash,
}: {
  isOpen: boolean
  trade: V2Trade<Currency, Currency, TradeType> | undefined
  originalTrade: V2Trade<Currency, Currency, TradeType> | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  allowedSlippage: Percent
  minerBribe?: string
  onAcceptChanges: () => void
  onConfirm: () => void
  swapErrorMessage: string | undefined
  onDismiss: () => void
}) {
  const { chainId } = useActiveWeb3React()
  return (
    <HodlConfirmationModal
      type={TransactionType.SWAP}
      chainId={chainId}
      hash={txHash}

      onDismiss={onDismiss}
      submitted={attemptingTxn ? false : !!txHash}
      isOpen={isOpen}
      token0Symbol={trade?.inputAmount?.currency?.symbol}
      token1Symbol={trade?.outputAmount?.currency?.symbol}

      // token0Amount={Number(trade?.inputAmount?.toFixed(6)).toString()}
      // token1Amount={Number(trade?.outputAmount?.toFixed(6)).toString()}

      token0Amount={trade?.inputAmount}
      token1Amount={trade?.outputAmount}
    />
  )
}
