import React, { useEffect, useState } from 'react'
import Image from 'next/image'

const CustomImage = (props: any) => {
	const { src, fallbackSrc, alt, ...rest } = props

	const [imgNonExists, setImgNonExists] = useState(false)
	const [oldSrc, setOldSrc] = useState(src)

	useEffect(() => {
		if (oldSrc !== src) {
			setImgNonExists(false)
			setOldSrc(src)
		}
	}, [oldSrc, src])

	return (
		<Image
			alt={alt || ''}
			{...rest}
			src={imgNonExists ? fallbackSrc : src}
			onErrorCapture={() => {
				setImgNonExists(true)
			}}
		/>
	)
}

export default CustomImage