import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { usePopper } from 'react-popper';


export interface TooltipProps {
  children: ReactNode;
  message: string | ReactNode;
  behaviour?: "absolute" | "fixed";
  customStyle?: React.CSSProperties;
}

const Tooltip: React.FC<TooltipProps> = ({
                                           children,
                                           message,
                                           customStyle = {}}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [visible, setVisibility] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement
        }
      },
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
          padding: 15
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      }
    ],
    placement: "top",
    // padding: "17px 15px 13px 15px"
  });

  const handleDocumentClick = useCallback((event) => {
    if (referenceElement?.current?.contains(event.target)) {
      return;
    }
    setVisibility(false);
  }, [referenceElement]);

  useEffect(() => {
    // listen for clicks and close dropdown on body
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [handleDocumentClick]);

  const popperAttributes = useMemo(() => ({
    ...attributes.popper
  }), [attributes])

  const popperStyles = useMemo(() => ({
    ...styles.popper,
    transition: "opacity 0.25s ease",
    opacity: visible ? 1 : 0,
    background: '#21314A',
    padding: "17px 15px 13px 15px",
    borderRadius: 10,
    color: "#BDC7D6",
    boxShadow: "none",
    ...customStyle
  }), [styles, visible, customStyle])

  const arrowStyles = useMemo(() => ({
    ...styles.arrow,
    width: 0,
    height: 0,
  }), [styles])

  return (
    <span ref={setReferenceElement} className={`font-medium text-sm text-gray-80 tracking-normal toast-popper z-10`}
         onMouseEnter={() => setVisibility(true)}
         onMouseLeave={() => setVisibility(false)}>
      {children}
      <span ref={setPopperElement} style={popperStyles} {...popperAttributes} className={"pointer-events-none z-20"}>
        {message}
        <i ref={setArrowElement} style={arrowStyles} className={'toast-popper__arrow'} />
      </span>
    </span>
  );
};

export default Tooltip;
