import { useCallback } from 'react'
import { CurrencyAmount, JSBI, Pair, Percent } from '@hodlvalley/sdk'

import PoolFarmItem from './template'

import { unwrappedToken } from 'functions'

import { getTokenIconFromName } from 'analytics/core/format'

import { useTokenBalance } from 'state/wallet/hooks'

import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import { useTotalSupply } from 'hooks/useTotalSupply'

interface PoolItemProps {
	item: Pair;
	onSelect?: () => void;
}

const PoolItem: React.FC<PoolItemProps> = ({ item, onSelect }) => {
	const handleSelect = useCallback(() => {
		onSelect && onSelect()
	}, [onSelect])

	const { account } = useActiveWeb3React()
	const pair = item

	const token0ImageUrl = getTokenIconFromName({ symbol: pair.token0.symbol })
	const token1ImageUrl = getTokenIconFromName({ symbol: pair.token1.symbol })

	const currency0 = unwrappedToken(pair.token0)
	const currency1 = unwrappedToken(pair.token1)

	const stakedBalance = CurrencyAmount.fromRawAmount(item.liquidityToken, '0')
	const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
	// if staked balance balance provided, add to standard liquidity amount
	const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance

	const totalPoolTokens = useTotalSupply(pair.liquidityToken)


	const poolTokenPercentage = !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.quotient, userPoolBalance.quotient)
			? new Percent(userPoolBalance.quotient, totalPoolTokens.quotient)
			: undefined

	return (
		<PoolFarmItem
			token0={{
				src: token0ImageUrl,
				alt: currency0.name,
				symbol: currency0.symbol.replace('WETH', 'ETH')
			}}
			token1={{
				src: token1ImageUrl,
				alt: currency1.name,
				symbol: currency1.symbol.replace('WETH', 'ETH')
			}}
			extra={poolTokenPercentage
				? (poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)) + '%'
				: '-'
			}
			onSelect={handleSelect}
		/>
	)
}

export default PoolItem
