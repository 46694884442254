import { useLingui } from '@lingui/react'

import { MenuItemModel } from './index'

interface ItemProps {
	subItems: MenuItemModel[];
	handleItemClick: (item: MenuItemModel, isSubitem: boolean) => void;
}

const Submenu: React.FC<ItemProps> = ({ subItems = [], handleItemClick }) => {
	const { i18n } = useLingui()

	return (
		<>
			{subItems.map((subitem, index) => (
				<div
					className={`rounded-18 mb-8px py-8px px-17px w-full bg-transparent transition last:mb-0 ${subitem?.link ? 'cursor-pointer hover:bg-white hover:bg-opacity-80' : 'cursor-not-allowed'}`}
					key={index}
					id={`submenu-item-element-wrapper-${subitem.label}-id`}
					onClick={() => handleItemClick(subitem, true)}>
					<p className={`text-gray-400 font-medium text-sm ${subitem?.link ? 'text-opacity-100' : 'text-opacity-50'}`}>
						{i18n._(`${subitem.label}`)}
					</p>
				</div>
			))}
		</>
	)
}

export default Submenu
