import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { setConnected, startMultiplayer, stopMultiplayer, setJoinedRoom, setUnityReady } from './actions'
import { MultiplayerStateType } from 'state/multiplayer/reducer'

const useMultiplayer = () => {
  const dispatch = useDispatch<AppDispatch>()
  const selector: MultiplayerStateType = useSelector((state: AppState) => state.multiplayer);

  const methods = useMemo(
    () => ({
      startMultiplayer: (): void => {
        dispatch(startMultiplayer())
      },
      stopMultiplayer: (): void => {
        dispatch(stopMultiplayer())
      },
      setConnected: (): void => {
        dispatch(setConnected())
      },
      setJoinedRoom: (joinedRoom: string): void => {
        dispatch(setJoinedRoom(joinedRoom))
      },
      setUnityReady: (isReady: boolean): void => {
        dispatch(setUnityReady(isReady))
      }
    }),
    [dispatch, selector]
  );

  return useMemo(
    () => ({
      ...methods,
      ...selector
    }),
    [methods]
  );
};

export default useMultiplayer;
