import React, { useCallback, useEffect, useMemo } from 'react'
import { useMoralis } from "react-moralis";
import { injected } from '../../connectors'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'

import { NATIVE } from '@hodlvalley/sdk'
import { t } from '@lingui/macro'
import useMultiplayer from 'state/multiplayer/hooks'
import Image from 'next/image'
import { NetworkContextName } from '../../constants'
import { TransactionDetails } from '../../state/transactions/reducer'
import Web3Connect from '../Web3Connect'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { useETHBalances } from '../../state/wallet/hooks'
import { useLingui } from '@lingui/react'
import { showToast, ToastTypes } from 'hooks/useToast'
import { useAddCustomNetwork } from 'hooks/useManageNetworks'
import { ChainId} from "@hodlvalley/sdk";

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
	return b.addedTime - a.addedTime
}

function Web3StatusInner() {
	const { account, chainId, deactivate, library, error } = useWeb3React()
	const { i18n } = useLingui()
	const { authenticate: moralisAuthenticate, isAuthenticated: moralisIsAuthenticated, logout: moralisLogout, user: moralisUser} = useMoralis();
	const { isUnityReady } = useMultiplayer()

	const { addCustomChain } = useAddCustomNetwork()

	const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']

	const allTransactions = useAllTransactions()

	const sortedRecentTransactions = useMemo(() => {
		const txs = Object.values(allTransactions)
		return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
	}, [allTransactions])

	const pending = sortedRecentTransactions
		.filter(tx => {
			return tx.receipt ? false : (tx.archer && tx.archer.deadline * 1000 - Date.now() < 0) ? false : true
		})
		.map((tx) => tx.hash)

	useEffect(() => {
		if (account && !moralisIsAuthenticated) {
			moralisAuthenticate({ signingMessage: "Hodlverse Authentication" });
		}

		if (!account && moralisIsAuthenticated) {
			moralisLogout();
			window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_LOGOUT', { detail: { } }) as Event)
		}
	}, [account, moralisIsAuthenticated, moralisAuthenticate, moralisLogout])

	useEffect(() => {
		if (account && moralisIsAuthenticated && moralisUser && isUnityReady) {
			window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_MORALIS_AUTHENTICATED', { detail: { account, moralisUser } }) as Event)
		}
	}, [account, moralisIsAuthenticated, moralisUser, isUnityReady])

	const handleLogout = useCallback((e) => {
		e.stopPropagation();
		e.preventDefault();
		localStorage.setItem('ACTIVE_WALLET', library?.provider?.isCoinWallet ? 'coin' : 'injected')
		deactivate()
	}, [deactivate])

	const handleNetworkItemClick = useCallback((networkChainId: ChainId)=>{
		addCustomChain(networkChainId);
	}, [addCustomChain]);

	useEffect(() => {
		if (error && error instanceof UnsupportedChainIdError) {
			showToast(<>Invalid Network Detected. Change to <u onClick={() => handleNetworkItemClick(ChainId.ROPSTEN)}>Ropsten</u> or <u onClick={() => handleNetworkItemClick(ChainId.BSC_TESTNET)}>BSC Testnet</u>.</>, {
				type: ToastTypes.error,
				timeout: 5000
			})
		}
	}, [error, handleNetworkItemClick])

	return account || error ? (
		<>
			<div
				id="web3-status-connected"
				className="flex items-center pr-3 py-2 text-sm rounded-lg bg-dark-1000 text-secondary xs:w-full xs:pt-14px"
			>
				<div className={`flex xs:flex-col`}>
					<p className={`text-white font-bold opacity-80 text-sm mr-15px xs:text-xl`}>{i18n._(t`MY WALLET`)}</p>
					{!error &&
						<p className={'text-white font-bold text-sm xs:text-xl xs:mt-6px'} id={'menu-wallet-balance-id'}>
							{userEthBalance?.toSignificant(4)} {NATIVE[chainId].symbol}
						</p>
					}
				</div>
			</div>
			<div className={'border-l-4 border-red-500 h-full flex cursor-pointer pl-11px -mr-10px xs:border-none'}
					 id={'menu-logout-wrapper-id'}>
				<Image onClick={handleLogout} src="/icons/logout.svg" alt="Logout" width="19" height="18"/>
			</div>
		</>
	) : (
		<Web3Connect/>
	)
}

export default function Web3Status() {
	const { active } = useWeb3React()
	const contextNetwork = useWeb3React(NetworkContextName)

	if (!contextNetwork.active && !active) {
		return null
	}

	return !contextNetwork.active && !active ? null : <Web3StatusInner/>
}
