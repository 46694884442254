import React, { useCallback, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { BackButton, Modal, ModalMenuItem } from 'components'
import { MenuItemTypeModel } from 'components/Menu'

import useModals from 'state/modals/hooks'

import { Sizes } from 'types/Sizes'
import { LinkItemModal } from 'types/LinkItemModal'

import styles from './stakeHome.module.scss'

const StakeHomeModal: React.FC = () => {
	const router = useRouter()
	const { closeModals, openModal } = useModals()
  const { i18n } = useLingui()

	const [showDetail, setShowDetail] = useState(false)

  const list: LinkItemModal[] = useMemo(() =>[
    { label: `${i18n._(t`Add stake`)}`, type: MenuItemTypeModel.LINK, link: '/stake/add' },
    { label: `${i18n._(t`Remove stake`)}`, type: MenuItemTypeModel.LINK, link: '/stake/remove' },
    { label: `${i18n._(t`View staking stats`)}`, type: MenuItemTypeModel.LINK },
    { label: `${i18n._(t`Property details`)}`, type: MenuItemTypeModel.LOCAL_EVENT, link: 'PROPERTY_DETAILS' }
  ], [])

	const toggleDetails = useCallback(() => {
		setShowDetail(!showDetail)
	}, [showDetail])

	const selectModal = useCallback((item: LinkItemModal): void => {
		if (item.link) {
			if ([MenuItemTypeModel.MODAL, MenuItemTypeModel.LINK].includes(item.type)) {
				closeModals()
			}
			if (item.type === MenuItemTypeModel.LINK) {
				router.push(`${item.link}`)
			}
			if (item.type === MenuItemTypeModel.LOCAL_EVENT && item.link === 'PROPERTY_DETAILS') {
				toggleDetails()
			}
		}
	}, [closeModals, openModal, toggleDetails])

	return (
		<Modal isOpen={true}
					 title={{ firstLine: `${i18n._(t`Community`)}`, secondLine: `${i18n._(t`Mine`)}` }}
					 description={`${i18n._(t`The property for the staking of the MONEY token in the HODLVERSE network`)}.`}
					 size={Sizes.SMALL}
					 mainIconName={'Mine'}
					 addTopSpace={showDetail}
					 addMobileTopSpace
					 customBackAction={showDetail ? toggleDetails : undefined}>
			<div className={`h-full relative xs:h-500px`}>
				<div className={`${styles.body} ${showDetail ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
					<div className={`h-full flex flex-col justify-center xs:justify-start`}>
						{list.map((item, index) => (
							<div className={'mb-20px xs:mb-15px'} key={index}>
								<ModalMenuItem label={item.label} onSelect={() => selectModal(item)}/>
							</div>
						))}
					</div>
				</div>
				<div className={`${styles.body} body-details ${showDetail ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
          <div className={'details-form'}>
            <p className='modal-description__title'>{i18n._(t`Name`)}</p>
            <p className='modal-description__value'>{i18n._(t`Community mine`)}</p>
            <hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px xs:h-2px xs:mt-24px xs:mb-26px'}/>
            <p className='modal-description__title'>{i18n._(t`Description`)}</p>
            <p className={`modal-description__value min-h-118px xs:min-h-90px xs:text-sm xs:normal-case`}>
              {i18n._(t`The property for the staking of the MONEY token in the HODLVERSE network`)}.
            </p>
            <hr className={'w-full h-3px bg-gray-600 rounded bg-opacity-20 border-0 my-27px xs:h-2px xs:mt-24px xs:mb-26px'}/>
            <p className='modal-description__title'>{i18n._(t`Available for purchase`)}</p>
            <p className='modal-description__value'>{i18n._(t`No`)}</p>
          </div>
				</div>
			</div>
		</Modal>
	)
}

export default StakeHomeModal
