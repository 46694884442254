import { createReducer } from '@reduxjs/toolkit'
import {
  startMultiplayer,
  stopMultiplayer,
  setConnected,
  setJoinedRoom, setUnityReady
} from './actions'

export interface MultiplayerStateType {
  readonly isActive: boolean
  readonly isConnecting: boolean
  readonly isJoinedRoom: boolean
  readonly joinedRoomName: string
  readonly isUnityReady: boolean
}

const initialState: MultiplayerStateType = {
  isActive: false,
  isConnecting: false,
  isJoinedRoom: false,
  joinedRoomName: "",
  isUnityReady: false,
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(startMultiplayer, (state, action) => {
      state.isConnecting = true;
    })
    .addCase(setConnected, (state, action) => {
      state.isActive = true;
      state.isConnecting = false;
    })
    .addCase(stopMultiplayer, (state, action) => {
      state.isActive = false;
    })
    .addCase(setJoinedRoom, (state, action) => {
      state.isJoinedRoom = !!action.payload;
      state.joinedRoomName = action.payload;
    })
    .addCase(setUnityReady, (state, action) => {
      state.isUnityReady = !!action.payload;
    })
)
