import React, { useCallback, useState } from 'react'
import { getAddress } from '@ethersproject/address'
import {ChainId, CurrencyAmount, Token} from '@hodlvalley/sdk'
import { useSelector } from 'react-redux'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { Button, Dots, Modal } from 'components'
import Input from './Input'

import { AppState } from 'state'
import { useTokenBalance } from 'state/wallet/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'

import { formatCurrency } from 'analytics/core/format'

import { formatNumber, formatPercent, limitNumber, tryParseAmount } from 'functions'

import { ApprovalState, useActiveWeb3React, useApproveCallback } from 'hooks'
import { showToast, ToastTypes } from 'hooks/useToast'

import { Chef, PairType } from 'features/farm/enum'
import { useUserInfo } from 'features/farm/hooks'
import useMasterChef, {useFarmByAddress} from 'features/farm/useMasterChef'

import TransactionConfirmationModal, { TransactionType } from 'modals/TransactionConfirmationModal'

import styles from './farmDetails.module.scss'
import { useWindowSize } from 'hooks/useWindowSize'
import { Sizes } from 'types/Sizes'
import { responsiveSizes } from 'constants/sizes'

const FarmDetailsModal: React.FC = () => {
	const { account, chainId } = useActiveWeb3React()
	const { width: screenWidth } = useWindowSize()
	const [pendingTx, setPendingTx] = useState(false)
	const { i18n } = useLingui()
	const [balanceAmount, setBalanceAmount] = useState('')
	const [farmingAmount, setFarmingAmount] = useState('')
	const [hash, setHash] = useState<string>('')
	const [showFormDetailsModal, setShowFormDetailsModal] = useState(false)

	const { farmData } = useSelector((state: AppState) => state.farm)


	const getFarmConfig = useCallback(() => {
		return {
			title: `${i18n._(t`Farm details`)}`,
			infoColumns: [
				{ title: `${i18n._(t`Harvesting`)}`, value: farmData.balance },
				{ title: `${i18n._(t`Base`)}`, value: farmData.pair?.token0?.symbol },
				{ title: `${i18n._(t`Quote`)}`, value: farmData.pair?.token1?.symbol }
			]
		}
	}, [farmData.pair?.token0?.symbol, farmData.pair?.token1?.symbol, farmData.balance])

	const addTransaction = useTransactionAdder()

	const liquidityToken = new Token(
		chainId,
		getAddress(farmData.pair.id),
		farmData.pair.type === PairType.KASHI ? Number(farmData.pair.asset.decimals) : 18,
		farmData.pair.type === PairType.KASHI ? 'KMP' : 'HVLP'
	)

	const typedDepositValue = tryParseAmount(balanceAmount, liquidityToken)
	const typedWithdrawValue = tryParseAmount(farmingAmount, liquidityToken)

	// const pendingSushi = usePendingMoney(farmData)
	// const reward = usePendingReward(farmData)


	const APPROVAL_ADDRESSES = {
		[Chef.MASTERCHEF]: {
			[ChainId.MAINNET]: farmData.id,
			[ChainId.ROPSTEN]: farmData.id,
			[ChainId.BSC_TESTNET]: farmData.id,
		}
	}


	const balance = useTokenBalance(account, liquidityToken)
	const amount = useUserInfo(farmData, liquidityToken)

	const [approvalState, approve] = useApproveCallback(typedDepositValue, APPROVAL_ADDRESSES[farmData.chef][chainId])
	const { deposit, withdraw } = useFarmByAddress(farmData.id)

	const handleDismissConfirmation = useCallback(() => {
		setPendingTx(false)
	}, [])

	const handleFarm = useCallback(async () => {
		setPendingTx(true)

		try {
			const tx = await deposit(balanceAmount.toBigNumber(liquidityToken?.decimals))

			addTransaction(tx, {
				summary: `Deposit ${farmData.pair?.token0?.symbol}/${farmData.pair?.token1?.symbol}`
			})
			setHash(tx.hash)
		} catch (error) {
			showToast(error.message, {
				type: ToastTypes.error,
				timeout: 7 * 1000
			})
			console.error(error)
		}

		setPendingTx(false)
	}, [farmData, balanceAmount, liquidityToken])

	return (
		<>
			<TransactionConfirmationModal
				type={TransactionType.FARM}
				isOpen={pendingTx}
				onDismiss={handleDismissConfirmation}
				attemptingTxn={pendingTx}
				hash={hash}
				pendingText={'Farming'}
			/>
			<Modal isOpen={!pendingTx}
						 title={{ firstLine: `${i18n._(t`Farm`)}`, secondLine: `${i18n._(t`Details`)}` }}
						 description={`${i18n._(t`The property for the farming of the HVLP token in the HODLVERSE network`)}.`}
						 formDetailsConfig={getFarmConfig()}
						 mainIconName={'FarmOne'}
						 showFormDetailsModal={showFormDetailsModal}
						 onManualChangeVisFormDetails={setShowFormDetailsModal}>
				<div className={`h-full relative xs:h-500px`}>
					<div className={`h-full flex flex-col`}>
						<div
							className={styles.assetForm}>
							{farmData &&
							<div className={'flex'}>
								<div className={`${styles.leftColumn} pr-10px`}>
									<div className={styles.infoRow}>
										<p className={styles.title}>{i18n._(t`TVL`)}</p>
										<p className={styles.value}>{formatCurrency(farmData.tvl)}</p>
									</div>
									<div className={styles.infoRow}>
										<p className={styles.title}>{i18n._(t`Rewards`)}</p>
										<p
											className={styles.value}>{farmData.rewards.map((r) => <>{formatCurrency(r.poolRewardPerMonth * r.rewardPrice)} {r.token}</>)}</p>
									</div>
									<div className={styles.infoRow}>
										<p className={styles.title}>{i18n._(t`Staked`)}</p>
										<p className={styles.value}>{formatNumber(farmData.balance ?? 0)} HVLP</p>
									</div>
									<div className={'xs:hidden'}>
										<div className={styles.infoRow}>
											<p className={styles.title}>{i18n._(t`Apr`)}</p>
											<p className={styles.value}>{formatPercent(farmData?.roiPerYear * 100)}</p>
										</div>
									</div>
								</div>
								<div className={styles.rightColumn}>
									<div className={'hidden xs:block'}>
										<div className={styles.infoRow}>
											<p className={styles.title}>{i18n._(t`Apr`)}</p>
											<p className={styles.value}>{formatPercent(farmData?.roiPerYear * 100)}</p>
										</div>
									</div>
									<div className={styles.infoRow}>
										<p className={styles.title}>{i18n._(t`Farm`)}</p>
										<div className={'hidden xs:block'}>
											<p className={styles.value}>{farmData.pair?.token0?.symbol} / {farmData.pair?.token1?.symbol}</p>
										</div>
									</div>
									<div className={'xs:hidden'}>
										<div className={styles.infoRow}>
											<p className={styles.value}>{farmData.pair?.token0?.symbol} / {farmData.pair?.token1?.symbol}</p>
										</div>
									</div>
									<div className={styles.infoRow}>
										<p className={styles.title}>{i18n._(t`Harvesting`)}</p>
										<div className={'hidden xs:block'}>
											<p
												className={`font-medium text-xl xs:text-xs uppercase tracking-normal ${parseFloat(amount?.toSignificant(6)) > 0 ? 'text-green-400' : 'text-red-400'}`}>
												{parseFloat(amount?.toSignificant(6)) > 0 ? 'YES' : 'NO'}
											</p>
										</div>
									</div>
									<div className={'xs:hidden'}>
										<div className={styles.infoRow}>
											<p
												className={`font-medium text-xl uppercase tracking-normal ${parseFloat(amount?.toSignificant(6)) > 0 ? 'text-green-400' : 'text-red-400'}`}>
												{parseFloat(amount?.toSignificant(6)) > 0 ? 'YES' : 'NO'}
											</p>
										</div>
									</div>
								</div>
							</div>
							}
						</div>
						<div className={`w-full relative z-10 pt-38px pb-20px pl-65px pr-40px xs:py-20px xs:px-12px`}>
							{farmData &&
							<>
								<div className={'flex xs:flex-col justify-between xs:justify-start items-start xs:items-center'}>
									<div className={'xs:mb-20px w-fit xs:w-full'}>
										<Input
											placeholder={'0'}
											title={`${i18n._(t`Balance`)}`}
											subtitle={`${formatNumber(balance?.toSignificant(6) ?? 0)} ${liquidityToken.symbol}`}
											value={balanceAmount}
											onChange={(val) => setBalanceAmount(limitNumber(val, 9, 8))}/>
									</div>
									<Input
										placeholder={'0'}
										title={`${i18n._(t`Farming`)}`}
										subtitle={`${formatNumber(amount?.toSignificant(6)) ?? 0} ${liquidityToken.symbol}`}
										value={farmingAmount}
										onChange={(val) => setFarmingAmount(limitNumber(val, 9, 8))}/>
								</div>
								<div className={'flex justify-between items-center mt-35px xs:mt-20px xs:justify-around'}>
									{approvalState === ApprovalState.NOT_APPROVED || approvalState === ApprovalState.PENDING ? (
										<Button disabled={approvalState === ApprovalState.PENDING} onClick={approve}
														id={`farm-details-approve-btn-id`}
														size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.MEDIUM}>
											{approvalState === ApprovalState.PENDING ? <Dots>Approving </Dots> : 'Approve'}
										</Button>
									) : (<Button
										size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.MEDIUM}
										disabled={pendingTx || !typedDepositValue || balance?.lessThan(typedDepositValue)}
										onClick={handleFarm} id={`farm-details-farm-btn-id`}>
										<p className={'tracking-normal text-white-750'}>{i18n._(t`Farm`)}</p>
									</Button>)}

									<Button
										size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.MEDIUM}
										id={`farm-details-main-cta-btn-id`}
										disabled={pendingTx || !typedWithdrawValue || amount.lessThan(typedWithdrawValue)}
										onClick={async () => {
											setPendingTx(true)
											try {
												const tx = await withdraw(farmingAmount.toBigNumber(liquidityToken?.decimals))
												addTransaction(tx, {
													summary: `Withdraw ${farmData.pair?.token0?.symbol}/${farmData.pair?.token1?.symbol}`
												})
												setHash(tx.hash)
											} catch (error) {
												showToast(error.message, {
													type: ToastTypes.error,
													timeout: 7 * 1000
												})
												console.error(error)
											}

											setPendingTx(false)
										}}
									>
										<p className={'tracking-normal text-white-750'}>{i18n._(t`Unfarm`)}</p>
									</Button>
								</div>
								<div className={'hidden xs:flex items-center justify-center mt-17px'}>
									<p className={'uppercase text-sm font-bold text-gray-400 mr-8px'}
										 id={`farm-details-btn-details-mobile-id`}
										 onClick={() => setShowFormDetailsModal(!showFormDetailsModal)}>
										Details
									</p>
								</div>
							</>
							}
						</div>
					</div>
				</div>
			</Modal>
		</>

	)
}

export default FarmDetailsModal
