import Modal from "../components/ModalWallet";
import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import Button from "../components/Button";
import CloseIcon from "../components/CloseIcon";
import {CrossChainState} from "state/crosschainswap/reducer";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../state";
import {get} from 'lodash'
import {ConfirmationPendingContent, TransactionType} from "../modals/TransactionConfirmationModal";
import {Field, typeInput} from "state/swap/actions";
import HodlConfirmationModal from "modals/Confirmation";
import {useActiveWeb3React} from "hooks/useActiveWeb3React";

interface ConfirmCrossChainSwapModalProps {
    isOpen: boolean;
    onDismiss: ()=> any;
    swapDetails: {
        fromAmount: string;
        toAmount: string;

        fromAsset: string;
        toAsset: string
    };
}

export const safelyParseJSON = (json) => {
    let parsed

    try {
        parsed = JSON.parse(json)
    } catch (e) {
        // console.log('ERROR: ', e, json)
        // Oh well, but whatever...
    }

    return parsed
}


export const ConfirmCrossChainSwapModal: FC<ConfirmCrossChainSwapModalProps> = function (props) {
    const { chainId } = useActiveWeb3React()

    const dispatch = useDispatch();
    const [isPending, setIsPending] = useState(()=>false);
    const crossChainState: CrossChainState = useSelector<AppState, CrossChainState>((state) => state.crosschainswap)
    const {isOpen, onDismiss, swapDetails} = props;


    const onConfirmCrossChainSwap =  useCallback(()=>{
        return new Promise((resolve, reject) => {
            const market = crossChainState.markets.find((m)=>m.from.toUpperCase() ===swapDetails.fromAsset.toUpperCase() && m.to.toUpperCase() === swapDetails.toAsset.toUpperCase());
            if (market) {
                const eventID = Date.now() + '.' + Math.random()
                window.postMessage({
                    id: eventID,
                    type: 'HODL_CROSS_CHAIN_SWAP',
                    data: {
                        id: eventID,
                        from: swapDetails.fromAsset,
                        to: swapDetails.toAsset,
                        amount: swapDetails.fromAmount,
                        toAmount: swapDetails.toAmount,
                    }
                }, '*')
                window.addEventListener(eventID, (event: any) => {
                    const detail = safelyParseJSON(event.detail)
                    const result = get(detail, 'result', null)
                    const error = get(detail, 'error', null)

                    console.log(result)
                    console.log(error)
                    if (error) {
                        return reject(error)
                    } else {
                        return resolve(result)
                    }
                }, false)
            } else {
                console.log('Market not available')
                return reject('Market not available')
            }

        })
    }, [crossChainState, swapDetails])
    const handleSwap = useCallback(async ()=>{
        setIsPending(true)
        await onConfirmCrossChainSwap()
        setIsPending(false)
        dispatch(typeInput({ field: Field.INPUT, typedValue: '0' }))
        onDismiss()
    }, [onConfirmCrossChainSwap, onDismiss])

    useEffect(()=>{
        if (isOpen && parseFloat(swapDetails.fromAmount) > 0) {
            // handleSwap()
        }
    }, [isOpen, handleSwap])
    const token0Amount = useMemo(()=>{
        return String(swapDetails.fromAmount)
    }, [swapDetails?.fromAmount]);

    const token1Amount = useMemo(()=>{
        return String(swapDetails.toAmount)
    }, [swapDetails?.toAmount]);
    return  (
        <HodlConfirmationModal
            type={TransactionType.SWAP}
            chainId={chainId}
            hash={undefined}

            onDismiss={onDismiss}
            submitted={false}
            isOpen={isOpen}
            token0Symbol={swapDetails.fromAsset}
            token1Symbol={swapDetails.toAsset}

            token0Amount={token0Amount}
            token1Amount={token1Amount}
        />
    )
}


