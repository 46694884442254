import { createAction } from '@reduxjs/toolkit'

export enum Field {
    INPUT = 'INPUT',
    OUTPUT = 'OUTPUT',
}

export interface Market {
    agent: string,
    createdAt: string,
    from: string,
    max: string,
    min: string,
    minConf: number,
    orderExpiresIn: number
    originalRate: number
    rate: number
    status: string
    to: string
    updatedAt: string
}



export const setMarkets = createAction<{
    markets: Market[]
}>('crosschainswap/setMarkets')

export const setMarket = createAction<{
    market: Market
}>('crosschainswap/setMarket')


export const setQuote = createAction<{
    quote: any
}>('crosschainswap/setQuote')
