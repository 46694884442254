import { useEffect, useRef } from 'react'
import Image from 'next/image'

import { useWindowSize } from 'hooks/useWindowSize'

import { responsiveSizes } from 'constants/sizes'

import styles from './notification.module.scss'

export enum NotificationType {
	NORMAL = 'NORMAL',
	SUCCESS = 'SUCCESS',
	WARNING = 'WARNING'
}

export type NotificationModel = {
	id?: string,
	text: string,
	linkText: {
		text: string,
		link: string
	},
	type: NotificationType;
}

interface Props {
	item: NotificationModel;
	showTime?: number;
}

const Notification: React.FC<Props> = ({ item, showTime }) => {
	const progressLineRef = useRef<HTMLDivElement>()
	const { width: screenWidth } = useWindowSize()

	useEffect(() => {
		if (progressLineRef.current) {
			progressLineRef.current.style.width = '100%';

			setTimeout(() => {
				progressLineRef.current.style.width = '0'
			}, 10)
		}
	}, [])

	return (
		<div
			className={`${styles.wrapper} backdrop-filter-blur-50 border-4 flex xs:flex-col items-center
		${item.type === NotificationType.SUCCESS
				? 'bg-green-400 border-green-500'
				: item.type === NotificationType.WARNING
					? 'bg-red-400 border-red-300'
					: 'bg-transparent border-white border-opacity-40'}`}>
			<div className={`flex justify-center items-center ${styles.notificationIcon}`}>
				{(item.type === NotificationType.SUCCESS || item.type === NotificationType.NORMAL) &&
				<Image src={'/icons/iconSuccess.svg'} alt="Success"
							 width={screenWidth < responsiveSizes.xs ? 40 : 49}
							 height={screenWidth < responsiveSizes.xs ? 40 : 49}/>
				}
				{item.type === NotificationType.WARNING &&
				<Image src={'/icons/iconWarning.svg'} alt="Warning"
							 width={screenWidth < responsiveSizes.xs ? 36 : 49}
							 height={screenWidth < responsiveSizes.xs ? 36 : 49}/>
				}
			</div>
			<hr className={`h-full bg-white bg-opacity-20 border-0 w-5px my-0 ml-16px mr-30px`}/>
			<div className={`flex flex-col space-between w-1/2 xs:w-full pb-3px`}>
				<p className={`font-bold text-sm text-white uppercase w-full line-clamp-2 order-1 
				xs:text-center xs:overflow-visible xs:whitespace-normal xs:mt-14px xs:leading-4 ${styles.title}`}>
					{item.text}
				</p>
				<p className={`font-bold text-sm text-white xs:text-center text-opacity-70 cursor-pointer w-full 
				truncate mt-3px xs:mt-10px order-1 xs:order-3`}
					 onClick={() => item?.linkText?.link && window.open(item.linkText.link, '_blank')}>
					{item.linkText.text}
				</p>
				{showTime &&
				<div className={`relative w-full bg-white bg-opacity-20 rounded h-5px mt-13px order-3 xs:order-2`}>
					<div className={`absolute top-0 left-0 bg-white rounded w-full h-full ease-linear ${styles.progressLineAnimation}`}
							 ref={progressLineRef} style={{ transitionDuration: `${showTime}ms` }}/>
				</div>
				}
			</div>
			<div className={`flex justify-center items-center cursor-pointer ml-26px`}/>
		</div>
	)
}

export default Notification
