import {useCallback} from "react";
import {get} from "lodash";
import {safelyParseJSON} from "../crosschainswap/ConfirmCrossChainSwapModal";
import {CrossChainState} from "state/crosschainswap/reducer";
import {useSelector} from "react-redux";
import {AppState} from "state/index";
import {useTransactionAdder} from "state/transactions/hooks";

export function useCrossChainSwapCallback() {
    const addTransaction = useTransactionAdder()
    const crossChainState: CrossChainState = useSelector<AppState, CrossChainState>((state) => state.crosschainswap)
    const isAmountValid = useCallback((market, swapDetails)=>{
        return market && (Number(swapDetails.fromAmount) >= Number(market.min) && Number(swapDetails.fromAmount) <= Number(market.max));
    }, [])


    const onConfirmCrossChainSwap =  useCallback((swapDetails)=>{
        return new Promise((resolve, reject) => {
            const market = crossChainState.markets.find((m)=>m.from.toUpperCase() ===swapDetails.fromAsset.toUpperCase() && m.to.toUpperCase() === swapDetails.toAsset.toUpperCase());
            if (market) {
                if (!isAmountValid(market, swapDetails)) {
                    return reject(`Oops, The minimum amount to swap is ${market.min} ${swapDetails.fromAsset} and maximum amount to swap is ${market.max} ${swapDetails.fromAsset} `)
                }
                const eventID = Date.now() + '.' + Math.random()
                window.postMessage({
                    id: eventID,
                    type: 'HODL_CROSS_CHAIN_SWAP',
                    data: {
                        id: eventID,
                        from: swapDetails.fromAsset,
                        to: swapDetails.toAsset,
                        amount: swapDetails.fromAmount,
                        toAmount: swapDetails.toAmount,
                    }
                }, '*')
                window.addEventListener(eventID, (event: any) => {
                    const detail = safelyParseJSON(event.detail)
                    const result = get(detail, 'result', null)
                    const error = get(detail, 'error', null)

                    if (error) {
                        return reject(error)
                    } else {

                        const tx = {
                            ...result,
                            hash: result.orderId
                        }
                        const fromAmount = Number(swapDetails.fromAmount).toFixed(6)
                        const toAmount = Number(swapDetails.toAmount).toFixed(6)
                        addTransaction(tx, { summary: `Swapping ${fromAmount} ${swapDetails.fromAsset} for ${toAmount} ${swapDetails.toAsset}`  })
                        return resolve(result)
                    }
                }, false)
            } else {
                console.log('Market not available')
                return reject('Market not available')
            }

        })
    }, [crossChainState, isAmountValid])
    return {
        callback: onConfirmCrossChainSwap
    }
}
