import React, { useCallback, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { FixedSizeList } from 'react-window'

import { Modal, ModalMenuItem, ModalMenuInputItem, Button, ButtonType } from 'components'

import { Sizes } from 'types/Sizes'

import styles from './createViewRoom.module.scss'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useWindowSize } from 'hooks/useWindowSize'
import { responsiveSizes } from 'constants/sizes'
import { safelyParseJSON } from '../../crosschainswap/ConfirmCrossChainSwapModal'
import useMultiplayer from 'state/multiplayer/hooks'

export interface RoomModel {
	id?: string;
	RoomName: string;
	PlayerCount: number;
}

// const hardocdedRoomList: RoomModel[] = [
//     { id: '1', title: 'Jimmy\'s Room', currentCount: 3, total: 20 },
//     { id: '2', title: 'Empire', currentCount: 11, total: 20 },
//     { id: '3', title: 'Battlefield', currentCount: 14, total: 20 },
//     { id: '4', title: 'The Jungle', currentCount: 4, total: 20 }
// ]

interface CreateViewRoomProps {
	isCreate?: boolean;
	isView?: boolean;
	airportPageLink: string;
	createRoomPageLink?: string;
}

const CreateViewRoom: React.FC<CreateViewRoomProps> = ({
																												 isCreate = false,
																												 isView = false,
																												 airportPageLink = '',
																												 createRoomPageLink = ''
																											 }) => {
	const router = useRouter()
	const { width: screenWidth } = useWindowSize()
	const { isJoinedRoom } = useMultiplayer()

	const [roomName, setRoomName] = useState('')
	const [roomList, setRoomList] = useState<RoomModel[]>([
		// {RoomName:"fgjh", PlayerCount: 1 },
		// {RoomName:"fgjh2", PlayerCount: 1 },
		// {RoomName:"fgjh3", PlayerCount: 1 },
		// {RoomName:"fgjh4", PlayerCount: 1 },
		// {RoomName:"fgjh5", PlayerCount: 1 },
		// {RoomName:"fgjh6", PlayerCount: 1 },
		// {RoomName:"fgjh7", PlayerCount: 1 },
		// {RoomName:"fgjh8", PlayerCount: 1 },
		// {RoomName:"fgjh9", PlayerCount: 1 },
		// {RoomName:"fgjh10", PlayerCount: 1 },
	])
	const [creating, setCreating] = useState(false)
	const [selectedRoom, setSelectedRoom] = useState<string>();

	const selectRoom = useCallback((roomName: string) => {
		setSelectedRoom(room => room === roomName ? undefined : roomName);
		// router.push(`/room/view/${roomName}`)
		// router.push(`/`); // redirect to home, because we joined the room already
	}, [])

	const fetchedRoomList = useCallback((e: any) => {
		const list = safelyParseJSON(e?.detail?.list) || [] // [{"RoomName":"fgjh","PlayerCount":"1"},{"RoomName":"test room 1","PlayerCount":"1"}]
		if (list && Array.isArray(list) && list.length) {
			setRoomList(list)
		}
	}, [])

	const handleJoinCreate = useCallback(() => {
		if (selectedRoom) {
			window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_JOIN_ROOM', { detail: { roomName: selectedRoom } }) as Event)
			router.push(`/`); // redirect to home, because we joined the room already
		} else {
			router.push(createRoomPageLink)
		}
	}, [router, selectedRoom, createRoomPageLink])

	useEffect(() => {
		const onPlayerJoinedRoom = (e) => {
			console.log('PLAYER JOINED: ', (e as any).detail)
			// router.push(`/room/view/${(e as any).detail.roomName}`)
			router.push('/')
		}

		const onUserJoinedRoom = (e) => {
			console.log('USER JOINED: ', (e as any).detail)
			setCreating(false)
			router.push('/')
		}
		// window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_START_PHOTON_GAME', {}) as Event);

		if (isView) {
			window.addEventListener('HODL_VALLEY__UNITY_LIST_OF_ROOMS', fetchedRoomList, false)
			window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_REQUEST_ROOMS', {}) as Event)
		}

		if (isCreate) {
			window.addEventListener('HODL_VALLEY__UNITY_PLAYER_JOINED_ROOM', onPlayerJoinedRoom, false)
			window.addEventListener('HODL_VALLEY__UNITY_USER_JOINED_ROOM', onUserJoinedRoom, false)
		}

		return () => {
			if (isView) {
				window.removeEventListener('HODL_VALLEY__UNITY_LIST_OF_ROOMS', fetchedRoomList)
			}

			if (isCreate) {
				window.removeEventListener('HODL_VALLEY__UNITY_PLAYER_JOINED_ROOM', onPlayerJoinedRoom)
				window.removeEventListener('HODL_VALLEY__UNITY_USER_JOINED_ROOM', onUserJoinedRoom)
			}
		}
	}, [isCreate, isView, fetchedRoomList, router])

	const RenderRow = React.useCallback(
		({ index, style, data }) => {
			const room = data[index] as RoomModel

			return (
				<div key={data[index].id}
						 style={style}
						 className={'px-10px'}
						 onClick={() => selectRoom(room.RoomName)}
						 id={`select-room-${index}-id`}>
					<ModalMenuItem label={room.RoomName}
												 selected={selectedRoom === room.RoomName}
												 suffix={<p className={'text-gray-400 tracking-wider text-xl font-medium'}>
													 {room.PlayerCount}/X
												 </p>}/>
				</div>
			)
		},
		[selectRoom, selectedRoom]
	)

	const createRoom = React.useCallback(() => {
		setCreating(true)
		window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_CREATE_ROOM', { detail: { roomName } }) as Event)
	}, [roomName])

	return (
		<Modal isOpen={true}
					 title={{ firstLine: isCreate ? 'Create' : 'View', secondLine: 'room' }}
					 description={'The property used to travel to other cities on the network and create or join multiplayer rooms in the HODLVERSE network.'}
					 size={Sizes.SMALLER}
					 onReset={() => {
						 window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_CLOSE_MODAL', {}) as Event)
					 }}
					 mainIconName={'Airport'}>
			<div className={`h-full relative xs:h-500px`}>
				<div
					className={`${styles.body} opacity-100 ${isCreate ? 'py-40px px-50px' : 'pt-20px pb-40px px-40px'} xs:py-40px xs:px-25px`}>
					<div className={`h-full flex flex-col ${isCreate && 'justify-center'}`}>
						{isCreate ? (
							<>
								<ModalMenuInputItem placeholder={'Room Name...'} value={roomName} onChange={setRoomName}/>
								<div className={'mt-40px flex items-center justify-between'}>
									<Button type={ButtonType.GHOST} size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.BIG}
													onClick={() => router.push(airportPageLink)} id={'create-room-cancel-btn-id'}>Cancel</Button>
									<Button size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.BIG}
													id={'create-room-create-btn-id'} disabled={creating}
													onClick={createRoom}>Create</Button>
								</div>
							</>
						) : (
							<>
								<div className={`mt-20px ${styles.listWrapper}`}>
									<FixedSizeList
										outerElementType={(props) => <PerfectScrollbar {...props} options={{ suppressScrollX: true }}/>}
										height={408}
										itemData={roomList}
										itemCount={roomList.length}
										itemSize={screenWidth < 70 ? Sizes.SMALL : 86}
										width={'100%'}
									>
										{RenderRow}
									</FixedSizeList>
								</div>
								<div className={'mt-30px flex items-center justify-between'}>
									<Button type={ButtonType.GHOST} size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.BIG}
													onClick={() => router.push(airportPageLink)} id={'view-room-back-btn-id'}>Back</Button>
									<Button size={screenWidth < responsiveSizes.xs ? Sizes.SMALL : Sizes.BIG}
													disabled={isJoinedRoom}
													onClick={handleJoinCreate}
													id={'view-room-create-btn-id'}>
										{selectedRoom ? 'Join' : 'Create New'}
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default CreateViewRoom
