import styles from './farmDetails.module.scss'

interface InputProps {
  title: string;
  subtitle: string;
  value: string;
  placeholder?: string;
  onChange: (val: string) => void;
}

const Input: React.FC<InputProps> = ({title, subtitle, value, onChange, placeholder = ""}) => (
  <div className={styles.inputWrapper}>
    <div className={'flex items-center xs:items-start xs:flex-col xs:w-4/12'}>
      <p className={'uppercase font-medium text-xs text-gray-400 opacity-60 tracking-normal'}>
        {title}
      </p>
      <p className={'uppercase font-medium text-xs text-gray-400 opacity-60 tracking-normal'}>
        {subtitle}
      </p>
    </div>
    <input type="text"
           placeholder={placeholder}
           className={'xs:w-8/12 bg-transparent font-medium text-34px xs:text-base text-gray-400 text-right w-full leading-0'}
           value={value}
           onChange={e => onChange(e.target.value)} />
  </div>
)

export default Input;
