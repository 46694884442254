import { ReactNode, useEffect, useCallback } from 'react'
import useMultiplayer from 'state/multiplayer/hooks'
import { useRouter } from 'next/router'

interface MultiplayerLayoutProps {
	children: ReactNode;
}

const MultiplayerLayout: React.FC<MultiplayerLayoutProps> = ({ children }) => {
	const router = useRouter()
	const { isActive, isConnecting, setConnected, stopMultiplayer } = useMultiplayer()

	useEffect(() => {
		if (!isActive && !isConnecting) {
			router.push('/')
		}
	}, [isActive, isConnecting])

	const PhotonJoinedLobby = useCallback(() => {
		setConnected()
	}, []);

	const PhotonDisconnected = useCallback(() => {
		stopMultiplayer()
	}, []);

	useEffect(() => {
		window.addEventListener('HODL_VALLEY__PHOTON_JOINED_LOBBY', PhotonJoinedLobby, false)

		window.addEventListener('HODL_VALLEY__PHOTON_DISCONNECTED', PhotonDisconnected, false)

		return () => {
			window.removeEventListener('HODL_VALLEY__PHOTON_JOINED_LOBBY', PhotonJoinedLobby, false)
			window.removeEventListener('HODL_VALLEY__PHOTON_DISCONNECTED', PhotonDisconnected, false)
		}
	}, [])

	return (
		<>{children}</>
	)
}

export default MultiplayerLayout
