import styles from "./home.module.scss"

const NetworkStatus: React.FC<{networkName?: string, network?: string}> = ({networkName = "Ethereum Mainnet", network = "Mainnet"}) => (
  <div className='flex items-center xs:relative'>
    <div className={`relative ${styles.circleWrapper} xs:scale-75 xs:absolute xs:-left-56px`}>
      <div className={`absolute rounded-full border-4 ${network === "Mainnet" ? 'bg-green-300 border-green-600' : 'bg-red-400 border-red-500'} ${styles.circle}`}></div>
      <div className={`absolute rounded-full border-4 ${network === "Mainnet" ? `border-green-600 ${styles.ringGreenAnimation}` : `border-red-500 ${styles.ringRedAnimation}`} ${styles.circleRing}`}></div>
    </div>
    <p className={'uppercase text-xl pl-4 tracking-normal pl-17px xs:text-sm xs:pl-0'}>
      {networkName}
    </p>
  </div>
);

export default NetworkStatus
