import React, { useCallback, useEffect, useState } from 'react'
import Image from "next/image";
import styles from "./formDetails.module.scss"
import { ButtonType } from 'components/Button'
import { Sizes } from 'types/Sizes'
import { Button } from 'components/index'

export interface FormDetailsColumnModal {
  title: string;
  value: string;
}

export interface FormDetailsConfigModel {
  title: string;
  infoColumns: FormDetailsColumnModal[];
}

interface FormDetailsProps {
  config: FormDetailsConfigModel;
  showFormDetailsModal?: boolean;
  onManualChangeVisFormDetails?: (show: boolean) => void;
}

const FormDetails: React.FC<FormDetailsProps> = ({config, showFormDetailsModal, onManualChangeVisFormDetails}) => {
  const [expand, setExpand] = useState(false);

  const toggle = useCallback(() => {
    setExpand(x => !x);
    onManualChangeVisFormDetails && onManualChangeVisFormDetails(!expand);
  }, [expand, onManualChangeVisFormDetails]);

  useEffect(() => {
    setExpand(showFormDetailsModal)
  }, [showFormDetailsModal]);

  return (
    <div className={`bg-blue-400 border-5 border-blue-500 border-t-0 flex xs:flex-col items-center 
                     relative rounded-bl-52 rounded-br-52 xs:rounded-40 xs:w-[calc(100%-20px)] xs:mx-auto
                    ${styles.wrapper} ${expand ? 'w-900px h-76px xs:h-524px xs:border-10' : 'w-310px h-56px xs:h-0 xs:border-0 xs:overflow-hidden'}`}>
      <div className={`flex items-center w-full h-full absolute top-0 left-0 justify-center px-32px transition-opacity 
                        ${expand ? styles.hide : styles.show}`}
           id={'modal-form-details-open-btn-id'}
           onClick={toggle}>
        <p className={'text-base uppercase font-medium text-white'}>{config.title}</p>
      </div>
      <div className={`flex xs:flex-col items-center w-full h-full absolute top-0 left-0 justify-between px-32px transition-opacity 
                        xs:pt-49px xs:pb-38px xs:px-37px 
                        ${expand ? styles.show : styles.hide}`}>
        <p className={'text-base uppercase font-medium text-white xs:text-25px xs:tracking-normal xs:mb-18px'}>{config.title}</p>
        <hr className={`border-0 bg-white bg-opacity-20 w-5px xs:hidden ${styles.delimiter}`} />
        {config.infoColumns.map((column, index) => (
          <>
            <div className={`${styles.column} ${expand ? 'opacity-100' : 'opacity-0'}`} key={index}>
              <p className={'font-medium text-xs xs:text-sm text-center text-white text-opacity-60 uppercase'}>{column.title}</p>
              <p className={'font-medium text-xs xs:text-sm text-center text-white uppercase'}>{column.value || '-'}</p>
            </div>
            {index !== config.infoColumns.length - 1 &&
              <hr className={`border-0 bg-white bg-opacity-20 ${styles.delimiter}`} key={`${index}_split`} />
            }
          </>
        ))}
        <hr className={`border-0 bg-white bg-opacity-20 w-5px xs:hidden ${styles.delimiter}`} />
        <div className={'w-24px h-16px xs:hidden'} onClick={toggle} id={'modal-form-details-close-arrow-id'}>
          <Image src={'/icons/arrowTop.svg'} alt="Arrow Up" width={24} height={16} />
        </div>
        <div className={'hidden xs:flex justify-center mt-27px'}>
          <Button type={ButtonType.GHOST} size={Sizes.NORMAL} onClick={toggle} id={'modal-form-details-close-btn-id'}>
            <p className={'tracking-wider xs:text-10px uppercase xs:text-gray-400'}>Close details</p>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FormDetails
