import {Market, setMarket, setMarkets, setQuote} from './actions'
import { createReducer } from '@reduxjs/toolkit'



export interface CrossChainState {

    readonly market: Market
    readonly markets: Market[]
}

const initialState: CrossChainState = {

    market: null,
    markets: []
}

export default createReducer<CrossChainState>(initialState, (builder) =>
    builder
        .addCase(
            setMarkets,
            (state, { payload: { markets } }) => {
                return {
                    ...state,
                    markets
                }
            }
        )
        .addCase(
            setMarket,
            (state, { payload: { market } }) => {
                return {
                    ...state,
                    market
                }
            }
        )
        .addCase(
            setQuote,
            (state, { payload: { quote } }) => {
                return {
                    ...state,
                    quote
                }
            }
        )
)
