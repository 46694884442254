import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { Button, Modal, Tooltip } from 'components'

import useModals from 'state/modals/hooks'
import { AppState } from 'state'

import { useGetPair, usePositionCardInformation } from 'hooks/Pool'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'

import styles from './poolDetails.module.scss'

const PoolDetailsModal: React.FC = () => {
	const router = useRouter()
	const { closeModals } = useModals()
	const { account } = useActiveWeb3React()
	const { i18n } = useLingui()

	if (!account) {
		router.push('/')
	}

	const { poolData, isUserPool } = useSelector((state: AppState) => state.pool)

	const pair = useGetPair(poolData || null)

	const {
		urlEnd,
		poolTokenPercentage,
		currency0,
		token0Deposited,
		token1Deposited,
		token0Rate,
		userPoolBalance,
		token1Rate,
		currency1
	} = usePositionCardInformation({ pair })

	const disableRemovePool = useMemo(() => {
		return !(userPoolBalance?.toSignificant(4) && parseFloat(userPoolBalance?.toSignificant(4)) > 0)
	}, [userPoolBalance])

	const handleAddPool = useCallback(() => {
		closeModals()
		router.push(`/pool/add/${urlEnd}`)
	}, [closeModals, router, urlEnd])

	const handleDeletePool = useCallback(() => {
		closeModals()
		router.push(`/pool/remove/${urlEnd}`)
	}, [closeModals, router, urlEnd])

	const token0RateRow = useMemo(() => {
		return token0Rate ? (
			<>
				<div className={`${styles.infoRow} ${styles.infoRowNoMobilePadding}`}>
					<p className={styles.title}>{i18n._(t`Rates`)}</p>
				</div>
				<div className={`${styles.infoRow} ${styles.infoRowNoMobilePadding}`}>
					<div className={styles.value}>1 {currency0.symbol} =
						<div className={`inline-flex px-5px truncate ${styles.valWrapper}`}>
							<Tooltip message={token0Rate || ''}>
								<p className={'truncate max-w-ful'}>{token0Rate.toFixed(4)}</p>
							</Tooltip>
						</div>
						{currency1.symbol}
					</div>
				</div>
			</>
		) : null
	}, [token0Rate, currency0, currency1])

	const token1RateRow = useMemo(() => {
		return token0Rate ? (
			<>
				<div className={`${styles.infoRow} ${styles.infoRowNoMobilePadding}`}>
					<p className={styles.title}>{i18n._(t`Rates`)}</p>
				</div>
				<div className={`${styles.infoRow} ${styles.infoRowNoMobilePadding}`}>
					<p className={styles.value}>
						<div className={`inline-flex pr-5px truncate ${styles.valWrapper}`}>
							<Tooltip message={token1Rate || ''}>
								<p className={'truncate max-w-ful'}>{token1Rate.toFixed(4)}</p>
							</Tooltip>
						</div>
						{currency1.symbol} = 1 {currency0.symbol}</p>
				</div>
			</>
		) : null
	}, [token1Rate, currency0, currency1])

	if (!poolData?.token0) {
		router.push('/pool')
	}

	return (
		<Modal isOpen={true}
					 title={{ firstLine: `${isUserPool ? `${i18n._(t`My`)}` : ''} ${i18n._(t`Pool`)}`, secondLine: `${i18n._(t`Details`)}` }}
					 description={`${i18n._(t`The property for all pools and adding liquidity in the HODLVERSE network`)}.`}
					 mainIconName={'PoolOne'}>
			<div className={`h-full relative xs:h-500px`}>
				<div className={`h-full flex flex-col`}>
					<div className={styles.assetForm}>
						{poolData &&
						<div className={'flex'}>
							<div className={`${styles.leftColumn} pr-10px`}>
								<div className={styles.infoRow}>
									<p className={styles.title}>{i18n._(t`Pool share`)}</p>
									<p className={styles.value}>{poolTokenPercentage
										? (poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)) + '%'
										: '-'}</p>
								</div>
								<div className={styles.infoRow}>
									<p className={styles.title}>{currency0.symbol} {i18n._(t`Added`)}</p>
									<div className={styles.valueWrapper}>
										<Tooltip message={token0Deposited ? token0Deposited?.toSignificant(6) : ''}>
											<p
												className={`${styles.value} truncate`}>{token0Deposited ? token0Deposited?.toSignificant(6) : '-'}</p>
										</Tooltip>
									</div>
								</div>
								<div className={'hidden xs:block'}>
									{token0RateRow}
								</div>
								<div className={'xs:hidden'}>
									<div className={styles.infoRow}>
										<p className={styles.title}>{currency1.symbol} {i18n._(t`Added`)}</p>
										<div className={styles.valueWrapper}>
											<Tooltip message={token1Deposited ? token1Deposited?.toSignificant(6) : ''}>
												<p
													className={`${styles.value} truncate max-w-full`}>{token1Deposited ? token1Deposited?.toSignificant(6) : '-'}</p>
											</Tooltip>
										</div>
									</div>
								</div>
								{/*<div className={styles.infoRow}>*/}
								{/*  <p className={styles.title}>DATE ADDED</p>*/}
								{/*  <p className={styles.value}>{"DateTime.fromJSDate(poolData.addedAt).toFormat('LL/d/yyyy')"}</p>*/}
								{/*</div>*/}
							</div>
							<div className={styles.rightColumn}>
								<div className={'hidden xs:block'}>
									<div className={styles.infoRow}>
										<p className={styles.title}>{i18n._(t`Date added`)}</p>
										<div className={styles.valueWrapper}>
											<p className={`${styles.value} truncate max-w-full`}>12/1/2021</p>
										</div>
									</div>
								</div>

								<div className={'hidden xs:block'}>
									<div className={styles.infoRow}>
										<p className={styles.title}>{currency1.symbol} {i18n._(t`Added`)}</p>
										<div className={styles.valueWrapper}>
											<Tooltip message={token1Deposited ? token1Deposited?.toSignificant(6) : ''}>
												<p
													className={`${styles.value} truncate max-w-full`}>{token1Deposited ? token1Deposited?.toSignificant(6) : '-'}</p>
											</Tooltip>
										</div>
									</div>
								</div>

								<div className={'block xs:hidden'}>
									{token0RateRow}
								</div>

								{token1RateRow}
							</div>
						</div>
						}
					</div>
					<div className={`w-full relative z-10 pt-42px pb-20px pl-65px pr-40px xs:py-33px xs:px-14px`}>
						<div className={'flex flex-col items-center'}>
							<p
								className={'text-40px xs:text-26px text-blue-400 xs:text-gray-400 font-medium tracking-normal xs:leading-8'}>
								{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
							</p>
							<p className={'text-xl xs:text-xs text-gray-400 font-medium tracking-normal -mt-2px uppercase'}>
								{currency0?.symbol}/{currency1?.symbol} {i18n._(t`Pool tokens`)}
							</p>
						</div>
						<div className={'flex justify-between items-center mt-45px xs:mt-79px'}>
							<Button onClick={handleAddPool} wrapClassname={'xs:w-140px'} id={`add-pool-btn-id`}>
								<p className={'tracking-normal'}>{i18n._(t`Add liquidity`)}</p>
							</Button>
							<Button disabled={disableRemovePool} wrapClassname={'xs:w-140px'} onClick={handleDeletePool} id={`delete-pool-btn-id`}>
								<p className={`tracking-normal ${disableRemovePool ? 'text-gray-750' : 'text-white'}`}>{i18n._(t`Remove pool`)}</p>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default PoolDetailsModal
