import { useEffect, useMemo, useState } from 'react'

import Item from './item'
import Web3Status from '../Web3Status'

import { useWallet } from 'state/wallet/hooks'
import { ApplicationModal } from 'state/modals/actions'

import MenuIcon from '../../../public/icons/menu.svg'
import LogoIcon from '../../../public/icons/hodlLogoBlackStroke.svg'

import styles from './menu.module.scss'
import { CameraViewSwitcher, LanguageNetworkSwitcher } from 'components/index'
import { useWindowSize } from 'hooks/useWindowSize'
import { responsiveSizes } from 'constants/sizes'

export enum MenuItemTypeModel {
	LINK = 'LINK',
	MODAL = 'MODAL',
	EXTERNAL_LINK = 'EXTERNAL_LINK',
	LOCAL_EVENT = 'LOCAL_EVENT'
}

export interface MenuItemModel {
	label: string;
	type?: MenuItemTypeModel;
	link?: string | ApplicationModal;
	subItems?: MenuItemModel[];
}

const menuList: MenuItemModel[] = [
	{
		label: 'DeFi', subItems: [
			{ label: 'My Home', type: MenuItemTypeModel.LINK, link: '/home' },
			{ label: 'Swap', type: MenuItemTypeModel.LINK, link: '/swapMeet' },
			{ label: 'Pool', type: MenuItemTypeModel.LINK, link: '/pool' },
			{ label: 'Farm', type: MenuItemTypeModel.LINK, link: '/farm' },
			{ label: 'Stake', type: MenuItemTypeModel.LINK, link: '/stake' },
			{ label: 'Govern' },
			{ label: 'Lend' },
			{ label: 'Borrow' },
			{ label: 'Insure' },
			{ label: 'Lock' },
			{ label: 'Mint' },
			{ label: 'Batch Send' },
			{ label: 'NFT' }
		]
	},
	{
		label: 'ANALYTICS', subItems: [
			{ label: 'Pairs', type: MenuItemTypeModel.LINK, link: '/pairs' },
			{ label: 'Tokens', type: MenuItemTypeModel.LINK, link: '/tokens' },
			{ label: 'Pools', type: MenuItemTypeModel.LINK, link: '/pools' },
			{ label: 'Gainers', type: MenuItemTypeModel.LINK, link: '/gainers' },
			{ label: 'Losers', type: MenuItemTypeModel.LINK, link: '/losers' }
		]
	},
	{ label: 'DOCS', type: MenuItemTypeModel.EXTERNAL_LINK, link: 'https://hodlverse.gitbook.io/docs' },
	{ label: 'COMMUNITY', type: MenuItemTypeModel.EXTERNAL_LINK, link: 'https://discord.gg/GD44kaKXD8' }
]

const Menu: React.FC = () => {
	const { alertNotConnectedWallet } = useWallet()

	const { width: screenWidth } = useWindowSize()

	const [showMobileMenu, setShowMobileMenu] = useState(false)

	const [activeSubmenu, setActiveSubmenu] = useState(false);

	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		setIsMobile(screenWidth < responsiveSizes.xs)
	}, [screenWidth])

	const menuEl = useMemo(() => (
		<>
			{menuList.map((item, index) => (
				<div className={'px-25px last:mr-11px xs:border-t-2 xs:border-white xs:border-opacity-30 xs:w-full ' +
				'xs:px-0 xs:pt-14px xs:pb-9px xs:last:mr-0 xs:last:border-b-2'}
						 key={index}>
					<Item item={item} handleActiveSubmenu={setActiveSubmenu} handleCloseMenu={() => setShowMobileMenu(false)}/>
				</div>
			))}
		</>
	), [])

	const webWalletWrapper = useMemo(() => (
		<div className={`${styles.walletBtn} ${alertNotConnectedWallet ? styles.walletBtnAnimated : ""} 
										flex items-center bg-red-400 border-4 border-red-500 h-full bg-opacity-100 rounded-30 px-25px
										xs:rounded-none xs:px-30px`} onClick={() => setShowMobileMenu(show => !show)}>
			<Web3Status />
		</div>
	), [alertNotConnectedWallet])

	return (
		<>
			<div className={`backdrop-filter-blur-50 h-68px rounded-34 pl-34px bg-white bg-opacity-80 border-4 border-white flex justify-end items-center 
    xs:w-full xs:rounded-none xs:bg-white xs:justify-start xs:border-gray-280 xs:border-4 xs:pl-30px xs:wrapper-xs xs:z-10`}>
				<div className={"hidden xs:flex w-full items-center justify-between"}>
					<div className={"w-full flex items-center"}>
						<div className={''} onClick={() => setShowMobileMenu(!showMobileMenu)}>
							<MenuIcon/>
						</div>
						<div className={'pl-10px'}>
							<LogoIcon width={111} height={17} />
						</div>
					</div>
					<div className="flex justify-end items-center">
						<CameraViewSwitcher />
						<LanguageNetworkSwitcher />
					</div>
				</div>
				{!isMobile && (
					<div className={"xs:hidden flex justify-end items-center h-full"}>
						<div className={'flex justify-end items-center'}>
							{menuEl}
						</div>
						<div className={'block h-full'}>
							{webWalletWrapper}
						</div>
					</div>
				)}
			</div>
			{isMobile && (
				<div className={`hidden xs:fixed left-0 top-68px h-[calc(100vh-68px)] w-full xs:flex flex-col
                       backdrop-filter-blur-50 rounded-22 xs:rounded-none bg-white bg-opacity-80
                       transition-transform ${showMobileMenu ? 'translate-x-0' : '-translate-x-full'}`}>
					<div className={`flex justify-end items-center xs:flex-col xs:justify-start xs:pl-34px xs:pr-45px xs:py-22px 
					transition-transform relative h-[calc(100%-117px)] 
					${activeSubmenu && '-translate-x-full'}`}>
						{menuEl}
					</div>
					<div className={'w-full absolute bottom-0 left-0 h-117px'}>
						{webWalletWrapper}
					</div>
				</div>
			)}
		</>
	)
}

export default Menu
