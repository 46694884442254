import React, { useCallback, useState } from 'react'
import Image from "next/image";
import Select from "react-select";
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

export interface SearchAutocompleteItemType {
  label: string;
  value: string;
  icon?: string;
}

interface SearchAutocompleteProps {
  placeholder?: string;
  value?: string;
  onChange: (val: string) => void;
  options: SearchAutocompleteItemType[];
}

const SearchAutocomplete: React.FC<SearchAutocompleteProps> = ({
                                                   placeholder= 'Search...',
                                                   value,
                                                   onChange,
                                                   options
                                                 }) => {
  const { i18n } = useLingui();

  const [val, setVal] = useState(value || null);

  const handleChange = useCallback((option) => {
    onChange(option.value)
    setVal(option);
  }, [onChange]);

  return (
    <div className={`w-full shadow-custom-3-inset bg-red h-50px xs:h-36px rounded-full bg-gray-220 border-3 border-white 
    border-opacity-60 bg-opacity-60 flex items-center relative search-autocomplete`}>
      <Select
        isSearchable
        className="dropdown"
        classNamePrefix="dropdown"
        options={options}
        value={val}
        onChange={option => handleChange(option)}
        placeholder={i18n._(t`${placeholder || "Search..."}`)}
        // menuIsOpen
      />
      <div className={'xs:hidden w-[40px] h-full flex absolute right-0 top-0'}>
        <Image src="/icons/magnifierBold.svg" alt="Magnifier" width={26} height={24} className={"w-2/12"} />
      </div>
    </div>
  )
}

export default SearchAutocomplete;
