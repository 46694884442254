import { createReducer } from '@reduxjs/toolkit'
import {
  addTransactionToast,
  updateTransactionToast,
  removeTransactionToast
} from './actions'

export type NotificationToast = {
  id: string,
  toastId: any;
  transactionInfo: any
}

export interface TransactionToastValues {
  readonly currentNotificationToastList: NotificationToast[]
}

const initialState: TransactionToastValues = {
  currentNotificationToastList: []
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(addTransactionToast, (state, action) => {
      const toastList = [...state.currentNotificationToastList];
      toastList.push(action.payload);

      state.currentNotificationToastList = toastList
    })
    .addCase(updateTransactionToast, (state, action) => {
      const toast = [...state.currentNotificationToastList];
      const index = toast.findIndex(x => x.id === action.payload.id);
      if (index > -1) {
        toast[index] = action.payload;
        state.currentNotificationToastList = toast;
      }
    })
    .addCase(removeTransactionToast, (state, action) => {
      const toast = [...state.currentNotificationToastList];
      const index = toast.findIndex(x => x.id === action.payload.id);
      if (index > -1) {
        toast.splice(index, 1);
        state.currentNotificationToastList = toast;
      }
    })
)
