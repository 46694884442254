import {useCallback} from "react";
import Image from "next/image";
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

interface SearchInputProps {
  placeholder?: string;
  value: string;
  onChange: (val: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
                                                   placeholder= 'Search...',
                                                   value,
                                                   onChange
                                                 }) => {
  const { i18n } = useLingui()

  const handleChange = useCallback((e) => {
    onChange(e.target.value)
  }, [onChange]);

  return (
    <div className={`w-full shadow-custom-3-inset bg-red h-50px xs:h-36px rounded-full bg-gray-220 border-3 border-white border-opacity-60 bg-opacity-60 flex items-center pl-26px xs:pl-18px pr-2px`}>
      <input type="text"
             value={value}
             onChange={handleChange}
             id={'search-input-id'}
             placeholder={`${i18n._(`${placeholder}`)}`}
             className={"font-medium text-base text-gray-700 placeholder-gray-700 bg-transparent w-10/12 xs:text-xs placeholder:uppercase"} />
      <div className={'xs:hidden'}>
        <Image src="/icons/magnifierBold.svg" alt="Magnifier" width={26} height={24} className={"w-2/12"} />
      </div>
    </div>
)}

export default SearchInput
