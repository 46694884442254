import {Fragment, FunctionComponent, useEffect} from 'react'
import Head from 'next/head'
import {useRouter} from 'next/router'
import {Provider as ReduxProvider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {ToastContainer} from 'react-toastify'
import {ApolloProvider} from '@apollo/client'
import {NextComponentType, NextPageContext} from 'next'
import type {AppProps} from 'next/app'
import ReactGA from 'react-ga'
import {Web3ReactProvider} from '@web3-react/core'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import {SessionProvider} from 'next-auth/react'
import {I18nProvider} from '@lingui/react'
import {i18n} from '@lingui/core'
import * as plurals from 'make-plural/plurals'
// import { remoteLoader } from '@lingui/remote-loader'

import {Dots} from 'components'
import Web3ReactManager from 'components/Web3ReactManager'
import {MoralisProvider} from "react-moralis";

import store, {persistor} from 'state'
import ListsUpdater from 'state/lists/updater'
import MulticallUpdater from 'state/multicall/updater'
import TransactionUpdater from 'state/transactions/updater'
import UserUpdater from 'state/user/updater'
import ApplicationUpdater from 'state/application/updater'

import {useApollo} from 'analytics/core/apollo'

import getLibrary from 'functions/getLibrary'
import {GLOBAL} from 'config'

import Layout from 'layouts'
import {CrossChainClientProvider} from 'crosschainswap/cross.client.provider'

import '../bootstrap'

import '../styles/globals.scss'
import {ApolloCustomProvider} from "analytics/apollo.custom.provider";

const Web3ProviderNetwork = dynamic(() => import('../components/Web3ProviderNetwork'), {ssr: false})

if (typeof window !== 'undefined' && !!window.ethereum) {
    window.ethereum.autoRefreshOnNetworkChange = false
}

export default function MyApp({
                                  Component,
                                  pageProps: {session, ...pageProps}
                              }: AppProps & {
    Component: NextComponentType<NextPageContext> & {
        Guard: FunctionComponent
        Layout: FunctionComponent
        Provider: FunctionComponent

    }
}) {
    const router = useRouter()
    // const client = useApollo(pageProps.initialApolloState)

    const {pathname, query, locale} = router

    useEffect(() => {
        ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {testMode: process.env.NODE_ENV === 'development'})

        const errorHandler = (error) => {
            ReactGA.exception({
                description: `${error.message} @ ${error.filename}:${error.lineno}:${error.colno}`,
                fatal: true
            })
        }

        window.addEventListener('error', errorHandler)

        return () => window.removeEventListener('error', errorHandler)
    }, [])

    useEffect(() => {
        ReactGA.pageview(`${pathname}${query}`)
    }, [pathname, query])

    useEffect(() => {
        // @ts-ignore TYPE NEEDS FIXING
        async function load(locale) {
            // @ts-ignore TYPE NEEDS FIXING
            i18n.loadLocaleData(locale, {plurals: plurals[locale.split('_')[0]]})

            // try {
            // 	// Load messages from AWS, use q session param to get latest version from cache
            // 	const res = await fetch(
            // 		`https://raw.githubusercontent.com/sushiswap/translations/master/sushiswap/${locale}.json`
            // 	)
            // 	const remoteMessages = await res.json()
            //
            // 	console.log('REMOTE MESSAGES: ', remoteMessages);
            //
            // 	const messages = remoteLoader({ messages: remoteMessages, format: 'minimal' })
            // 	i18n.load(locale, messages)
            // } catch {
            // 	// Load fallback messages
            // 	const { messages } = await import(`@lingui/loader!./../../locale/${locale}.json?raw-lingui`)
            // 	i18n.load(locale, messages)
            // }

            const {messages} = await import(`@lingui/loader!./../../locale/${locale}.json?raw-lingui`)
            i18n.load(locale, messages)

            i18n.activate(locale)
        }

        load(locale)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale])

    // Allows for conditionally setting a provider to be hoisted per page
    const Provider = Component.Provider || Fragment

    // Allows for conditionally setting a guard to be hoisted per page
    const Guard = Component.Guard || Fragment

    return (
        <SessionProvider session={session}>
            <Head>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                <link rel="manifest" href="/site.webmanifest"/>
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ff0100"/>
                <meta name="msapplication-TileColor" content="#ffffff"/>
                <meta name="theme-color" content="#ffffff"/>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
              window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-THYJ00Y6YW');

    gtag('create', 'G-THYJ00Y6YW', 'auto', {allowLinker: true});
    gtag('require', 'linker');
    gtag('linker:autoLink', ['https://hodlvalley.com']);
            `
                    }}
                />
            </Head>
            <Script
                src="https://www.googletagmanager.com/gtag/js?id=G-THYJ00Y6YW"
                strategy="afterInteractive"
            />
            <Script
                src="https://sdk.relicx.ai/relicx-sdk.min.js"
                strategy="lazyOnload"
                onLoad={() => {
                    // @ts-ignore
                    relicxSDK?.init({
                        orgID: '585412e7-e5a8-42d0-9446-683c03138844',
                        appID: '3d45eed7-6e9c-4af6-8d68-72b63047e8ff'
                    })
                }}
            />
            <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
                <Web3ReactProvider getLibrary={getLibrary}>
                    <Web3ProviderNetwork getLibrary={getLibrary}>
                        <Web3ReactManager>
                            <ReduxProvider store={store}>
                                <PersistGate loading={<Dots>loading</Dots>} persistor={persistor}>
                                    <ApolloCustomProvider>
                                            <>
                                                <ListsUpdater/>
                                                <UserUpdater/>
                                                <ApplicationUpdater/>
                                                <TransactionUpdater/>
                                                <MulticallUpdater/>
                                            </>
                                            <Provider>
                                                <Guard>
                                                    <Layout.AuthWrapper>
                                                        <MoralisProvider
                                                            appId={GLOBAL.MORALIS_APP_ID}
                                                            serverUrl={GLOBAL.MORALIS_SERVER_URL}
                                                        >
                                                            <Layout.Default blurBg={pathname !== "/"}>
                                                                <Component {...pageProps} />
                                                                <ToastContainer/>
                                                            </Layout.Default>
                                                        </MoralisProvider>
                                                    </Layout.AuthWrapper>
                                                </Guard>
                                            </Provider>
                                    </ApolloCustomProvider>

                                </PersistGate>
                            </ReduxProvider>

                        </Web3ReactManager>
                    </Web3ProviderNetwork>
                </Web3ReactProvider>
            </I18nProvider>
        </SessionProvider>
    )
}

// export default MyApp
