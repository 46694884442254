import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Image from 'next/image'
import { Currency, CurrencyAmount, JSBI, Percent, Trade as V2Trade, TradeType } from '@hodlvalley/sdk'
import ReactGA from 'react-ga'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import {
	BackButton,
	Button,
	Column,
	Loader,
	Modal,
	ProgressSteps,
	TokenForm,
	Tooltip
} from 'components'
import { Sizes } from 'types/Sizes'

import Input from './Input'
import Toggle from './toggle'

import { SwapCallbackError } from 'features/swap/styleds'
import confirmPriceImpactWithoutFee from 'features/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from 'features/swap/ConfirmSwapModal'
import { getTradePriceStr } from 'features/swap/TradePrice'

import { useActiveWeb3React } from 'hooks'
import { useIsSwapUnsupported } from 'hooks/useIsSwapUnsupported'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import useIsArgentWallet from 'hooks/useIsArgentWallet'
import { ApprovalState, useApproveCallbackFromTrade } from 'hooks/useApproveCallback'
import { useUSDCValue } from 'hooks/useUSDCPrice'
import { useSwapCallback } from 'hooks/useSwapCallback'
import useENSAddress from 'hooks/useENSAddress'
import { showToast, ToastTypes } from 'hooks/useToast'
import { useWindowSize } from 'hooks/useWindowSize'

import { useDerivedSwapInfo, useSwapActionHandlers, useSwapState } from 'state/swap/hooks'
import {
	useExpertModeManager,
	useUserArcherETHTip,
	useUserArcherUseRelay,
	useUserSingleHopOnly,
	useUserTransactionTTL
} from 'state/user/hooks'
import { Field } from 'state/swap/actions'

import { ARCHER_RELAY_URI } from '../../constants'

import { computeRealizedLPFeePercent, warningSeverity } from 'functions/prices'
import {computeFiatValuePriceImpact} from 'functions/trade'
import { maxAmountSpend } from 'functions/currency'

import { useAgentMarket } from 'crosschainswap/hooks'

import styles from './swaptokens.module.scss'
import { responsiveSizes } from 'constants/sizes'
import SettingsModal from "components/Settings";
import {useCrossChainSwapCallback} from "hooks/useCrossChainSwapCallback";
import {TransactionType} from "modals/TransactionConfirmationModal";
import HodlConfirmationModal from "modals/Confirmation";

const SwapTokensModal: React.FC = () => {
	const size = useWindowSize()
	const { i18n } = useLingui()
	const { account, chainId } = useActiveWeb3React()
	const { independentField, typedValue, recipient } = useSwapState()
	const [useArcher] = useUserArcherUseRelay()
	const [singleHopOnly, setSingleHopOnly] = useUserSingleHopOnly()
	const { onCurrencySelection, onUserInput } = useSwapActionHandlers()

	const [isExpertMode] = useExpertModeManager()
	const isArgentWallet = useIsArgentWallet()
	const [ttl] = useUserTransactionTTL()
	const [archerETHTip] = useUserArcherETHTip()

	const [refresh, setRefresh] = useState(false)

	const settingsModalRef = useRef();

	const [showFormDetailsModal, setShowFormDetailsModal] = useState(false)

	// check if user has gone through approval process, used to show two step buttons, reset on token change
	const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

	const [showSettings, setShowSettings] = useState(false)

	const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
		showConfirm: boolean
		tradeToConfirm: V2Trade<Currency, Currency, TradeType> | undefined
		attemptingTxn: boolean
		swapErrorMessage: string | undefined
		txHash: string | undefined
	}>({
		showConfirm: false,
		tradeToConfirm: undefined,
		attemptingTxn: false,
		swapErrorMessage: undefined,
		txHash: undefined
	})

	const doArcher = useMemo(() => {
		const archerRelay = chainId ? ARCHER_RELAY_URI?.[chainId] : undefined
		return archerRelay !== undefined && useArcher
	}, [chainId, useArcher])

	const {
		v2Trade,
		currencyBalances,
		parsedAmount,
		currencies,
		inputError: swapInputError,
		allowedSlippage,
		isCrossChain
	} = useDerivedSwapInfo(doArcher)


	const {
		wrapType,
		execute: onWrap,
		inputError: wrapInputError
	} = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)

	const handleMaxInput = useCallback(() => {
		const maxInputAmount: CurrencyAmount<Currency> | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
		maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
	}, [onUserInput, currencyBalances])

	const swapIsUnsupported = useIsSwapUnsupported(currencies?.INPUT, currencies?.OUTPUT)
	const showWrap = useMemo(() => wrapType !== WrapType.NOT_APPLICABLE, [wrapType])
	const trade = useMemo(() => {
		return showWrap ? undefined : v2Trade
	}, [showWrap, v2Trade])

	// check whether the user has approved the router on the input token
	const [approvalState, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage, doArcher)

	const parsedAmounts = useMemo(
		() =>
			showWrap
				? {
					[Field.INPUT]: parsedAmount,
					[Field.OUTPUT]: parsedAmount
				}
				: {
					[Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
					[Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
				},
		[independentField, parsedAmount, showWrap, trade]
	)

	const priceImpact = computeFiatValuePriceImpact(useUSDCValue(parsedAmounts[Field.INPUT]), useUSDCValue(parsedAmounts[Field.OUTPUT]))
	const { address: recipientAddress } = useENSAddress(recipient)

	const { realizedLPFee, priceImpact: lppriceImpact } = useMemo(() => {
		if (!trade) return { realizedLPFee: undefined, priceImpact: undefined }

		const realizedLpFeePercent = computeRealizedLPFeePercent(trade)
		const realizedLPFee = trade.inputAmount.multiply(realizedLpFeePercent)

		const priceImpact = trade.priceImpact.subtract(realizedLpFeePercent)

		return { priceImpact, realizedLPFee }
	}, [trade])

	const coinAgentTradeDetails = useAgentMarket(parsedAmount, currencies[Field.OUTPUT], isCrossChain)
	const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

	const priceImpactSeverity = useMemo(() => {
		if (isCrossChain) {
			return 0
		}
		const executionPriceImpact = trade?.priceImpact
		return warningSeverity(
			executionPriceImpact && priceImpact
				? executionPriceImpact.greaterThan(priceImpact)
				? executionPriceImpact
				: priceImpact
				: executionPriceImpact ?? priceImpact
		)
	}, [priceImpact, trade, isCrossChain])

	// show approve flow when: no error on inputs, not approved or pending, or approved in current session
	// never show if price impact is above threshold in non expert mode
	const showApproveFlow =
		!isArgentWallet &&
		!swapInputError &&
		(approvalState === ApprovalState.NOT_APPROVED ||
			approvalState === ApprovalState.PENDING ||
			(approvalSubmitted && approvalState === ApprovalState.APPROVED)) &&
		!(priceImpactSeverity > 3 && !isExpertMode)

	// the callback to execute the swap
	const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
		trade,
		allowedSlippage,
		recipient,
		undefined, // signatureData
		doArcher ? ttl : undefined,
		isCrossChain
	)

	const {callback: crossChainSwapCallback} = useCrossChainSwapCallback()

	const isValid = !swapInputError

	const formattedAmounts = useMemo(() => ({
			[independentField]: typedValue,
			[dependentField]: isCrossChain
				? coinAgentTradeDetails[dependentField]
				: showWrap
					? parsedAmounts[independentField]?.toExact() ?? ''
					: parsedAmounts[dependentField]?.toSignificant(6) ?? ''
		}
	), [typedValue, independentField, dependentField, isCrossChain, coinAgentTradeDetails, showWrap, parsedAmounts])

	const switchTokens = useCallback(() => {
		onCurrencySelection(Field.INPUT, currencies[Field.OUTPUT])
		onCurrencySelection(Field.OUTPUT, currencies[Field.INPUT])
	}, [currencies])

	const toggleSettings = useCallback(() => {
		setShowSettings(!showSettings)
	}, [showSettings])

	const handleSwap = useCallback(() => {
		if (!swapCallback) {
			return
		}
		if (priceImpact && !confirmPriceImpactWithoutFee(priceImpact)) {
			return
		}
		setSwapState({
			attemptingTxn: true,
			tradeToConfirm: trade,
			showConfirm: true,
			swapErrorMessage: undefined,
			txHash: undefined
		})
		swapCallback()
			.then((hash) => {

				setSwapState({
					attemptingTxn: false,
					tradeToConfirm,
					showConfirm: true,
					swapErrorMessage: undefined,
					txHash: hash
				})
				ReactGA.event({
					category: 'Swap',
					action:
						recipient === null
							? 'Swap w/o Send'
							: (recipientAddress ?? recipient) === account
							? 'Swap w/o Send + recipient'
							: 'Swap w/ Send',
					label: [
						trade?.inputAmount?.currency?.symbol,
						trade?.outputAmount?.currency?.symbol,
						singleHopOnly ? 'SH' : 'MH'
					].join('/')
				})

				ReactGA.event({
					category: 'Routing',
					action: singleHopOnly ? 'Swap with multihop disabled' : 'Swap with multihop enabled'
				})
			})
			.catch((error) => {
				showToast(error.message, {
					type: ToastTypes.error,
					timeout: 7 * 1000
				})
				setSwapState({
					attemptingTxn: false,
					tradeToConfirm,
					showConfirm: false,
					swapErrorMessage: error.message,
					txHash: undefined
				})
			})
	}, [
		swapCallback,
		priceImpact,
		tradeToConfirm,
		showConfirm,
		recipient,
		recipientAddress,
		account,
		trade?.inputAmount?.currency?.symbol,
		trade?.outputAmount?.currency?.symbol,
		singleHopOnly
	])

	const handleTypeAmount = useCallback(
		(isInput: boolean, value: string) => {
			onUserInput(isInput ? Field.INPUT : Field.OUTPUT, value)
		},
		[onUserInput]
	)

	const swapDetailMinToken = useMemo(() => {
		if (trade) {
			return `${trade.minimumAmountOut(allowedSlippage).toSignificant(6)} ${trade.outputAmount.currency.symbol}`
		} else if (isCrossChain) {
			return `${coinAgentTradeDetails[dependentField]?.toFixed(6)} ${currencies[Field.OUTPUT]?.symbol}`
		}
		return ''
	}, [trade, isCrossChain, coinAgentTradeDetails, allowedSlippage, dependentField])

	const swapDetailFee = useMemo(() => {
		if (realizedLPFee) {
			return `${realizedLPFee.divide(6).toSignificant(4)} ${realizedLPFee.currency.symbol}`
		} else if (isCrossChain) {
			const amountToReceive = coinAgentTradeDetails[dependentField] ? coinAgentTradeDetails[dependentField] : 0
			return `${(amountToReceive * (0.3 / 100)).toFixed(4)} ${currencies[Field.OUTPUT]?.symbol}`
		}
		return '-'
	}, [realizedLPFee, isCrossChain, coinAgentTradeDetails, dependentField, currencies])

	const swapRate = useMemo(() => {
		if (trade) {
			return getTradePriceStr({ price: trade?.executionPrice })
		}

		if (isCrossChain) {
			return coinAgentTradeDetails.rate
		}

		return '-'
	}, [trade, isCrossChain, coinAgentTradeDetails])

	const getSwapFormDetailConfig = useCallback(() => {
		return {
			title: `${i18n._(t`Swap details`)}`,
			infoColumns: [
				{ title: `${i18n._(t`Rate`)}`, value: swapRate },
				{ title: `${i18n._(t`Slippage`)}`, value: `${allowedSlippage.toFixed(2)}%` },
				{ title: `${i18n._(t`Fee`)}`, value: swapDetailFee },
				{ title: `${i18n._(t`Min. token`)}`, value: swapDetailMinToken }
			]
		}
	}, [swapRate, allowedSlippage, swapDetailMinToken, swapDetailFee])

	const handleCurrency = useCallback(
		(currency, isInput: boolean) => {
			onCurrencySelection(isInput ? Field.INPUT : Field.OUTPUT, currency)
			setApprovalSubmitted(false) // reset 2 step UI for approvals
		},
		[onCurrencySelection]
	)

	const handleAcceptChanges = useCallback(() => {
		setSwapState({
			tradeToConfirm: trade,
			swapErrorMessage,
			txHash,
			attemptingTxn,
			showConfirm
		})
	}, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

	const handleConfirmDismiss = useCallback(() => {
		setSwapState({
			showConfirm: false,
			tradeToConfirm,
			attemptingTxn,
			swapErrorMessage,
			txHash
		})
		// if there was a tx hash, we want to clear the input
		if (txHash) {
			onUserInput(Field.INPUT, '')
		}
	}, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])


	const routeNotFoundAndSpecifiedInputOutput = useMemo(() =>
		(isCrossChain ? false : !trade?.route) && !!currencies[Field.INPUT] && !!currencies[Field.OUTPUT] && !!parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
		, [isCrossChain, trade, currencies, parsedAmounts, independentField])


	const textCTA = useMemo(() => {
		if (swapIsUnsupported) {
			return 'Unsupported Asset'
		}

		if (!account) {
			return 'ENTER DETAILS'
		}

		if (showWrap) {
			return wrapInputError ??
				(wrapType === WrapType.WRAP
					? 'Wrap'
					: wrapType === WrapType.UNWRAP
						? 'Unwrap'
						: null)
		}

		// if (routeNotFoundAndSpecifiedInputOutput) {
		//   <div style={{ textAlign: 'center' }}>
		//     <div className="mb-1">Insufficient liquidity for this trade</div>
		//     {singleHopOnly && <div className="mb-1">Try enabling multi-hop trades</div>}
		//   </div>
		// }

		if (showApproveFlow) {
			switch (approvalState) {
				case ApprovalState.APPROVED:
					return priceImpactSeverity > 3 && !isExpertMode
						? `Price Impact High`
						: priceImpactSeverity > 2
							? `Swap Anyway`
							: `Swap`

				case ApprovalState.PENDING:
					return <>Approving <Loader stroke="white"/></>

				default:
					return <>Approve {currencies[Field.INPUT]?.symbol}</>
			}
		}

		return swapInputError
			? swapInputError
			: priceImpactSeverity > 3 && !isExpertMode
				? `Price Impact Too High`
				: priceImpactSeverity > 2
					? `Swap Anyway`
					: `Swap`

	}, [swapIsUnsupported, account, showWrap, wrapInputError, wrapType, showApproveFlow, approvalState, isExpertMode, priceImpactSeverity, currencies, swapInputError])

	const disabledCTA = useMemo(() => {
		if (showWrap) {
			if (Boolean(wrapInputError)) {
				return true
			}
		} else {
			if (swapIsUnsupported || !account || routeNotFoundAndSpecifiedInputOutput) {
				return true
			}

			if (showApproveFlow) {
				return approvalState === ApprovalState.APPROVED
					? !isValid || (priceImpactSeverity > 3 && !isExpertMode)
					: approvalState !== ApprovalState.NOT_APPROVED || approvalSubmitted
			}

			return !isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError
		}
		return false

	}, [swapIsUnsupported, account, showWrap, wrapInputError, showApproveFlow,
		approvalState, priceImpactSeverity, isExpertMode, approvalSubmitted, isValid,
		swapCallbackError, routeNotFoundAndSpecifiedInputOutput])

	const errorCTA = useMemo(() => {
		if (showApproveFlow && approvalState === ApprovalState.APPROVED) {
			return isValid && priceImpactSeverity > 2
		}

		return isValid && priceImpactSeverity > 2 && !swapCallbackError
	}, [isValid, priceImpactSeverity, swapCallbackError])

	const clearValues = useCallback(() => {
		onCurrencySelection(Field.INPUT, {
			isToken: null,
			isNative: false
		} as Currency)
		onCurrencySelection(Field.OUTPUT, {
			isToken: null,
			isNative: true
		} as Currency)
		onUserInput(Field.INPUT, '')
		onUserInput(Field.OUTPUT, '')

		if (settingsModalRef.current) {
			// @ts-ignore
			settingsModalRef.current.clearValues();
		}
	}, [])

	useEffect(() => {
		!account && clearValues()
	}, [account, clearValues])

	useEffect(() => {
		clearValues()

		return () => {
			clearValues()
		}
	}, [clearValues])

	const handleCTA = useCallback(() => {
		if (swapIsUnsupported || !account) {
			// do nothing

		} else if (showWrap) {
			onWrap()

		} else if (showApproveFlow) {
			approvalState !== ApprovalState.APPROVED && approveCallback()

			if (approvalState === ApprovalState.APPROVED) {
				if (isExpertMode) {
					handleSwap()
				} else {
					setSwapState({
						tradeToConfirm: trade,
						attemptingTxn: false,
						swapErrorMessage: undefined,
						showConfirm: true,
						txHash: undefined
					})
				}
			}
		} else if (isCrossChain && formattedAmounts[Field.INPUT] > 0) {
			setSwapState({
				tradeToConfirm: trade,
				attemptingTxn: false,
				swapErrorMessage: undefined,
				showConfirm: true,
				txHash: undefined
			})
			crossChainSwapCallback({
				fromAmount: formattedAmounts[Field.INPUT],
				toAmount: formattedAmounts[Field.OUTPUT],
				fromAsset: currencies[Field.INPUT]?.symbol,
				toAsset: currencies[Field.OUTPUT]?.symbol
			}).then((result: any)=>{
				const {orderId} = result
				setSwapState({
					attemptingTxn: false,
					tradeToConfirm,
					showConfirm: true,
					swapErrorMessage: undefined,
					txHash: orderId
				})
			}).catch((error)=>{
				showToast(String(error), {
					type: ToastTypes.error,
					timeout: 7 * 1000
				})
				setSwapState({
					attemptingTxn: false,
					tradeToConfirm,
					showConfirm: false,
					swapErrorMessage: String(error),
					txHash: undefined
				})
			})
		} else {
			handleSwap()
		}
	}, [swapIsUnsupported, account, showWrap, onWrap, showApproveFlow, approvalState, approveCallback,
		isExpertMode, handleSwap, trade, isCrossChain, formattedAmounts])

	return (
		<>
			{isCrossChain ?
				<HodlConfirmationModal
					type={TransactionType.SWAP}
					chainId={chainId}
					hash={txHash}

					onDismiss={handleConfirmDismiss}
					submitted={attemptingTxn ? false : !!txHash}
					isOpen={showConfirm}
					token0Symbol={currencies[Field.INPUT]?.symbol}
					token1Symbol={currencies[Field.OUTPUT]?.symbol}

					token0Amount={String(formattedAmounts[Field.INPUT])}
					token1Amount={String(formattedAmounts[Field.OUTPUT])}
				/>
				: <ConfirmSwapModal
					isOpen={showConfirm}
					trade={trade}
					originalTrade={tradeToConfirm}
					onAcceptChanges={handleAcceptChanges}
					attemptingTxn={attemptingTxn}
					txHash={txHash}
					recipient={recipient}
					allowedSlippage={allowedSlippage}
					onConfirm={handleSwap}
					swapErrorMessage={swapErrorMessage}
					onDismiss={handleConfirmDismiss}
					minerBribe={doArcher ? archerETHTip : undefined}
				/>
			}
			<Modal isOpen={!showConfirm}
						 title={{
							 firstLine: `${i18n._(t`Swap`)}`,
							 secondLine: showSettings ? `${i18n._(t`Settings`)}` : `${i18n._(t`Tokens`)}`,
							 secondLineSize: Sizes.SMALL
						 }}
						 description={`${i18n._(t`The property for decentralized digital asset exchange in the HODLVERSE network.`)}`}
						 mainIconName={'SwapMeet'}
						 formDetailsConfig={getSwapFormDetailConfig()}
						 showFormDetailsModal={showFormDetailsModal}
						 onManualChangeVisFormDetails={setShowFormDetailsModal}
						 addMobileTopSpace={showSettings}
						 customBackAction={showSettings ? toggleSettings : undefined}>
				<div className={`h-full relative xs:h-500px`}>
					<div className={`${styles.body} ${showSettings ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
						<div className={`h-full flex flex-col`}>
							<div
								className={`w-full bg-gray-200 border-b-10 xs:border-b-4 border-gray-100 p-10px relative p-20px rounded-tr-60 
														pt-[80px] pr-20px pb-70px pl-33px xs:rounded-tr-16 xs:rounded-tl-16
														xs:pt-26px xs:pb-46px xs:px-3px`}>
								<TokenForm
									onMax={handleMaxInput}
									operationTitle={`${i18n._(t`Swap`)}`}
									selectedToken={currencies[Field.INPUT]}
									hideTokens={[currencies[Field.OUTPUT]]}
									onChangeToken={(cur) => handleCurrency(cur, true)}
									amount={formattedAmounts[Field.INPUT]}
									onChangeAmount={(val) => handleTypeAmount(true, val)}
									allowEditAmount={true}
									// readonly={approvalState === ApprovalState.APPROVED}/>
									readonly={false}/>
								<div
									className={`absolute bg-gray-200 rounded-full overflow-hidden flex items-center justify-center border-10 xs:border-4
									border-gray-100 z-20 cursor-pointer w-150px xs:w-70px h-112px xs:h-47px ${styles.swapForm}`}
									onClick={switchTokens}>
									<Image src={'/icons/arrowDown.svg'} width={size.width < responsiveSizes.xs ? 20 : 47} height={47}
												 alt={'Arrow'}/>
									<Image className={'rotate-180'} src={'/icons/arrowDown.svg'}
												 width={size.width < responsiveSizes.xs ? 20 : 47} height={47} alt={'Arrow'}/>
								</div>
							</div>
							<div className={`w-full relative z-10 pt-73px pr-20px pb-20px pl-33px xs:pt-39px xs:px-3px`}>
								<TokenForm
									operationTitle={`${i18n._(t`Receive`)}`}
									selectedToken={currencies[Field.OUTPUT]}
									hideTokens={[currencies[Field.INPUT]]}
									onChangeToken={(cur) => handleCurrency(cur, false)}
									amount={formattedAmounts[Field.OUTPUT]}
									onChangeAmount={(val) => handleTypeAmount(false, val)}
									allowEditAmount={false}
									isSecond
									// readonly={approvalState === ApprovalState.APPROVED}/>
									readonly={false}/>
							</div>
							<div
								className={'flex xs:flex-col justify-end xs:justify-center items-center mt-10px px-40px pt-15px xs:pt-5px'}>
								<div className={'cursor-pointer mr-93px flex items-center xs:hidden'}>
									{(approvalState !== ApprovalState.APPROVED && approvalState !== ApprovalState.PENDING) ? (
										<div className={'flex items-center'} onClick={toggleSettings} id={'swap-modal-settings-btn-id'}>
											<p className={'font-medium text-xl text-black tracking-normal mr-22px uppercase'}>{i18n._(t`Settings`)}</p>
											<Image src={'/icons/iconGear.svg'} width={33} height={33} alt={'Settings'}/>
										</div>
									) : (
										<div className={'flex items-center mr-22px'}>
											<p className={'uppercase text-xl text-green-350 font-medium tracking-normal mr-10px'}>Approved</p>
											<Image src={'/icons/iconSuccessDark.svg'} width={25} height={25} alt={'Success'}/>
										</div>
									)}
								</div>
								<div>
									{routeNotFoundAndSpecifiedInputOutput && !Boolean(showWrap) && (
										<div style={{ textAlign: 'center' }}>
											<div className="mb-1">Insufficient liquidity for this trade</div>
											{singleHopOnly && <div className="mb-1">Try enabling multi-hop trades</div>}
										</div>
									)}
									<Button disabled={disabledCTA} error={errorCTA} onClick={handleCTA}
													timerSec={!disabledCTA ? 30 : undefined}
													id={'swap-modal-action-btn-id'}
													onTimerEnd={() => setRefresh(!refresh)}>
										{textCTA}
									</Button>
									{/*{swapIsUnsupported ? (*/}
									{/*	<Button disabled>*/}
									{/*		Unsupported Asset*/}
									{/*	</Button>*/}
									{/*) : !account ? (*/}
									{/*	<Button disabled>ENTER DETAILS</Button>*/}
									{/*	// <Web3Connect />*/}
									{/*) : showWrap ? (*/}
									{/*	<Button disabled={Boolean(wrapInputError)} onClick={onWrap}>*/}
									{/*		{wrapInputError ??*/}
									{/*		(wrapType === WrapType.WRAP*/}
									{/*			? 'Wrap'*/}
									{/*			: wrapType === WrapType.UNWRAP*/}
									{/*				? 'Unwrap'*/}
									{/*				: null)}*/}
									{/*	</Button>*/}
									{/*) : routeNotFoundAndSpecifiedInputOutput ? (*/}
									{/*	<div style={{ textAlign: 'center' }}>*/}
									{/*		<div className="mb-1">Insufficient liquidity for this trade</div>*/}
									{/*		{singleHopOnly && <div className="mb-1">Try enabling multi-hop trades</div>}*/}
									{/*	</div>*/}
									{/*) : showApproveFlow ? (*/}
									{/*	<RowBetween>*/}
									{/*		{approvalState !== ApprovalState.APPROVED && (*/}
									{/*			<Button*/}
									{/*				onClick={() => approveCallback()}*/}
									{/*				disabled={approvalState !== ApprovalState.NOT_APPROVED || approvalSubmitted}*/}
									{/*			>*/}
									{/*				{approvalState === ApprovalState.PENDING ? (*/}
									{/*					<AutoRow gap="6px" justify="center">*/}
									{/*						Approving <Loader stroke="white"/>*/}
									{/*					</AutoRow>*/}
									{/*				) : (*/}
									{/*					`Approve ${currencies[Field.INPUT]?.symbol}`*/}
									{/*				)}*/}
									{/*			</Button>*/}
									{/*		)}*/}
									{/*		{approvalState === ApprovalState.APPROVED && (*/}
									{/*			<Button*/}
									{/*				onClick={() => {*/}
									{/*					if (isExpertMode) {*/}
									{/*						handleSwap()*/}
									{/*					} else {*/}
									{/*						setSwapState({*/}
									{/*							tradeToConfirm: trade,*/}
									{/*							attemptingTxn: false,*/}
									{/*							swapErrorMessage: undefined,*/}
									{/*							showConfirm: true,*/}
									{/*							txHash: undefined*/}
									{/*						})*/}
									{/*					}*/}
									{/*				}}*/}
									{/*				disabled={*/}
									{/*					!isValid || approvalState !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)*/}
									{/*				}*/}
									{/*				error={isValid && priceImpactSeverity > 2}*/}
									{/*			>*/}
									{/*				{priceImpactSeverity > 3 && !isExpertMode*/}
									{/*					? `Price Impact High`*/}
									{/*					: priceImpactSeverity > 2*/}
									{/*						? `Swap Anyway`*/}
									{/*						: `Swap`}*/}
									{/*			</Button>*/}
									{/*		)}*/}
									{/*	</RowBetween>*/}
									{/*) : (*/}
									{/*	<Button*/}
									{/*		onClick={() => {*/}
									{/*			if (isCrossChain && formattedAmounts[Field.INPUT] > 0) {*/}
									{/*				setSwapState({*/}
									{/*					tradeToConfirm: trade,*/}
									{/*					attemptingTxn: false,*/}
									{/*					swapErrorMessage: undefined,*/}
									{/*					showConfirm: true,*/}
									{/*					txHash: undefined*/}
									{/*				})*/}
									{/*			} else {*/}
									{/*				handleSwap()*/}
									{/*			}*/}
									{/*		}}*/}
									{/*		disabled={(!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError)}*/}
									{/*		error={(isValid && priceImpactSeverity > 2 && !swapCallbackError)}*/}
									{/*	>*/}
									{/*		{swapInputError*/}
									{/*			? swapInputError*/}
									{/*			: priceImpactSeverity > 3 && !isExpertMode*/}
									{/*				? `Price Impact Too High`*/}
									{/*				: priceImpactSeverity > 2*/}
									{/*					? `Swap Anyway`*/}
									{/*					: `Swap`}*/}
									{/*	</Button>*/}
									{/*)}*/}
									{showApproveFlow && (
										<Column style={{ marginTop: '1rem', display: 'none' }}>
											<ProgressSteps steps={[approvalState === ApprovalState.APPROVED]}/>
										</Column>
									)}
									{isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage}/> : null}
								</div>
								<div className={'hidden xs:flex items-center justify-center mt-17px'}>
									<p className={'uppercase text-sm font-bold text-gray-400 mr-8px'}
										 id={'swap-modal-details-btn-mobile-id'}
										 onClick={() => setShowFormDetailsModal(!showFormDetailsModal)}>
										Details
									</p>
									<p className={'uppercase text-sm font-bold text-gray-400 ml-8px'}
										 id={'swap-modal-settings-btn-mobile-id'}
										 onClick={toggleSettings}>
										{i18n._(t`Settings`)}
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className={`${styles.body} ${!showSettings ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
						<SettingsModal toggleSettings={toggleSettings} ref={settingsModalRef} />
					</div>
				</div>
			</Modal>
		</>
	)
}

export default SwapTokensModal
