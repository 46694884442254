/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TokenList } from '@uniswap/token-lists'
import { FixedSizeList } from 'react-window'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import ManageButton from 'components/TokenDropdown/manageButton'
import NoResults from 'components/TokenDropdown/NoResults'
import ListRow from './ListRow'
import Input from './Input'

import { useActiveListUrls, useAllLists } from 'state/lists/hooks'

import { useFetchListCallback } from 'hooks/useFetchListCallback'
import { useWindowSize } from 'hooks/useWindowSize'

import { uriToHttp } from 'functions/convert'
import { parseENSAddress, shortenStringAsAddress } from 'functions'

import { UNSUPPORTED_LIST_URLS } from 'constants/token-lists'
import { responsiveSizes } from 'constants/sizes'

import styles from './tokenDropdown.module.scss'

interface ManageListProps {
	onManageTokens: () => void;
	isSecond?: boolean;
}

const ManageList: React.FC<ManageListProps> = ({ onManageTokens, isSecond }) => {
	const windowSize = useWindowSize()
	const { i18n } = useLingui()

	const [listUrlInput, setListUrlInput] = useState<string>('')
	const [shortAddress, setShortAddress] = useState(listUrlInput)
	const [focusedAddressInput, setFocusedAddressInput] = useState(false)
	const [activeCopy, setActiveCopy] = useState<string[] | undefined>()
	const [tempList, setTempList] = useState<TokenList>()
	const [addError, setAddError] = useState<string | undefined>()

	const lists = useAllLists()
	const activeListUrls = useActiveListUrls()
	const fetchList = useFetchListCallback()
	const listName = listUrlInput

	const validUrl: boolean = useMemo(() => {
		return uriToHttp(listUrlInput).length > 0 || Boolean(parseENSAddress(listUrlInput))
	}, [listUrlInput])

	const sortedLists = useMemo(() => {
		return Object.keys(lists)
			.filter(listUrl => Boolean(lists[listUrl].current)
				&& !UNSUPPORTED_LIST_URLS.includes(listUrl)
				&& (listName !== '' ? lists[listUrl].current.name.toLowerCase().includes(listName.toLowerCase()) : lists))
			.sort((u1, u2) => {
				const { current: l1 } = lists[u1]
				const { current: l2 } = lists[u2]

				// first filter on active lists
				if (activeCopy?.includes(u1) && !activeCopy?.includes(u2)) {
					return -1
				}
				if (!activeCopy?.includes(u1) && activeCopy?.includes(u2)) {
					return 1
				}

				if (l1 && l2) {
					return l1.name.toLowerCase() < l2.name.toLowerCase()
						? -1
						: l1.name.toLowerCase() === l2.name.toLowerCase()
							? 0
							: 1
				}
				if (l1) return -1
				if (l2) return 1
				return 0
			})
	}, [lists, activeCopy, listName])

	useEffect(() => {
		async function fetchTempList() {
			fetchList(listUrlInput, false)
				.then((list) => setTempList(list))
				.catch(() => setAddError('Error importing list'))
		}

		// if valid url, fetch details for card
		if (validUrl) {
			fetchTempList()
		} else {
			setTempList(undefined)
			listUrlInput !== '' && setAddError('Enter valid list location')
		}

		// reset error
		if (listUrlInput === '') {
			setAddError(undefined)
		}
		setShortAddress(shortenStringAsAddress(listUrlInput))
	}, [fetchList, listUrlInput, validUrl])

	useEffect(() => {
		!activeCopy && activeListUrls && setActiveCopy(activeListUrls);
	}, [activeCopy, activeListUrls])

	const Row = useCallback(
		({ data, index, style }) => {
			const listUrl = data[index]
			return <ListRow key={listUrl} listUrl={listUrl} style={style}/>
		},
		[]
	)

	return (
		<div className={styles.formWrapper} id={`manage-list-dropdown-${isSecond ? 'second' : 'first'}-id`}>
			<div className={styles.formSearchWrapper}>
				<Input value={focusedAddressInput ? listUrlInput : shortAddress}
							 onChange={setListUrlInput}
							 placeholder={focusedAddressInput ? '' : `${i18n._(t`Enter https:// or ipfs:// or ENS name`)}...`}
							 id={`manage-list-dropdown-input-${isSecond ? 'second' : 'first'}-id`}
							 onFocus={() => setFocusedAddressInput(true)}
							 onBlur={() => setFocusedAddressInput(false)}/>
			</div>
			<div className={`px-35px xs:pl-17px xs:pr-11px mt-24px xs:mt-5px flex flex-col overflow-y-scroll h-160px`}>
				{sortedLists.length ?
					<FixedSizeList
						width={'100%'}
						height={windowSize.width < responsiveSizes.xs ? 140 : 160}
						itemData={sortedLists}
						itemCount={sortedLists.length}
						itemSize={70}
					>
						{Row}
					</FixedSizeList>
					: <NoResults/>}
			</div>
			<ManageButton text={`${i18n._(t`Manage tokens`)}`} handleClick={onManageTokens} wrapClassname={'xs:mt-7px'}/>
		</div>
	)
}

export default ManageList
