import { useCallback, useState } from 'react'

interface ModalMenuInputItemProps {
	placeholder?: string;
	value?: string;
	onChange?: (val: string) => void;
	disabled?: boolean;
}

const ModalMenuInputItem: React.FC<ModalMenuInputItemProps> = ({ placeholder, value, onChange, disabled }) => {
	const [val, setVal] = useState(value)

	const handleChange = useCallback((e) => {
		const val = e.target.value
		setVal(val)
		onChange && onChange(val)
	}, [onChange])

	return (
		<div
			className={`border-white w-full rounded-full flex items-center cursor-pointer text-gray-400 ${disabled ? 'bg-gray-250 border-0' : 'bg-gray-220 bg-opacity-70 border-3'} shadow-custom-4 pt-17px pb-15px px-44px`}>
			<input
				className={'text-xl font-medium overflow-ellipsis overflow-hidden w-full whitespace-nowrap bg-transparent placeholder-gray-700'}
				type="text"
				value={val}
				placeholder={placeholder || ''}
				onChange={handleChange}/>
		</div>
	)
}

export default ModalMenuInputItem
