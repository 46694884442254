import { useCallback } from 'react'

import { getTokenIconFromName } from 'analytics/core/format'

import { formatPercent } from 'functions'

import { FarmModel } from 'types/FarmModel'

import PoolFarmItem from 'components/PoolFarmItem/template'

interface FarmItemProps {
	item: FarmModel;
	onSelect?: () => void;
}

const FarmItem: React.FC<FarmItemProps> = ({ item, onSelect }) => {
	const handleSelect = useCallback(() => {
		onSelect && onSelect()
	}, [onSelect])

	const token0ImageUrl = getTokenIconFromName({ symbol: item?.pair?.token0?.symbol })
	const token1ImageUrl = getTokenIconFromName({ symbol: item?.pair?.token1?.symbol })

	return (
		<PoolFarmItem
			token0={{
				src: token0ImageUrl,
				alt: 'Token',
				symbol: item?.pair?.token0?.symbol.replace("WETH", "ETH")
			}}
			token1={{
				src: token1ImageUrl,
				alt: 'Token',
				symbol: item?.pair?.token1?.symbol.replace("WETH", "ETH")
			}}
			extra={formatPercent(item?.roiPerYear * 100)}
			onSelect={handleSelect}
		/>
	)
}

export default FarmItem
