import React, {useCallback, useState} from "react";
import Select, { components } from "react-select";
import Image from "next/image";

import { useWindowSize } from 'hooks/useWindowSize'

import { responsiveSizes } from 'constants/sizes'

const SingleValue = ({ ...props }) => {
  const { width: screenWidth } = useWindowSize()

  return (
    <components.SingleValue {...props}>
      <div className={'flex items-center pl-9px'}>
        <Image src={props.data.icon} alt={props.data.symbol}
               width={screenWidth < responsiveSizes.xs ? 20 : 26}
               height={screenWidth < responsiveSizes.xs ? 20 : 26} />
        <p className={'uppercase pl-9px xs:pl-5px xs:text-xs'}>{props.data.name}</p>
      </div>
    </components.SingleValue>
  );
};

const Option = props => {
  const { width: screenWidth } = useWindowSize()

  return (
    <components.Option {...props}>
      <div className={'flex items-center'}>
        <Image src={props.data.icon} alt={props.data.symbol}
               width={screenWidth < responsiveSizes.xs ? 20 : 26}
               height={screenWidth < responsiveSizes.xs ? 20 : 26} />
        <p className={'uppercase text-gray-400 pl-10px xs:text-xs'}>{props.data.name}</p>
      </div>
    </components.Option>
  );
};

const { ValueContainer } = components;

const CustomValueContainer = ({ children, ...props }) => {
  const commonProps = {
    cx: props.cx,
    clearValue: props.clearValue,
    getStyles: props.getStyles,
    getValue: props.getValue,
    hasValue: props.hasValue,
    isMulti: props.isMulti,
    isRtl: props.isRtl,
    options: props.options,
    selectOption: props.selectOption,
    setValue: props.setValue,
    selectProps: props.selectProps,
    theme: props.theme
  };

  const value = props?.getValue()[0];

  return (
    <ValueContainer {...props}>
      {React.Children.map(children, child => {
        return child ? (
          child
        ) : props.hasValue ? (
          <SingleValue
            {...commonProps}
            isFocused={props.selectProps.isFocused}
            isDisabled={props.selectProps.isDisabled}
            data={value}
          />
        ) : (
          <components.Placeholder
            {...commonProps}
            key="placeholder"
            isDisabled={props.selectProps.isDisabled}
            data={props.getValue()}
          >
            <p>{props.selectProps.placeholder}</p>
          </components.Placeholder>
        );
      })}
    </ValueContainer>
  );
};

interface NetworkModel {
  icon: string;
  name: string;
  val: string;
}

const networkList: NetworkModel[] = [
  { name: 'Ethereum', icon: '/icons/assets/eth.svg', val: 'ethereum' },
  // { name: 'Binance Smart Chain', icon: '/icons/assets/eth.svg', val: 'binanceSmartChain' }
]

interface NetworkListDropdownProps {
  placeholder?: string;
  value?: NetworkModel;
  onChange?: (val: NetworkModel) => void;
  menuIsOpen?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  options?: NetworkModel[];
}

const NetworkListDropdown: React.FC<NetworkListDropdownProps> = ({
                                        placeholder,
                                        value,
                                        onChange,
                                        menuIsOpen,
                                        onMenuOpen,
                                        onMenuClose,
                                        options,
                                        ...rest
                                      }): JSX.Element => {
  const [activeNetwork, setActiveNetwork] = useState<NetworkModel>(value || networkList[0])

  const handleChange = React.useCallback(
    (e: NetworkModel) => {
      setActiveNetwork(e);
      onChange && onChange(e);
    },
    [onChange]
  );

  const handleOptionSelected = useCallback((option) => activeNetwork?.val === option.val, [activeNetwork]);

  return (
    <div className={'token-list-dropdown'}>
      <Select
        className="dropdown"
        classNamePrefix="dropdown"
        options={options || networkList}
        isOptionSelected={(option, value) => handleOptionSelected(option)}
        value={activeNetwork}
        onChange={value => handleChange(value)}
        placeholder={placeholder}
        components={{ SingleValue, Option, ValueContainer: CustomValueContainer }}
        isSearchable={false}
        menuIsOpen={menuIsOpen}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
      />
    </div>
  );
};

export default NetworkListDropdown;