import { memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { useMoralis, useMoralisQuery, useNewMoralisObject } from 'react-moralis'

import { Menu, Popups, LanguageNetworkSwitcher, CameraViewSwitcher } from 'components'

import styles from './default.module.scss'
import useMultiplayer from 'state/multiplayer/hooks'
import { useActiveWeb3React } from 'hooks/useActiveWeb3React'
import { safelyParseJSON } from '../../crosschainswap/ConfirmCrossChainSwapModal'

interface LayoutProps {
	blurBg?: boolean;
	children: ReactNode;
}

// eslint-disable-next-line react/display-name
const Layout: React.FC<LayoutProps> = memo(({ children, blurBg }) => {
	const router = useRouter()

	const { startMultiplayer, setConnected, setUnityReady, isUnityReady } = useMultiplayer()

	const { isAuthenticated: moralisIsAuthenticated, user: moralisUser } = useMoralis();

	const { save, error: updateMoralisStoreError } = useNewMoralisObject('UserUnityStore');

	const moralisUsername = useMemo(() => moralisUser?.getUsername ? moralisUser.getUsername() : "", [moralisUser])

	const { fetch, data: unityStoreData, error: fetchMoralisDataError } =
		useMoralisQuery("UserUnityStore", query =>
				query
					.equalTo("userId", moralisUsername)
					.descending('updatedAt')
					.limit(1)
			, [moralisUsername]);

	const { chainId } = useActiveWeb3React()

	const [unityLoadProgress, setUnityLoadProgress] = useState(0)
	const [unityLoadAssetsProgress, setUnityLoadAssetsProgress] = useState(0)

	useEffect(() => {
		const unityLoading = (data: { progress: number, unityInstance: any }) => {
			const {
				progress
			} = data

			const correctProgress = Math.round(progress * 100 || 0)

			if (correctProgress < 100) {
				setUnityLoadProgress(correctProgress)
			} else {
				setTimeout(() => {
					setUnityLoadProgress(100)

					// for test purposes
					// debugger
					// updateMoralisUnityStore({detail: {newTestObject: {test: 13}}})
					// setTimeout(() => {
					// 	debugger
					// 	fetch();
					// }, 2500)
				}, 2500)
			}
		}

		const unityLoaderProgress = (e) => {
			unityLoading((e as any).detail)
		}

		const unityAssetsLoaderProgress = (e) => {
			const percent = (e as any).detail?.percent;
			if (!isNaN(percent)) {
				if (percent < 100) {
					setUnityLoadAssetsProgress(percent)
				} else {
					setTimeout(() => {
						setUnityLoadAssetsProgress(100)
					}, 1500)
				}
			}
		}

		const openModal = (e) => {
			const modalName = (e as any).detail.modalName

			if (modalName === 'AIRPORT') {
				startMultiplayer();
				router.push('/airport')
			}

			if (modalName === 'DATA_CENTER') {
				// not ready yet
			}

			if (modalName === 'COMMUNITY_POOL') {
				router.push('/pool')
			}

			if (modalName === 'SWAP_MEET') {
				router.push('/swapMeet')
			}

			if (modalName === 'USER_HOME') {
				router.push('/home')
			}

			if (modalName === 'COMMUNITY_FARM') {
				router.push('/farm')
			}
		}

		const onUnityReady = () => {
			setUnityReady(true);
		}

		window.addEventListener('HODL_VALLEY__UNITY_LOADER_PROGRESS', unityLoaderProgress, false)

		window.addEventListener('HODL_VALLEY__OPEN_MODAL', openModal, false)

		window.addEventListener('HODL_VALLEY__UNITY_GAME_STARTED', onUnityReady, false)

		window.addEventListener('HODL_VALLEY__UNITY_LOADING_CITY_PERCENT', unityAssetsLoaderProgress, false)

		return () => {
			window.removeEventListener('HODL_VALLEY__UNITY_LOADER_PROGRESS', unityLoaderProgress);
			window.removeEventListener('HODL_VALLEY__OPEN_MODAL', openModal)
			window.removeEventListener('HODL_VALLEY__UNITY_GAME_STARTED', onUnityReady)
			window.removeEventListener('HODL_VALLEY__UNITY_LOADING_CITY_PERCENT', unityAssetsLoaderProgress);
		}
	}, [router, setUnityReady, startMultiplayer])

	useEffect(() => {
		const fetchMoralisUnityStore = () => {
			fetch();
		}

		window.addEventListener('HODL_VALLEY__FETCH_MORALIS_UNITY_STORE', fetchMoralisUnityStore, false)

		return () => {
			window.removeEventListener('HODL_VALLEY__FETCH_MORALIS_UNITY_STORE', fetchMoralisUnityStore)
		}
	}, [fetch])

	useEffect(() => {
		const updateMoralisUnityStore = (e) => {
			if (e.detail) {
				const updatedObject = unityStoreData?.length ? {...unityStoreData[0]?.attributes} : {
					userId: moralisUsername,
					props: {}
				}
				updatedObject.props = typeof e.detail === "string" ? safelyParseJSON(e.detail) : e.detail;
				save(updatedObject)
			}
		}

		window.addEventListener('HODL_VALLEY__UPDATE_MORALIS_UNITY_STORE', updateMoralisUnityStore, false)

		return () => {
			window.removeEventListener('HODL_VALLEY__UPDATE_MORALIS_UNITY_STORE', updateMoralisUnityStore)
		}
	}, [unityStoreData, save, moralisUsername])

	useEffect(() => {
		const connectedEvent = () => {
			setConnected()
		}

		window.addEventListener('HODL_VALLEY__PHOTON_JOINED_LOBBY', connectedEvent, false)

		return () => {
			window.removeEventListener('HODL_VALLEY__PHOTON_JOINED_LOBBY', connectedEvent)
		}
	}, [setConnected])

	useEffect(() => {
		if (moralisIsAuthenticated && isUnityReady) {
			const data = unityStoreData?.length && unityStoreData[0]?.attributes?.props ? unityStoreData[0]?.attributes?.props : {};
			window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_MORALIS_UNITY_STORE_UPDATED', { detail: data }) as Event)
		}
	}, [unityStoreData, moralisIsAuthenticated, isUnityReady])

	useEffect(() => {
		if (moralisIsAuthenticated && isUnityReady) {
			window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_MORALIS_UNITY_STORE_FETCH_ERROR', { detail: fetchMoralisDataError }) as Event)
		}
	}, [moralisIsAuthenticated, fetchMoralisDataError, isUnityReady])

	useEffect(() => {
		if (moralisIsAuthenticated && isUnityReady) {
			window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_MORALIS_UNITY_STORE_UPDATE_ERROR', { detail: updateMoralisStoreError }) as Event)
		}
	}, [moralisIsAuthenticated, updateMoralisStoreError, isUnityReady])

	useEffect(() => {
		if (isUnityReady) {
			window.dispatchEvent(new CustomEvent('HODL_VALLEY__UNITY_NETWORK_SWITCHED', { detail: { chainId } }) as Event)
		}
	}, [chainId, isUnityReady])

	return (
		<div
			className={`z-0 flex flex-col items-center w-full min-h-screen overflow-x-hidden overflow-y-auto bg-valley-pattern bg-no-repeat bg-cover bg-center relative`}>
			<div
				className={`fixed overflow-hidden w-full h-full z-40 bg-valley-loading-pattern bg-no-repeat bg-cover bg-center flex flex-col items-center justify-center opacity-1 pointer-events-none ${unityLoadAssetsProgress >= 100 && unityLoadProgress >= 100 && 'opacity-0 invisible pointer-events-auto'}`}>
				<Image src={'/images/HODLValleyRibbonLogo.webp'} alt="HODLVERSE" width={650} height={536}/>
				<div className={`p-8px ${styles.loader}`}>
					<div className={`${styles.loaderForm} w-full h-full relative flex items-center pl-40px overflow-hidden`}>
						<div className={`${styles.loaderActiveProgress} h-full absolute top-0 left-0`}
								 style={{ width: `calc(${unityLoadAssetsProgress || unityLoadProgress}% - 4px)` }}></div>
						<p
							className={'text-2xl uppercase font-medium text-white text-center relative z-10 tracking-normal w-full'}>loading{!!unityLoadAssetsProgress && " city"} {unityLoadAssetsProgress || unityLoadProgress}%...</p>
					</div>
				</div>
			</div>
			<div className={`absolute overflow-visible z-50 xs:hidden ${styles.logoWrapper}`}
					 onClick={() => router.push('/')}>
				<Image src={'/images/HODL-Verse-Logo.png'} alt="HODLVERSE" width={280} height={42}/>
			</div>
			<div className={`absolute z-50 top-33px right-47px xs:fixed xs:top-0 xs:left-0 xs:w-full flex`}>
				<div className={'mr-20px xs:hidden'}>
					<CameraViewSwitcher />
				</div>
				<div className={'mr-20px xs:hidden'}>
					<LanguageNetworkSwitcher />
				</div>
				<Menu/>
			</div>
			<Popups/>
			<div
				className={`fixed w-full h-full top-0 left-0 bg-transparent transition-opacity duration-500 ${unityLoadProgress === 100 ? 'opacity-1 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
				<iframe src="/hodlUnityNewVersion/index.html" frameBorder="0" className={'w-full h-full'}/>
			</div>
			<main
				className={`w-full min-h-screen max-h-screen overflow-y-scroll pb-50px xs:pb-0 pt-100px xs:pt-68px xs:max-h-max ${unityLoadAssetsProgress >= 100 && unityLoadProgress >= 100 ? '' : 'z-40'} ${blurBg ? 'blurWrapper' : ''}`}>
				{/*<main className={`w-full min-h-screen pb-50px ${blurBg ? 'blurWrapper' : ''} ${styles.contentWrapper}`}>*/}
				{children}
			</main>
		</div>
	)
})

export default Layout
