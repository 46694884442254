import Image from 'next/image'
import { useWindowSize } from 'hooks/useWindowSize'

import { responsiveSizes } from 'constants/sizes'

import styles from './poolFarmItem.module.scss'

interface TokenInfo {
  src: string;
  alt: string;
  symbol: string;
}

interface PoolFarmItemProps {
  token0: TokenInfo;
  token1: TokenInfo;
  onSelect: () => void;
  extra?: string;
}

const PoolFarmItem: React.FC<PoolFarmItemProps> = ({ token0, token1, onSelect, extra }) => {
  const { width: screenWidth } = useWindowSize()

  return (
    <div
      id={`pool-item-${token0.symbol}-${token1.symbol}-id`}
      className={`bg-gray-50 h-66px xs:h-52px border-3 border-bg-white rounded-full flex items-center xs:justify-between cursor-pointer 
			${styles.btn} transition-opacity text-opacity-60 text-gray-400 hover:text-opacity-100 hover:bg-white`}
      onClick={onSelect}>
      <div className={'flex items-center'}>
        <div>
          <Image src={token0.src} alt={token0.alt}
                 width={screenWidth < responsiveSizes.xs ? 21 : 25}
                 height={screenWidth < responsiveSizes.xs ? 21 : 25} />
        </div>
        <div className={'ml-10px xs:ml-2px'}>
          <Image src={token1.src} alt={token1.alt}
                 width={screenWidth < responsiveSizes.xs ? 21 : 25}
                 height={screenWidth < responsiveSizes.xs ? 21 : 25} />
        </div>
        <p className={'ml-14px xs:ml-5px uppercase font-medium text-xl xs:text-base text-gray-400 text-opacity-60'}>
          {token0.symbol} / {token1.symbol}</p>
      </div>
      <p className={'ml-22px xs:ml-0 font-medium text-xl xs:text-base text-blue-400'}>
        {extra || "-"}
      </p>
    </div>
  )
}

export default PoolFarmItem
