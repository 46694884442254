import React from 'react'
import Image from 'next/image'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { FixedSizeList } from 'react-window'

import { useWindowSize } from 'hooks/useWindowSize'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

import { useWallet } from 'state/wallet/hooks'

import { responsiveSizes } from 'constants/sizes'

import styles from './searchPoolFarm.module.scss'

interface SearchPoolOrFarmModalProps {
	searchVal?: string;
	setSearchVal?: (val: string) => void;
	elements: any[];
	renderRow: any;
}

const SearchPoolOrFarmTemplate: React.FC<SearchPoolOrFarmModalProps> = ({ searchVal, setSearchVal, elements, renderRow }) => {
	const { width: screenWidth } = useWindowSize()
	const { notifyNotConnectedWallet } = useWallet()
	const { account } = useActiveWeb3React()

	return (
		<div className={`h-full flex flex-col xs:h-480px ${styles.form}`}>
			<div className={'px-10px'}>
				<div
					className={`w-full h-66px xs:h-52px bg-gray-220 border-3 border-white bg-opacity-60 border-opacity-60 rounded-full 
						flex justify-between items-center pl-43px xs:pl-22px pr-26px xs:pr-18px pt-2px ${styles.searchWrapper}`}
					onClick={() => {
						!account && notifyNotConnectedWallet()
					}}>
					<input
						className={'bg-transparent font-medium text-xl xs:text-base text-gray-700 placeholder-gray-700 xs:tracking-normal xs:w-10/12'}
						placeholder={'SEARCH...'}
						type="text"
						value={searchVal}
						disabled={!account}
						id={'search-farm-pool-input-id'}
						onChange={(e) => setSearchVal(e.target.value)}/>
					<div className={'mt-5px xs:mt-4px'}>
						<Image src={'/icons/iconMagnifier.svg'} alt={'Search'}
									 width={screenWidth < responsiveSizes.xs ? 21 : 25}
									 height={screenWidth < responsiveSizes.xs ? 21 : 25}/>
					</div>
				</div>
			</div>
			<div className={`mt-20px xs:mt-15px ${styles.listWrapper}`}>
				<FixedSizeList
					outerElementType={(props) => <PerfectScrollbar {...props} options={{ suppressScrollX: true }}/>}
					height={screenWidth < responsiveSizes.xs ? 378 : 408}
					itemData={elements}
					itemCount={elements.length}
					itemSize={screenWidth < responsiveSizes.xs ? 67 : 86}
					width={'100%'}
				>
					{renderRow}
				</FixedSizeList>
			</div>
		</div>
	)
}

export default SearchPoolOrFarmTemplate
