/* eslint-disable jsx-a11y/alt-text */
import { useCallback, useMemo, useState } from 'react'
import Image from 'next/image'
import { Currency, Token } from '@hodlvalley/sdk'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { Sizes } from 'types/Sizes'
import { TokenModel } from 'types/TokenModel'

import AddToken from './addToken'
import SelectTokens from './selectTokens'
import ManageTokens from './manageTokens'
import ManageList from './manageList'

import { useWallet } from 'state/wallet/hooks'

import { Input as NumericalInput } from '../NumericalInput'

import { useWindowSize } from 'hooks/useWindowSize'

import { responsiveSizes } from 'constants/sizes'

import styles from './tokenDropdown.module.scss'

export enum TokenDropdownTypes {
	TOKEN,
	INPUT_STEPPER
}

interface TokenDropdownProps {
	selectedToken?: any;
	operation?: string;
	onSelectToken?: (token: TokenModel) => void;
	amount?: string;
	onChangeAmount?: (amount: string) => void;
	allowEditAmount?: boolean;
	readonly: boolean;
	size?: Sizes;
	suffix?: string;
	type?: TokenDropdownTypes;
	disabled: boolean;
	hideInput?: boolean;
	autosizeInput?: boolean;
	hideTokens?: Currency[];
	isSecond?: boolean;
}

const TokenDropdown: React.FC<TokenDropdownProps> = ({
																											 selectedToken,
																											 operation,
																											 onSelectToken,
																											 amount,
																											 onChangeAmount,
																											 allowEditAmount = false,
																											 readonly,
																											 suffix = '',
																											 disabled,
																											 hideInput = false,
																											 type = TokenDropdownTypes.TOKEN,
																											 size = Sizes.MEDIUM,
																											 autosizeInput = false,
																											 hideTokens = [],
																											 isSecond = false
																										 }) => {
	const { notifyNotConnectedWallet } = useWallet()
	const windowSize = useWindowSize()
	const [expand, setExpand] = useState(false)
	const [showAddForm, setShowAddForm] = useState(false)
	const [showManageTokenForm, setShowManageTokenForm] = useState(false)
	const [showManageListForm, setShowManageListForm] = useState(false)
	const [addableToken, setAddableToken] = useState<Token>()
	const { i18n } = useLingui()

	const toggle = useCallback(() => {
		if (!disabled) {
			if (expand) {
				setShowAddForm(false)
				setShowManageTokenForm(false)
				setShowManageListForm(false)
			}

			setExpand(!expand)
		} else {
			notifyNotConnectedWallet()
		}
	}, [expand, disabled, notifyNotConnectedWallet])

	const showBack = useMemo(() => {
		return showAddForm || showManageTokenForm || showManageListForm
	}, [showAddForm, showManageTokenForm, showManageListForm])

	const title = useMemo(() => {
		return showAddForm ? 'Add Token' : showManageTokenForm ? `${i18n._(t`Manage tokens`)}` : showManageListForm ? `${i18n._(t`Manage lists`)}` : `${i18n._(t`Select Token`)}`
	}, [showAddForm, showManageTokenForm, showManageListForm, i18n])

	const toggleAddForm = useCallback((token?: TokenModel) => {
		if (!showAddForm && token) {
			// setAddableToken(token);
		}
		setShowAddForm(!showAddForm)
	}, [showAddForm])

	const goBack = useCallback(() => {
		if (showAddForm) {
			setShowAddForm(false)
		}

		if (showManageTokenForm) {
			setShowManageTokenForm(false)
		}

		if (showManageListForm) {
			setShowManageListForm(false)
		}
	}, [showAddForm, showManageTokenForm, showManageListForm])

	const handleChangeAmount = useCallback((val: string): void => {
		onChangeAmount && onChangeAmount(val)
	}, [onChangeAmount])

	const handleSelectToken = useCallback((token: TokenModel): void => {
		onSelectToken && onSelectToken(token)
		setExpand(false)
	}, [onSelectToken])

	const increaseAmount = useCallback((val: number): void => {
		if (!isNaN(Number(amount))) {
			handleChangeAmount(`${Number(amount) + val}`)
		} else {
			handleChangeAmount(`0`)
		}
	}, [amount, handleChangeAmount])

	return (
		<div className={`relative z-20 ${styles.formWrapper} 
    ${type === TokenDropdownTypes.TOKEN ? (selectedToken ? (readonly ? 'w-280px' : 'w-304px') : 'w-401px') : ''} 
    ${size === Sizes.SMALL ? 'w-240px' : ''} xs:w-full h-100px xs:h-52px`}>
			<div className={`border-white w-full absolute top-0 right-0 overflow-hidden rounded-50 xs:rounded-30 xs:w-full
      ${styles.form} ${expand ? 'w-401px h-464px max-h-464px xs:h-323px' : 'h-100px max-h-100px xs:h-52px'} 
      ${readonly ? 'bg-transparent border-0' : `border-3 bg-gray-220 ${styles.formShadowed}`} 
      ${disabled ? 'cursor-not-allowed' : ''}`} id={`token-dropdown-wrapper-${isSecond ? 'second' : 'first'}-id`}
					 onClick={() => !expand && type === TokenDropdownTypes.TOKEN && !selectedToken && toggle()}>
				<div
					className={`relative flex items-center justify-center w-full px-14px cursor-pointer h-94px xs:h-50px xs:px-23px xs:justify-start`}>
					{(showBack && !readonly) &&
					<div className={`absolute h-full flex items-center pt-15px xs:pt-7px left-44px xs:left-25px`}
							 id={`token-dropdown-arrow-back-btn-${isSecond ? 'second' : 'first'}-id`}
							 onClick={goBack}>
						<Image src={'/icons/arrowLeftTiny.svg'} width={windowSize.width < responsiveSizes.xs ? 7 : 12} height={20}/>
					</div>
					}
					{selectedToken || type === TokenDropdownTypes.INPUT_STEPPER ? (
						<div className={`flex flex-col items-end w-full xs:flex-row xs:items-center 
              ${readonly ? 'pr-31px pt-13px' : 'pr-81px pt-6px'} ${showBack ? 'xs:pl-24px xs:pt-8px' : 'xs:pt-0'}`}>
							{operation &&
							<p
								className={'font-medium text-xl text-gray-400 uppercase text-opacity-60 tracking-normal xs:text-base'}>{operation}</p>
							}
							{!hideInput &&
							<div className={'flex items-baseline justify-end'}>
								<NumericalInput
									className={`font-medium text-gray-400 text-right -mt-7px tracking-normal 
                    bg-transparent max-w-140px xs:text-left xs:text-base xs:mt-0 xs:pl-8px xs:max-w-110px`}
									readOnly={!allowEditAmount || readonly}
									type="text"
									value={amount || ''}
									inputMode="decimal"
									autoComplete="off"
									autoCorrect="off"
									pattern="^[0-9]*[.,]?[0-9]*$"
									placeholder={'0'}
									onUserInput={(val) => {
										handleChangeAmount(val)
									}}
                  autosize={autosizeInput}
									id={`${operation}-numerical-input-${isSecond ? 'second' : 'first'}-id`}
								/>
								{suffix &&
								<span className={'font-medium text-34px xs:text-base text-gray-400 tracking-normal leading-8'}>
                      {suffix}
                    </span>
								}
							</div>
							}
						</div>
					) : (
						<p
							className={`uppercase font-medium text-gray-400 text-opacity-60 tracking-normal text-28px ${styles.placeholder} 
            ${expand ? `pt-21px text-26px xs:pt-8px` : `pt-15px xs:pt-8px`}
            ${showBack && 'xs:pt-11px xs:pl-33px'}
            ${!showAddForm && !expand ? 'pr-17px' : ''} xs:text-13px`}>
							{title}
						</p>
					)}
					{(!readonly && type === TokenDropdownTypes.TOKEN) &&
					<div className={`rounded-full absolute top-0 h-full mt-17px xs:mt-7px w-60px h-60px xs:w-30px xs:h-30px
                  ${expand ? `shadow-none right-14px` : `bg-white right-13px shadow-custom-1`}`}
							 id={`token-dropdown-token-type-${isSecond ? 'second' : 'first'}-id`}
							 onClick={toggle}>
						<div className={`absolute top-0 left-0 w-full h-full flex items-center justify-center transition-opacity pt-7px xs:pt-1px
              ${expand ? 'opacity-0' : 'opacity-1'}`}>
							<Image className={'-rotate-90'} src={'/icons/arrowLeft.svg'}
										 width={windowSize.width < responsiveSizes.xs ? 10 : 24}
										 height={windowSize.width < responsiveSizes.xs ? 10 : 24}/>
						</div>
						<div className={`absolute top-0 left-0 w-full h-full flex items-center justify-center transition-opacity pt-16px pr-10px xs:pr-0 xs:pt-6px
              ${expand ? 'opacity-1' : 'opacity-0'}`}>
							<Image className={'-rotate-90'} src={'/icons/iconCrossTiny.svg'}
										 width={windowSize.width < responsiveSizes.xs ? 12 : 21}
										 height={windowSize.width < responsiveSizes.xs ? 12 : 21}/>
						</div>
					</div>
					}
					{(!readonly && type === TokenDropdownTypes.INPUT_STEPPER) &&
					<div className={`absolute top-0 xs:top-7px right-0 xs:right-12px h-full mt-16px mr-33px xs:m-0 bg-transparent flex flex-col xs:flex-row items-center 
					justify-between w-30px h-67px xs:w-auto xs:h-auto`}>
						<div className={`${styles.arrowStepWrapper} xs:mr-5px`} id={`token-dropdown-arrow-up-btn-${isSecond ? 'second' : 'first'}-id`} onClick={() => increaseAmount(1)}>
							<Image className={'rotate-90'} src={'/icons/arrowLeft.svg'}
                     width={windowSize.width < responsiveSizes.xs ? 10 : 24}
                     height={windowSize.width < responsiveSizes.xs ? 10 : 24} />
						</div>
						<div className={styles.arrowStepWrapper} id={`token-dropdown-arrow-down-btn-${isSecond ? 'second' : 'first'}-id`} onClick={() => increaseAmount(-1)}>
							<Image className={'-rotate-90'} src={'/icons/arrowLeft.svg'}
                     width={windowSize.width < responsiveSizes.xs ? 10 : 24}
                     height={windowSize.width < responsiveSizes.xs ? 10 : 24} />
						</div>
					</div>
					}
				</div>
				<div className={`w-full relative ${expand ? '' : 'invisible'}`}>
					{expand &&
						<>
							<div
								className={`${styles.formBody} ${!showAddForm && !showManageTokenForm && !showManageListForm ? styles.formBodyShow : styles.formBodyHide}`}>
								<SelectTokens onAdd={toggleAddForm} onSelect={handleSelectToken}
															hideTokens={expand ? hideTokens : []}
															isSecond={isSecond}
															onManageTokens={() => setShowManageTokenForm(true)} />
							</div>
							<div className={`${styles.formBody} ${showAddForm ? styles.formBodyShow : styles.formBodyHide}`}>
								<AddToken token={addableToken} isSecond={isSecond} onConfirmAdd={(token: Token) => {
									toggleAddForm()
								}} />
							</div>
							<div className={`${styles.formBody} ${showManageTokenForm ? styles.formBodyShow : styles.formBodyHide}`}>
								<ManageTokens isSecond={isSecond}
															onManageList={() => {
																setShowManageTokenForm(false)
																setShowManageListForm(true)
															}} onAddCustomToken={(token: Token) => {
									setShowManageTokenForm(false)
									setShowAddForm(true)
									setAddableToken(token)
								}} />
							</div>
							<div className={`${styles.formBody} ${showManageListForm ? styles.formBodyShow : styles.formBodyHide}`}>
								<ManageList isSecond={isSecond}
														onManageTokens={() => {
															setShowManageTokenForm(true)
															setShowManageListForm(false)
														}} />
							</div>
						</>
					}
				</div>
			</div>
		</div>
	)
}

export default TokenDropdown
