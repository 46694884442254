import React, { useEffect, useMemo, useState } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import ReactGA from 'react-ga'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { useRouter } from 'next/router'
import Image from 'next/image'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { FixedSizeList } from 'react-window'
import { isMobile } from 'react-device-detect'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { Modal, ModalMenuItem } from 'components'

import { Sizes } from 'types/Sizes'
import { WalletModel } from 'types/WalletModel'

import { useWindowSize } from 'hooks/useWindowSize'

import { injected, portis } from 'connectors'

import { SUPPORTED_WALLETS } from '../../constants'
import { responsiveSizes } from 'constants/sizes'

const ConnectWalletModal: React.FC = () => {
	const router = useRouter()
	const { account, activate } = useWeb3React()
	const { width: screenWidth } = useWindowSize()
	const { i18n } = useLingui()

	const [pendingWalletName, setPendingWalletName] = useState<string>()
	const [pendingWallet, setPendingWallet] = useState<WalletModel>()
	const [pendingError, setPendingError] = useState<boolean>()

	const options = useMemo(() => {
		const isMetamask = window.ethereum && window.ethereum.isMetaMask

		const optionList = Object.keys(SUPPORTED_WALLETS).map((key) => {

			const option = SUPPORTED_WALLETS[key]

			// check for mobile options
			if (isMobile) {
				// disable portis on mobile for now
				if (option.connector === portis) {
					return null
				}

				if (!window.web3 && !window.ethereum && option.mobile) {
					return option
				}
				return null
			}

			// overwrite injected when needed
			if (option.connector === injected) {
				// don't show injected if there's no injected provider
				if (!(window.web3 || window.ethereum)) {
					if (option.name === 'MetaMask') {
						return option
					} else {
						return null // dont want to return install twice
					}
				}
				// don't return metamask if injected provider isn't metamask
				else if (option.name === 'MetaMask' && !isMetamask) {
					return null
				}
				// likewise for generic
				else if (option.name === 'Injected' && isMetamask) {
					return null
				}
			}

			return option
		})
		// return optionList.filter(x => x);
		return optionList.filter(x => x && x?.name !== 'Coin Wallet' && x?.name !== 'Open in Coinbase Wallet').map(x => ({
			...x,
			name: x.name === 'WalletConnect' ? 'Wallet Connect' : x.name
		}))
	}, [])

	const tryActivation = async (wallet: WalletModel) => {
		const connector = wallet.connector;
		let name = ''
		let conn = typeof connector === 'function' ? await connector() : connector

		Object.keys(SUPPORTED_WALLETS).map((key) => {
			if (connector === SUPPORTED_WALLETS[key].connector) {
				return (name = SUPPORTED_WALLETS[key].name)
			}
			return true
		})
		// log selected wallet
		ReactGA.event({
			category: 'Wallet',
			action: 'Change Wallet',
			label: name
		})
		setPendingWallet(wallet) // set wallet for pending view
		setPendingWalletName(name)
		// setWalletView(WALLET_VIEWS.PENDING)

		// if the connector is walletconnect and the user has already tried to connect, manually reset the connector
		// @ts-ignore
		if (conn instanceof WalletConnectConnector && conn.walletConnectProvider?.wc?.uri) {
			conn.walletConnectProvider = undefined
		}

		localStorage.setItem('ACTIVE_WALLET', name === 'Coin Wallet' ? 'coin' : 'injected')
		conn &&
		activate(conn, undefined, true).catch((error) => {
			if (error instanceof UnsupportedChainIdError) {
				activate(conn) // a little janky...can't use setError because the connector isn't set
			} else {
				setPendingError(true)
			}
		})
	}

	const tryingToConnect = useMemo(() => pendingWalletName && !pendingError, [pendingWalletName, pendingError])

	useEffect(() => {
		if (account) {
			router.push('/')
		}
	}, [account])

	const RenderRow = React.useCallback(
		({ index, style, data }) => (
			<div key={index} style={style} className={`max-w-400px px-15px`}>
				<ModalMenuItem label={data[index].name} onSelect={() => tryActivation(data[index])}/>
			</div>
		), [tryActivation]
	)

	return (
		<Modal isOpen={true}
					 title={{ firstLine: `${i18n._(t`connect`)}`, secondLine: `${i18n._(t`wallet`)}` }}
					 description={`${i18n._(t`Please connect your favorite wallet to the network to begin using the HODLVERSE metaverse.`)}`}
					 size={Sizes.MEDIUM}
					 addMobileTopSpace
					 mainIconName={'UserHomeOne'}>
			<div className={`h-full xs:h-500px flex flex-col pt-[90px] pb-33px pl-55px pr-63px xs:py-35px xs:px-13px relative`}>
				<div className={`w-full h-430px xs:h-375px relative`}>
					<div className={`modalBody ${tryingToConnect ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
						<div className={`w-full h-full relative`}>
							<FixedSizeList
								outerElementType={(props) => <PerfectScrollbar {...props} />}
								height={screenWidth < responsiveSizes.xs ? 375 : 430}
								itemData={options}
								itemCount={options.length}
								itemSize={screenWidth < responsiveSizes.xs ? 67 : 86}
								width={'100%'}
							>
								{RenderRow}
							</FixedSizeList>
						</div>
					</div>
					<div className={`modalBody ${!tryingToConnect ? 'opacity-0 pointer-events-none' : 'opacity-100'}`}>
						<div className={'h-full flex flex-col justify-center pb-30px'}>
							<p className={'font-medium text-40px xs:text-3xl text-gray-400 tracking-normal xs:text-center pl-6px xs:pl-0 mb-30px'}>{i18n._(t`Connecting…`)}</p>
							<div className={'xs:hidden'}>
								<ModalMenuItem label={pendingWalletName} disabled={true}/>
							</div>
							<div className={'hidden xs:flex flex-col items-center'}>
								<Image src={`/images/wallets/${pendingWallet?.iconName}`} alt={'Wallet'}
											 width={120} height={105} />
							  <p className={'font-medium text-xl text-gray-400 mt-30px'}>{pendingWalletName}</p>
							</div>
						</div>
					</div>
				</div>
				<p className={'font-medium text-xs text-gray-400 mt-10px pl-21px xs:px-0 tracking-normal xs:text-center'}>
					{i18n._(t`Connecting a wallet acknowledges that you agree to HODLVERSE’s`)}&nbsp;
					<a className={'underline'}
						href="https://hodlverse.gitbook.io/docs/legal/terms-of-service"
						rel="noopener noreferrer" target="_blank">{i18n._(t`Terms of Service`)}</a>,&nbsp;
					<a className={'underline'} href="https://hodlverse.gitbook.io/docs/legal/privacy-policy"
						 rel="noopener noreferrer" target="_blank">{i18n._(t`Privacy Policy`)}</a>, {i18n._(t`and`)} <a
					className={'underline'} href="https://hodlverse.gitbook.io/docs/legal/disclaimer" rel="noopener noreferrer"
					target="_blank">{i18n._(t`Disclaimer`)}</a>.
				</p>
			</div>
		</Modal>
	)
}

export default ConnectWalletModal
