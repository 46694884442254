import { useEffect, useState } from 'react'

import { injected, coinInjection } from '../connectors'
import { isMobile } from 'react-device-detect'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import {AbstractConnector} from "@web3-react/abstract-connector";

function useEagerConnect() {
  const { activate, active } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false)

  const activeWallet = localStorage.getItem('ACTIVE_WALLET')

  const tryConnectToWallet = (connector: any, injectionKey: string)=>{
    return new Promise((resolve, reject)=>{
      connector.isAuthorized().then((isAuthorized) => {
        if (isAuthorized) {
          activate(connector, undefined, true).catch(() => {
            resolve(true)
          })
        } else {
          if (isMobile && window[injectionKey]) {
            activate(connector, undefined, true).catch(() => {
              resolve(true)
            })
          } else {
            resolve(true)
          }
        }
      })
    });
  }
  useEffect(() => {
    if (activeWallet === 'coin') {
      tryConnectToWallet(coinInjection, 'eth').then((tried:boolean)=>{
        setTried(tried);
        if (!active) {
          tryConnectToWallet(injected, 'ethereum').then((tried:boolean)=>{
            setTried(tried);
          })
        }
      });
    } else {
      if (!active) {

        tryConnectToWallet(injected, 'ethereum').then((tried:boolean)=>{
          setTried(tried);
        })
      }
    }


  }, [activate]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

export default useEagerConnect
