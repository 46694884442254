import { Loader } from 'components'

import { useActiveWeb3React } from "hooks";

import { useCurrencyBalance } from "state/wallet/hooks";

const Balance = ({ currency }) => {
  const { account } = useActiveWeb3React();

  const balance = useCurrencyBalance(account ?? undefined, currency)

  return (
    <div className="flex items-center justify-end" id={'token-balance-wrapper'}>
      {balance ? (
        <div className="whitespace-nowrap overflow-hidden max-w-[5rem] overflow-ellipsis text-blue-400 tracking-wider
        font-medium text-15px xs:text-base xs:leading-4"
             title={balance.toExact()}>
          {balance.toSignificant(4)}
        </div>
      ) : account ? <Loader /> : null}
    </div>
  )
}

export default Balance;
