import {
  getMasterChefV1Farms,
  getMasterChefV1PairAddreses,
  getMasterChefV1SushiPerBlock,
  getMasterChefV1TotalAllocPoint,
  getMasterChefV2Farms,
  getMasterChefV2PairAddreses,
  getMiniChefFarms,
  getMiniChefPairAddreses, getMyMasterChefV1Farms,
} from '../fetchers'
import { useEffect, useMemo } from 'react'
import useSWR, { SWRConfiguration } from 'swr'

import { ChainId } from '@hodlvalley/sdk'
import { Chef } from '../../../features/farm/enum'
import concat from 'lodash/concat'
import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import {usePositions} from "../../../features/farm/hooks";

export * from './bentobox'
export * from './blocks'
export * from './exchange'

export function useMasterChefV1TotalAllocPoint(swrConfig = undefined) {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = chainId && chainId === ChainId.MAINNET
  const { data } = useSWR(
    shouldFetch ? 'masterChefV1TotalAllocPoint' : null,
    () => getMasterChefV1TotalAllocPoint(),
    swrConfig
  )
  return data
}

export function useMasterChefV1SushiPerBlock(swrConfig = undefined) {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = chainId && chainId === ChainId.MAINNET
  const { data } = useSWR(
    shouldFetch ? 'masterChefV1SushiPerBlock' : null,
    () => getMasterChefV1SushiPerBlock(),
    swrConfig
  )
  return data
}

export function useMasterChefV1Farms(swrConfig = undefined) {
  const { chainId } = useActiveWeb3React()
  // const shouldFetch = (chainId && chainId === ChainId.MAINNET) || chainId === ChainId.ROPSTEN

  const shouldFetch = chainId ? true : false

  const { data } = useSWR(shouldFetch ? 'masterChefV1Farms' : null, () => getMasterChefV1Farms(chainId), swrConfig)

  return useMemo(() => {
    if (!data) return []
    return data.map((data) => ({ ...data, chef: Chef.MASTERCHEF }))
  }, [data])
}

export function useMasterChefV2Farms(swrConfig: SWRConfiguration = undefined) {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = chainId && chainId === ChainId.MAINNET
  const { data } = useSWR(shouldFetch ? 'masterChefV2Farms' : null, () => getMasterChefV2Farms(), swrConfig)
  return useMemo(() => {
    if (!data) return []
    return data.map((data) => ({ ...data, chef: Chef.MASTERCHEF_V2 }))
  }, [data])
}

export function useFarms(swrConfig: SWRConfiguration = undefined) {
  const masterChefV1Farms = useMasterChefV1Farms()

  return useMemo(
      () => concat(masterChefV1Farms,).filter((pool) => pool && pool.pair),
      [masterChefV1Farms, ]
  )
}

export function useMasterChefV1PairAddresses() {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = true
  // const shouldFetch = (chainId && chainId === ChainId.MAINNET) || chainId === ChainId.ROPSTEN


  const { data } = useSWR(shouldFetch ? ['masterChefV1PairAddresses', chainId] : null, (_) =>
    getMasterChefV1PairAddreses(chainId)
  )
  return useMemo(() => {
    if (!data) return []
    return data.map((data) => data.pair)
  }, [data])
}

export function useMiniChefPairAddresses() {
  const { chainId } = useActiveWeb3React()
  const shouldFetch = chainId && [ChainId.MATIC, ChainId.XDAI, ChainId.HARMONY].includes(chainId)
  const { data } = useSWR(shouldFetch ? ['miniChefPairAddresses', chainId] : null, (_, chainId) =>
    getMiniChefPairAddreses(chainId)
  )
  return useMemo(() => {
    if (!data) return []
    return data.map((data) => data.pair)
  }, [data])
}

export function useFarmPairAddresses() {
  const masterChefV1PairAddresses = useMasterChefV1PairAddresses()
  // const masterChefV2PairAddresses = useMasterChefV2PairAddresses()
  // const miniChefPairAddresses = useMiniChefPairAddresses()
  return useMemo(
    () => concat(masterChefV1PairAddresses,),
    [masterChefV1PairAddresses]
  )
}
export function useGetMyHodlFarms () {
  const { chainId } = useActiveWeb3React()
  const positions = usePositions()


  const poolIds = positions.map(p => p.id);

  const shouldFetch = chainId && [ChainId.MAINNET, ChainId.ROPSTEN, ChainId.BSC_TESTNET].includes(chainId)


  const variables = {
    where: {
      id_in: poolIds.map(a => a.toLowerCase())
    }
  }


  const {data} = useSWR(shouldFetch? ['getMyMasterChefV1Farms', chainId, JSON.stringify(variables)]: null, ()=> getMyMasterChefV1Farms(chainId, variables))

  return useMemo(()=>{
    if (Array.isArray(data)) {
      return concat(data,).filter((pool) => pool && pool.pair).map((data) => ({ ...data, chef: Chef.MASTERCHEF }))
    }
    return  []
  }, [data])
}
