import React from 'react'
import { Currency } from '@hodlvalley/sdk'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { TokenDropdown, Tooltip } from '../index'
import CurrencyLogo from '../CurrencyLogo'

import { useActiveWeb3React } from 'hooks'
import { useUSDCValue } from 'hooks/useUSDCPrice'

import { useCurrencyBalance } from 'state/wallet/hooks'

import { TokenModel } from 'types/TokenModel'

import styles from './tokenForm.module.scss'


interface TokenFormProps {
	operationTitle?: string;
	selectedToken?: Currency;
	onChangeToken: (token: TokenModel | Currency) => void;
	amount?: string;
	onChangeAmount?: (amount: string) => void;
	allowEditAmount?: boolean;
	readonly?: boolean;
	disabled?: boolean;
	onMax?: () => void;
	hideMax?: boolean;
	hideInput?: boolean;
	hideTokens?: Currency[];
	isSecond?: boolean;
}

const TokenForm: React.FC<TokenFormProps> = ({
																							 operationTitle,
																							 selectedToken,
																							 onChangeToken,
																							 amount,
																							 onChangeAmount,
																							 onMax,
																							 allowEditAmount = false,
																							 disabled = false,
																							 readonly = false,
																							 hideMax = false,
																							 hideInput = false,
																							 hideTokens = [],
																							 isSecond = false
																						 }) => {
	const { account } = useActiveWeb3React()
	const { i18n } = useLingui()
	const balance = useCurrencyBalance(account ?? undefined, selectedToken)

	const balanceUSDCValue = useUSDCValue(balance)

	return (
		<div
			className={`w-full flex items-center xs:flex-col xs:items-start ${selectedToken ? 'justify-between' : 'justify-end'}`}>

			{selectedToken && balance ? <div className={'flex flex-col'}>
				<div className={'flex items-center'} id={'token-form-selected-token-wrapper-id'}>
					<CurrencyLogo currency={selectedToken} size={32}/>
					<div className={'flex flex-col pl-25px relative xs:flex-row xs:pl-2px'}>
						<p className={'uppercase font-medium text-2xl text-gray-400 text-opacity-60 tracking-normal xs:text-xl'}>
							 {selectedToken.symbol}
						</p>
						<Tooltip message={selectedToken.name}>
							<p className={`uppercase font-medium text-2xl text-gray-400 tracking-normal -mt-4px 
															overflow-ellipsis whitespace-nowrap ${styles.tokenName}
															xs:mt-0 xs:pl-5px xs:text-xl`}>
								{selectedToken.name}
							</p>
						</Tooltip>
					</div>
				</div>
				<div className={`flex items-center pl-56px -mt-4px xs:pl-34px`}>
					<p className={'uppercase font-medium text-xs text-gray-350 tracking-normal'}>{i18n._(t`Balance`)}</p>
					<Tooltip
						message={`$${balanceUSDCValue ? balanceUSDCValue.toSignificant(4, { groupSeparator: ',' }) : '-'} USD`}>
						<p className={'uppercase font-medium text-xs text-gray-350 tracking-normal pl-11px whitespace-nowrap'}>
							<span id={`token-form__token-balance_value-id_${isSecond ? '2' : '1'}`}>{balance.toSignificant(4)}</span>{" "}
							<span id={`token-form__token-balance_symbol-id_${isSecond ? '2' : '1'}`}>{selectedToken.symbol}</span>
						</p>
					</Tooltip>

				</div>
			</div> : <div className={'xs:h-43px'}></div>
			}
			<div className={'relative z-50 -mt-6px -mr-5px xs:mt-15px xs:w-full xs:px-8px'}>
				<TokenDropdown operation={operationTitle}
											 selectedToken={selectedToken}
											 hideTokens={hideTokens}
											 onSelectToken={onChangeToken}
											 amount={amount}
											 onChangeAmount={onChangeAmount}
											 allowEditAmount={allowEditAmount}
											 disabled={!account || disabled}
											 hideInput={hideInput}
											 isSecond={isSecond}
											 readonly={readonly}/>
				{(allowEditAmount && !readonly && !hideMax) &&
				<div className={`absolute flex justify-center items-center z-10 -bottom-34px right-26px xs:z-20 xs:top-15px xs:right-65px xs:h-fit`}>
					<button onClick={onMax}
									id={'token-form-max-btn-id'}
									className={`bg-yellow-400 text-white text-sm xs:text-xs uppercase font-medium tracking-normal w-50px h-28px xs:w-40px xs:h-21px rounded-5 shadow-custom-6`}>
						{i18n._(t`Max`)}
					</button>
				</div>
				}
			</div>
		</div>
	)
}

export default TokenForm
