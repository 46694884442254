import {useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {AppDispatch, AppState} from '../index'

import {NotificationType} from "components/Notification";

import {addPopup, ApplicationModal, PopupContent, removePopup, setOpenModal} from './actions'

import {useActiveWeb3React} from 'hooks/useActiveWeb3React'

import {getEtherScanBaseUrl} from "analytics/core/format";

import useTransactionToast  from 'state/transactionToast/hooks'

import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React()

  return useSelector((state: AppState) => state.application.blockNumber[chainId ?? -1])
}

export function useModalOpen(modal: ApplicationModal): boolean {
  const openModal = useSelector((state: AppState) => state.application.openModal)
  return openModal === modal
}

export function useToggleModal(modal: ApplicationModal): () => void {
  const open = useModalOpen(modal)
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(open ? null : modal)), [dispatch, modal, open])
}

export function useOpenModal(modal: ApplicationModal): () => void {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal])
}

export function useCloseModals(): () => void {
  const dispatch = useDispatch<AppDispatch>()
  return useCallback(() => dispatch(setOpenModal(null)), [dispatch])
}

export function useWalletModalToggle(): () => void {
  return useToggleModal(ApplicationModal.WALLET)
}

export function useNetworkModalToggle(): () => void {
  return useToggleModal(ApplicationModal.NETWORK)
}

export function useToggleSettingsMenu(): () => void {
  return useToggleModal(ApplicationModal.SETTINGS)
}

export function useShowClaimPopup(): boolean {
  return useModalOpen(ApplicationModal.CLAIM_POPUP)
}

export function useToggleShowClaimPopup(): () => void {
  return useToggleModal(ApplicationModal.CLAIM_POPUP)
}

export function useToggleSelfClaimModal(): () => void {
  return useToggleModal(ApplicationModal.SELF_CLAIM)
}

export function useToggleDelegateModal(): () => void {
  return useToggleModal(ApplicationModal.DELEGATE)
}

export function useToggleVoteModal(): () => void {
  return useToggleModal(ApplicationModal.VOTE)
}

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {

  const { chainId, library } = useActiveWeb3React()

  const { updateTransactionToast } = useTransactionToast();

  const dispatch = useDispatch()
  return useCallback(
    (content: PopupContent, key?: string) => {
      // @ts-ignore
      if (content?.txn?.success && content?.txn?.summary) {
        // @ts-ignore
        updateTransactionToast(content?.txn?.hash, {
          // @ts-ignore
          text: content?.txn?.summary,
          linkText: {
            text: `${i18n._(t`View transaction`)}`,
            // @ts-ignore
            link: `${getEtherScanBaseUrl(chainId)}tx/${content?.txn?.hash}`
          },
          type: NotificationType.SUCCESS
        });
        // @ts-ignore
      } else if (!content?.txn?.success && content?.txn?.summary){
        // @ts-ignore
        updateTransactionToast(content?.txn?.hash, {
          // @ts-ignore
          text: content?.txn?.summary,
          linkText: {
            text: `${i18n._(t`View transaction`)}`,
            // @ts-ignore
            link: `${getEtherScanBaseUrl(chainId)}tx/${content?.txn?.hash}`
          },
          type: NotificationType.WARNING
        });
      }
      dispatch(addPopup({ content, key }))
    },
    [dispatch, updateTransactionToast]
  )
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useDispatch()
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }))
    },
    [dispatch]
  )
}

// get the list of active popups
export function useActivePopups(): AppState['application']['popupList'] {
  const list = useSelector((state: AppState) => state.application.popupList)
  return useMemo(() => list.filter((item) => item.show), [list])
}

export function useKashiApprovalPending(): string {
  return useSelector((state: AppState) => state.application.kashiApprovalPending)
}
