import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

import { Modal, FarmItem } from 'components'

import useModals from 'state/modals/hooks'
import useFarm from 'state/farm/hooks'

import {
	useFarmPairAddresses,
	useMoneyPairs,
	useMoneyPrice
} from 'services/graph'

import { usePositions } from 'features/farm/hooks'
import { Chef, PairType } from 'features/farm/enum'
import useReserveContract from 'features/stake/useReserve'

import { useFuse } from 'hooks'
import useDebounce from 'hooks/useDebounce'

import { Sizes } from 'types/Sizes'
import { FarmModel } from 'types/FarmModel'

import SearchPoolOrFarmTemplate from './template'

const FILTER = {
	portfolio: (farm) => farm?.amount && !farm.amount.isZero(),
	sushi: (farm) => farm.pair.type === PairType.SWAP,
	kashi: (farm) => farm.pair.type === PairType.KASHI,
	'2x': (farm) => farm.chef === Chef.MASTERCHEF_V2 || farm.chef === Chef.MINICHEF
}

const options = {
	keys: ['pair.id', 'pair.token0.symbol', 'pair.token1.symbol'],
	threshold: 0.4
}

interface SearchFarmModalProps {
	isMyPool?: boolean;
	farms
}

const SearchFarmModal: React.FC<SearchFarmModalProps> = ({ isMyPool = false, farms }) => {
	const router = useRouter()
	const { i18n } = useLingui()
	const type = router.query.filter as string

	const { closeModals } = useModals()

	const { setFarm } = useFarm()

	const [searchVal, setSearchVal] = useState('')

	const debouncedSearchVal = useDebounce(searchVal, 250)

	const [moneyPrice] = [useMoneyPrice()]

	const pairAddresses = useFarmPairAddresses()

	const positions = usePositions()



	const swapPairs = useMoneyPairs({
		where: {
			id_in: pairAddresses
		}
	})

	const { moneyBalance: moneyReserveBalance } = useReserveContract()

	const moneyReserveForMonth = (moneyReserveBalance ? parseFloat(moneyReserveBalance.toSignificant(6)) : 0) * 0.6


	const map = useMemo(() => pool => {

		// TODO: Account for fees generated in case of swap pairs, and use standard compounding
		// algorithm with the same intervals acrosss chains to account for consistency.
		// For lending pairs, what should the equivilent for fees generated? Interest gained?
		// How can we include this?

		// TODO: Deal with inconsistencies between properties on subgraph
		pool.owner = pool?.owner || pool?.masterChef || pool?.miniChef
		pool.balance = pool?.balance || pool?.slpBalance

		const swapPair = swapPairs?.find((pair) => pair.id === pool.pair)

		const type = PairType.SWAP

		const pair = swapPair

		const getRewards = () => {
			const poolRewardPerMonth = (pool.allocPoint / pool.owner.totalAllocPoint) * moneyReserveForMonth

			const defaultReward = {
				token: 'MONEY',
				icon: 'https://raw.githubusercontent.com/sushiswap/icons/master/token/sushi.jpg',
				poolRewardPerMonth,
				rewardPrice: moneyPrice
			}
			return [defaultReward]
		}

		const rewards = getRewards()

		const balance = Number(pool.balance / 1e18)

		const tvl = (balance / Number(swapPair.totalSupply)) * Number(swapPair.reserveUSD)

		const roiPerMonth =
			rewards.reduce((previousValue, currentValue) => {
				return previousValue + currentValue.poolRewardPerMonth * currentValue.rewardPrice
			}, 0) / tvl

		const roiPerYear = roiPerMonth * 12

		const position = positions.find((position) => position.id === pool.id && position.chef === pool.chef)

		return {
			...pool,
			...position,
			pair: {
				...pair,
				decimals: 18,
				type
			},
			balance,
			roiPerMonth,
			roiPerYear,
			rewards,
			tvl
		}
	}, [swapPairs])

	const data = useMemo(() => farms
		.filter((farm) => swapPairs && swapPairs.find((pair) => pair.id === farm.pair))
		.map(map)
		.filter((farm) => {
			return type in FILTER ? FILTER[type](farm) : true
		}), [farms, swapPairs, type, map])

	const { result, search } = useFuse({
		data,
		options
	})

	const selectItem = useCallback((item): void => {
		closeModals()


		const serialized = {
			...item,
			amount: item.amount?.toString() ? item.amount?.toString() : '0',
			pendingMoney: item.pendingMoney?.toString() ? item.pendingMoney?.toString() : '0'
		}
		setFarm(serialized as FarmModel)
		router.push(`/farm/${isMyPool ? 'myfarm' : 'allfarm'}/${item.id}`)
	}, [closeModals, isMyPool, router])

	const RenderRow = React.useCallback(
		({ index, style, data }) => {
			return (
				<div key={index} style={style} className={'px-10px'}>
					<FarmItem item={data[index]} onSelect={() => selectItem(data[index])}/>
				</div>
			)
		},
		[selectItem]
	)

	useEffect(() => {
		search(searchVal)
	}, [debouncedSearchVal])

	return (
		<Modal isOpen={true}
					 title={{
						 firstLine: isMyPool ? `${i18n._(t`View my`)}` : `${i18n._(t`View all`)}`,
						 secondLine: `${i18n._(t`Farms`)}`
					 }}
					 description={`${i18n._(t`The property for the farming of the HVLP token in the HODLVERSE network`)}.`}
					 size={Sizes.MEDIUM}
					 addMobileTopSpace
					 mainIconName={'FarmOne'}>
			<SearchPoolOrFarmTemplate
				searchVal={searchVal}
				setSearchVal={setSearchVal}
				elements={result}
				renderRow={RenderRow}
			/>
		</Modal>
	)
}

export default SearchFarmModal
