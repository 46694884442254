import React from 'react'
import {useRouter} from "next/router";
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'

export default function Web3Connect() {
  const router = useRouter();
  const { i18n } = useLingui()

  return (
    <div onClick={() => router.push('/connect')}
         className={`text-white font-bold opacity-80 text-sm cursor-pointer 
         xs:bg-white xs:text-gray-400 xs:opacity-100 xs:mx-auto xs:rounded-full xs:px-26px xs:py-14px`}>
      {i18n._(t`CONNECT WALLET`)}
    </div>
  )
}
